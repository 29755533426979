import React, { useState, useEffect, Fragment } from "react";
import OrderDashItem from "./OrderDashItem";
import CommercialOrderDashItem from "./CommercialOrderDashItem";
import DatagridComponent from "./Datagrid";
import IvrStatus from "./IvrStatus";
import { Transition, Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { DataGrid } from "@mui/x-data-grid";
import ReactPaginate from "react-paginate";
import { Tab } from "@headlessui/react";

const DashboardScreen = (props) => {
  if (typeof window === "undefined") {
    var urlParams = new URLSearchParams({ f: "bar", l: "bar" });
  } else {
    var urlParams = new URLSearchParams(window.location.search);
  }
  //
  //

  //
  //

  //
  //
  useEffect(() => {
    getAllOrders();
  }, []);
  //
  //
  //*Start FaunaDB*//
  //*Imports*//
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "orders";
  const [allExistingOrders, setallExistingOrders] = useState("");
  const [orders, setAllOrders] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(1);
  const [ordersPerPage, setordersPerPage] = useState(10);
  const [slice, setSlice] = useState([]);
  const [loadOrderDetails, setloadOrderDetails] = useState("blah");
  //
  //
  // Filtering Stuff
  //
  // Expired - timed out
  // Undeliverable - undelivered
  // Pending - delivered
  // Complete - complete
  //
  //
  //
  //
  const [filterSelected, setfilterSelected] = useState({});
  const [isDataFiltered, setisDataFiltered] = useState(false);
  const [orderNoFilter, setorderNoFilter] = useState("default");
  const [orderNoControl, setorderNoControl] = useState("default");
  // Filter Control used to update states
  const [filterControl, setfilterControl] = useState(0);
  //
  const [statusFilterControl, setstatusFilterControl] = useState({
    complete: false,
    pending: false,
    expired: false,
    undeliverable: false,
  });

  const statusFilterChange = (event) => {
    setstatusFilterControl({
      ...statusFilterControl,
      [event.target.name]: event.target.checked,
    });
  };

  //
  //
  //
  // Order Number Filter
  //
  //
  //
  const handleOrderNoSortDefault = () => {
    setallExistingOrders(
      allExistingOrders.sort(function (a, b) {
        return b.ref.value.id - a.ref.value.id;
      })
    );
    setisDataFiltered(false);
    setfilterControl(filterControl + 1);
    setorderNoControl("default");
  };
  //
  //
  //
  const handleOrderNoSortDesc = () => {
    setallExistingOrders(
      allExistingOrders.sort(function (a, b) {
        return b.data.orderNumber - a.data.orderNumber;
      })
    );
    setisDataFiltered(true);
    window.history.replaceState(null, null, "?o=blahh");
    // setfilterControl(filterControl + 1);
    setorderNoControl("descending");
  };
  //
  //
  //
  const handleOrderNoSortAsc = () => {
    setallExistingOrders(
      allExistingOrders.sort(function (a, b) {
        return b.data.orderNumber - a.data.orderNumber;
      })
    );
    setallExistingOrders(allExistingOrders.reverse());

    setisDataFiltered(true);
    setfilterControl(filterControl + 1);
    setorderNoControl("ascending");
  };

  //
  //
  //
  //
  //
  // switch (typeof props.activeUserSupplier) {
  //   case "string":
  //     handleOrderNoSortAsc();
  //     break;
  //   default:
  //     var client = new faunadb.Client({
  //       secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
  //     });
  //     break;
  // }
  //
  //
  //

  //
  //
  //
  var filteredArray = "";
  //
  //
  //
  switch (isDataFiltered === true && allExistingOrders.length > 0) {
    case true:
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    case false:
      var filteredArray = allExistingOrders;
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }
  //
  //
  //
  //

  const getAllOrders = async () => {
    {
      const allOrders = await client.query(
        q.Reverse(
          q.Map(
            q.Paginate(
              q.Match(
                q.Index("order-search-by-vendor"),
                props.activeUserVendor
              ),
              { size: 6000 }
            ),
            q.Lambda((x) => q.Get(x))
          )
        )
      );

      setallExistingOrders(allOrders.data);
      const data = allOrders.data;

      const slice = data.slice(offset - 1, offset - 1 + ordersPerPage);

      // For displaying Data
      const ordersData = setSlice(slice);

      // Using Hooks to set value
      setAllOrders(ordersData);
      setPageCount(Math.ceil(data.length / ordersPerPage));
    }
  };
  //
  //
  //
  const handlePageClick = (event) => {
    const selectedPage = event.selected;

    setOffset(1 + selectedPage * 10);
  };
  // useEffect(() => {
  //   getAllOrders();
  // }, [offset]);

  //
  //

  //
  //
  if (Object.keys(allExistingOrders).length === 0) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                aria-hidden="true"
                class="mb-10 w-20 h-20 animate-spin text-white fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <h1 class="text-6xl text-tpvBlue font-bold">Loading Orders</h1>
              <p class="text-2xl text-gray-700">
                If orders do not load please contact system admin.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <main class="animate-fade dashboard flex-1">
        <div class="lg:border-b lg:border-gray-200 lg:py-4 lg:flex lg:items-center lg:justify-between lg:px-8">
          <div class="hidden lg:flex flex-1 justify-between lg:mx-auto">
            <div class="flex flex-1">
              <label for="search-field" class="sr-only">
                Search
              </label>
              <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                  aria-hidden="true"
                >
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search-field"
                  name="search-field"
                  class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                  placeholder="Search Orders"
                  type="search"
                />
              </div>
            </div>
          </div>

          {props.activeUserRole[0] === "Vendor-Admin" ? (
            <div></div>
          ) : (
            <>
              {" "}
              <div class="hidden lg:block order-selection">
                <span class="isolate inline-flex rounded-md">
                  <button
                    type="button"
                    onClick={() => {
                      const newPosition = "Create-Order-Commercial";
                      props.onChange(newPosition);
                    }}
                    class="relative inline-flex items-center rounded-l-md border border-white bg-gradient-to-l from-blue-700 to-sky-500 px-12 py-3 text-sm font-medium text-white hover:opacity-70 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="mr-2 w-6 h-6"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zM12.75 12a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V18a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V12z"
                        clip-rule="evenodd"
                      />
                      <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                    </svg>
                    Commercial{" "}
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      const newPosition = "Create-Order";
                      props.onChange(newPosition);
                    }}
                    class="relative -ml-px inline-flex items-center rounded-r-md border border-white bg-gradient-to-r from-orange-500 to-tpvRed px-12 py-3 text-sm font-medium text-white hover:opacity-70 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="mr-2 w-6 h-6"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zM12.75 12a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V18a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V12z"
                        clip-rule="evenodd"
                      />
                      <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                    </svg>
                    Residential
                  </button>
                </span>
              </div>
            </>
          )}
        </div>

        <div class="mt-8 flow-root overflow-hidden">
          <div class="flex justify-between">
            <div class="ml-5">
              <h2 class="pt-3  pb-5 text-xl font-semibold text-gray-900">
                Your Recent Orders{" "}
                <span class="text-gray-400 italic">
                  {props.activeSelectedDB}
                </span>{" "}
              </h2>
            </div>
          </div>
          <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8 ">
            <div class="inline-block overflow-scroll max-w-7xl min-w-full py-2 align-middle sm:px-6 lg:px-12">
              <div class="flex justify-between border-b w-full mb-4 pb-4 ">
                <div class="inline-flex">
                  <div className="order no z-50 w-full ">
                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`
                ${open ? "" : "text-opacity-90"}
                ${
                  orderNoControl === "ascending" ||
                  orderNoControl === "descending"
                    ? "bg-blue-50"
                    : "ring-gray-700/50 bg-white"
                }



                group inline-flex rounded-md px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset hover:bg-gray-50`}
                          >
                            <span className="whitespace-nowrap">
                              Order Number
                            </span>
                            <ChevronDownIcon
                              className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 text-gray-600 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                              aria-hidden="true"
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute mt-3 w-screen max-w-sm px-4 sm:px-0 ">
                              <div className="absolute right-30 z-10 overflow-hidden rounded-lg shadow-xl ring-1 ring-black/20">
                                <div className="">
                                  <div className="relative bg-white pt-6 pb-16  pl-3 pr-12">
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="order-no-asc"
                                        name="notification-method"
                                        type="radio"
                                        onClick={() =>
                                          setorderNoFilter("ascending")
                                        }
                                        checked={orderNoFilter === "ascending"}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="order-no-asc"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Ascending
                                      </label>
                                    </div>
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="order-no-desc"
                                        name="notification-method"
                                        type="radio"
                                        onClick={() =>
                                          setorderNoFilter("descending")
                                        }
                                        checked={orderNoFilter === "descending"}
                                        className="mt-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="order-no-desc"
                                        className="mt-2 ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Descending
                                      </label>
                                    </div>
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="order-no-default"
                                        name="notification-method"
                                        type="radio"
                                        onClick={() =>
                                          setorderNoFilter("default")
                                        }
                                        checked={orderNoFilter === "default"}
                                        className="mt-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="order-no-default"
                                        className="mt-2 ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Default
                                      </label>
                                    </div>
                                  </div>

                                  <div className="">
                                    {orderNoFilter === "default" ? (
                                      <button
                                        onClick={() =>
                                          handleOrderNoSortDefault(close())
                                        }
                                        className="absolute bottom-0 w-full rounded-b-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      >
                                        Apply
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {orderNoFilter === "descending" ? (
                                      <button
                                        onClick={() =>
                                          handleOrderNoSortDesc(close())
                                        }
                                        className="absolute bottom-0 w-full rounded-b-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      >
                                        Apply
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {orderNoFilter === "ascending" ? (
                                      <button
                                        onClick={() =>
                                          handleOrderNoSortAsc(close())
                                        }
                                        className="absolute bottom-0 w-full rounded-b-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      >
                                        Apply
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>

                  <div className="status ml-3 z-50 w-full ">
                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-700/50 hover:bg-gray-50`}
                          >
                            <span className="whitespace-nowrap">Status</span>
                            <ChevronDownIcon
                              className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 text-gray-600 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                              aria-hidden="true"
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute mt-3 w-screen max-w-sm px-4 sm:px-0 ">
                              <div className="absolute right-30 z-10 overflow-hidden rounded-lg shadow-xl ring-1 ring-black/20">
                                <div className="">
                                  <div className="relative bg-white pt-6 pb-16  pl-3 pr-12">
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="complete"
                                        name="complete"
                                        type="checkbox"
                                        defaultChecked={false}
                                        value={statusFilterControl.complete}
                                        onChange={statusFilterChange}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="complete"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Complete
                                      </label>
                                    </div>
                                    <div className="mt-2 flex items-center hover:opacity-80">
                                      <input
                                        id="pending"
                                        name="pending"
                                        type="checkbox"
                                        defaultChecked={false}
                                        value={statusFilterControl.pending}
                                        onChange={statusFilterChange}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="pending"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Delivered
                                      </label>
                                    </div>
                                    <div className="mt-2 flex items-center hover:opacity-80">
                                      <input
                                        id="expired"
                                        name="expired"
                                        type="checkbox"
                                        defaultChecked={false}
                                        value={statusFilterControl.expired}
                                        onChange={statusFilterChange}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="expired"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Timed Out
                                      </label>
                                    </div>
                                    <div className="mt-2 flex items-center hover:opacity-80">
                                      <input
                                        id="undeliverable"
                                        name="undeliverable"
                                        type="checkbox"
                                        defaultChecked={false}
                                        value={
                                          statusFilterControl.undeliverable
                                        }
                                        onChange={statusFilterChange}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="undeliverable"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Issue
                                      </label>
                                    </div>
                                  </div>

                                  <div className="">
                                    <button
                                      onClick={() => close()}
                                      className="absolute bottom-0 w-full rounded-b-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>

                  <div className="date ml-3 z-50 w-full ">
                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-700/50 hover:bg-gray-50`}
                          >
                            <span className="whitespace-nowrap">
                              Date Created
                            </span>
                            <ChevronDownIcon
                              className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 text-gray-600 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                              aria-hidden="true"
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute mt-3 w-screen max-w-sm px-4 sm:px-0 ">
                              <div className="absolute right-30 z-10 overflow-hidden rounded-lg shadow-xl ring-1 ring-black/20">
                                <div className="">
                                  <div className="relative bg-white pt-6 pb-16  pl-3 pr-12">
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="asc"
                                        name="notification-method"
                                        type="radio"
                                        // defaultChecked={notificationMethod.id === 'email'}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="asc"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Ascending
                                      </label>
                                    </div>
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="desc"
                                        name="notification-method"
                                        type="radio"
                                        // defaultChecked={notificationMethod.id === 'email'}
                                        className="mt-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="desc"
                                        className="mt-2 ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Descending
                                      </label>
                                    </div>
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="desc"
                                        name="notification-method"
                                        type="radio"
                                        // defaultChecked={notificationMethod.id === 'email'}
                                        className="mt-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="desc"
                                        className="mt-2 ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Default
                                      </label>
                                    </div>
                                  </div>

                                  <div className="">
                                    <button
                                      onClick={() => close()}
                                      className="absolute bottom-0 w-full rounded-b-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>

                  <div className="name ml-3 z-50 w-full ">
                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-700/50 hover:bg-gray-50`}
                          >
                            <span className="whitespace-nowrap">
                              Customer Name
                            </span>
                            <ChevronDownIcon
                              className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 text-gray-600 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                              aria-hidden="true"
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute mt-3 w-screen max-w-sm px-4 sm:px-0 ">
                              <div className="absolute right-30 z-10 overflow-hidden rounded-lg shadow-xl ring-1 ring-black/20">
                                <div className="">
                                  <div className="relative bg-white pt-6 pb-16  pl-3 pr-12">
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="asc"
                                        name="notification-method"
                                        type="radio"
                                        // defaultChecked={notificationMethod.id === 'email'}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="asc"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Ascending
                                      </label>
                                    </div>
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="desc"
                                        name="notification-method"
                                        type="radio"
                                        // defaultChecked={notificationMethod.id === 'email'}
                                        className="mt-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="desc"
                                        className="mt-2 ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Descending
                                      </label>
                                    </div>
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="desc"
                                        name="notification-method"
                                        type="radio"
                                        // defaultChecked={notificationMethod.id === 'email'}
                                        className="mt-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="desc"
                                        className="mt-2 ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Default
                                      </label>
                                    </div>
                                  </div>

                                  <div className="">
                                    <button
                                      onClick={() => close()}
                                      className="absolute bottom-0 w-full rounded-b-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>

                  <div className="more ml-3 z-50 w-full ">
                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-700/50 hover:bg-gray-50`}
                          >
                            <span className="whitespace-nowrap">More</span>
                            <ChevronDownIcon
                              className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 text-gray-600 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                              aria-hidden="true"
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute mt-3 w-screen max-w-sm px-4 sm:px-0 ">
                              <div className="absolute right-30 z-10 overflow-hidden rounded-lg shadow-xl ring-1 ring-black/20">
                                <div className="">
                                  <div className="relative bg-white pt-6 pb-16  pl-3 pr-12">
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="asc"
                                        name="notification-method"
                                        type="radio"
                                        // defaultChecked={notificationMethod.id === 'email'}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="asc"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Ascending
                                      </label>
                                    </div>
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="desc"
                                        name="notification-method"
                                        type="radio"
                                        // defaultChecked={notificationMethod.id === 'email'}
                                        className="mt-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="desc"
                                        className="mt-2 ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Descending
                                      </label>
                                    </div>
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="desc"
                                        name="notification-method"
                                        type="radio"
                                        // defaultChecked={notificationMethod.id === 'email'}
                                        className="mt-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="desc"
                                        className="mt-2 ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Default
                                      </label>
                                    </div>
                                  </div>

                                  <div className="">
                                    <button
                                      onClick={() => close()}
                                      className="absolute bottom-0 w-full rounded-b-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>
                </div>

                <span class="opacity-60 inline-flex rounded-md bg-white px-4 py-2.5 text-sm font-semibold bg-purple-50 text-purple-700 ring-1 ring-inset ring-purple-700/50">
                  {props.activeUser[0].data.vendor.name}
                </span>
              </div>

              <div class="overflow-x-auto shadow-md sm:rounded-lg">
                {isDataFiltered === false ? (
                  <>
                    <table class="min-w-full divide-y divide-gray-300 flex">
                      <DatagridComponent
                        orderDetailID={props.orderDetailID}
                        onChange={props.onChange}
                        allExistingOrders={allExistingOrders}
                        //{allExistingOrders.filter((word) => word.length > 6)}//
                      />
                    </table>
                  </>
                ) : (
                  <>
                    <table class="min-w-full divide-y divide-gray-300 flex">
                      <DatagridComponent
                        orderDetailID={props.orderDetailID}
                        onChange={props.onChange}
                        allExistingOrders={allExistingOrders}
                      />{" "}
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
};

export default DashboardScreen;
