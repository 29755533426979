import React, { useState, useEffect } from "react";

const CreateProduct = (props) => {
  //Get all Order Numbers//
  useEffect(() => {
    getProductNumbers();
    getAllScripts();
    getAllVendors();
    getAllUtilities();
  }, []);
  const [SaveState, setSaveState] = useState("unsaved");
  const [allExistingScripts, setallExistingScripts] = useState("");
  const [allExistingVendors, setallExistingVendors] = useState("");
  const [allExistingUtilities, setallExistingUtilities] = useState("");
  const [allExistingProductNumbers, setallExistingProductNumbers] =
    useState("");
  const [NewProduct, setNewProduct] = useState({
    name: "",
    reportingID: "",
    commodity: "",
    unitMeasurement: "",
    rate: Number(""),
    dwelling: "Residential",
    supplier: "",
    vendor: "",
    script: "",
    utility: null,
    term: "12-Months",
    monthlyFee: Number(""),
    terminationFee: Number(""),
    details: "",
    productType: "",
  });

  function handleChange(e) {
    setNewProduct({
      ...NewProduct,
      [e.target.name]: e.target.value,
    });
  }

  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "products";

  //
  //
  //
  // //
  //
  // Pull All Existing Order Numbers //
  //
  //
  //
  const getProductNumbers = async () => {
    {
      const existingOrderNumbers = await client.query(
        q.Paginate(q.Match(q.Index("product-numbers")), { size: 6000 })
      );

      setallExistingProductNumbers(existingOrderNumbers.data);
    }
  };
  //
  //
  //
  // New Order Number //
  //
  //
  //
  //var productNumberCheck = Math.floor(10000 + Math.random() * 90000);//
  var productNumberCheck = Math.floor(1000000000 + Math.random() * 9000000000);
  var newProductNumber = "";

  //
  //
  switch (allExistingProductNumbers.includes(productNumberCheck)) {
    case true:
      while (allExistingProductNumbers.includes(productNumberCheck) === true) {
        productNumberCheck = Math.floor(
          1000000000 + Math.random() * 9000000000
        );
        var newProductNumber = productNumberCheck;
      }
      break;
    case false:
      var newProductNumber = productNumberCheck;
      break;
  }

  //
  //
  //
  //
  //
  //
  //
  //
  // Pull all Scripts
  //
  //
  //
  //
  const getAllScripts = async () => {
    {
      const allScripts = await client.query(
        q.Map(
          q.Paginate(q.Documents(q.Collection("scripts"))),
          q.Lambda((x) => q.Get(x))
        )
      );

      setTimeout(function () {
        setallExistingScripts(allScripts.data);
      }, 2000);
    }
  };
  //
  //
  //
  //
  //
  //
  //
  //
  // Pull all Vendors
  //
  //
  //
  //
  const getAllVendors = async () => {
    {
      const allVendors = await client.query(
        q.Map(
          q.Paginate(q.Documents(q.Collection("vendors"))),
          q.Lambda((x) => q.Get(x))
        )
      );

      setTimeout(function () {
        setallExistingVendors(allVendors.data);
      }, 2000);
    }
  };
  //
  //
  //
  //
  //
  //
  //
  //
  // Pull all Utilities
  //
  //
  //
  //
  const getAllUtilities = async () => {
    {
      const allUtilities = await client.query(
        q.Map(
          q.Paginate(q.Documents(q.Collection("utilities"))),
          q.Lambda((x) => q.Get(x))
        )
      );

      setTimeout(function () {
        setallExistingUtilities(allUtilities.data);
      }, 2000);
    }
  };

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //Create Database Entry//
  //
  //
  //
  //

  function productSubmission() {
    setTimeout(() => {
      setSaveState("saving");
    }, 100);
    setTimeout(() => {
      createProductEntry();
    }, 3000);
    setTimeout(() => {
      setSaveState("saved");
    }, 2000);
    setTimeout(() => {
      props.onChange("Dashboard-Home");
    }, 4500);
  }

  const createProductEntry = async (_callback) => {
    const inputDate = new Date().toGMTString();
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    await client.query(
      q.Create(q.Collection(COLLECTION_NAME), {
        data: {
          name: NewProduct.name,
          reportingID: NewProduct.reportingID.toUpperCase(),
          commodity: NewProduct.commodity,
          unitMeasurement: NewProduct.unitMeasurement,
          rate: NewProduct.rate,
          dwelling: NewProduct.dwelling,
          supplier: NewProduct.supplier,
          vendor: JSON.parse(NewProduct.vendor),
          script: JSON.parse(NewProduct.script),
          utility: JSON.parse(NewProduct.utility),
          term: NewProduct.term,
          monthlyFee: NewProduct.monthlyFee,
          terminationFee: NewProduct.terminationFee,
          details: NewProduct.details,
          productID: newProductNumber,
          dateAdded: inputDate,
          dateAddedShort: formatDate(inputDate),
          productType: NewProduct.productType,
          supplier: props.activeSelectedDB,
        },
      })
    );
    setTimeout(() => {
      setSaveState("saved");
    }, 6000);
    props.onChange("Dashboard-Home");
  };

  //
  //
  // End Fauna DB Shenanigans //
  //
  //
  //
  //
  var matchedUtility = null;

  switch (typeof NewProduct.utility === "string") {
    case true:
      var matchedUtility = JSON.parse(NewProduct.utility);
      break;
    default:
      var matchedUtility = null;
      break;
  }

  //
  //
  //
  //
  //

  if (Object.keys(allExistingVendors).length === 0) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-gray-100 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <div class="animate-fade p-4 rounded p-20 rounded-3xl  ">
              <div class="flex flex-col items-center space-y-2">
                <svg
                  aria-hidden="true"
                  class="mb-10 w-20 h-20 animate-spin text-gray-100 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <h1 class="text-6xl text-tpvBlue font-bold">Loading</h1>
                <p class="text-2xl text-gray-700">
                  Please wait while we load this screen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <div class="animate-fade create-order-1 p-10 bg-gradient-to-r from-sky-200 to-blue-300 lg:grid lg:grid-cols-8 lg:gap-x-5">
        <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
          <div>
            <div class="heading">
              <nav class="sm:hidden" aria-label="Back">
                <a
                  href="#"
                  class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <svg
                    class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Back
                </a>
              </nav>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <button
                        type="button"
                        onClick={() => {
                          props.onChange("Dashboard-Home");
                        }}
                        class="text-sm font-medium text-tpvBlue hover:underline"
                      >
                        Products
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="h-5 w-5 flex-shrink-0 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="tpvBlue"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <p class="ml-4 text-sm font-medium text-tpvBlue underline">
                        Create Product
                      </p>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-tpvBlue sm:truncate sm:text-3xl sm:tracking-tight">
                  Create Product
                </h2>
              </div>
            </div>
          </div>

          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Product Information
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Add product information below.
                </p>
              </div>

              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Product Name<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    autocomplete="given-name"
                    class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Vendor
                  </label>
                  <select
                    name="vendor"
                    onChange={handleChange}
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="" selected disabled hidden>
                      Select Vendor
                    </option>
                    {allExistingVendors.map((post) => {
                      return (
                        <>
                          <option value={JSON.stringify(post.data)}>
                            {post.data.name} - {post.data.supplierVendorID}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Select Utility<span class="text-red-400">*</span>
                  </label>
                  <select
                    name="utility"
                    onChange={handleChange}
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="" selected disabled hidden>
                      Select Utility
                    </option>
                    {allExistingUtilities.map((post) => {
                      return (
                        <>
                          <option value={JSON.stringify(post.data)}>
                            {post.data.name} - {post.data.nickname}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                {matchedUtility === null ? (
                  <div class="col-span-6 sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">
                      Commodity<span class="text-red-400">*</span>
                    </label>
                    <select
                      name="commodity"
                      disabled
                      onChange={handleChange}
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                      <option value="" selected disabled hidden>
                        Select Utility First
                      </option>
                    </select>
                  </div>
                ) : (
                  <div class="col-span-6 sm:col-span-3">
                    <label class="block text-sm font-medium text-gray-700">
                      Commodity<span class="text-red-400">*</span>
                    </label>
                    <select
                      name="commodity"
                      onChange={handleChange}
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                      <option value="" selected disabled hidden>
                        Select Commodity
                      </option>

                      <option value={matchedUtility.commodity_1}>
                        {matchedUtility.commodity_1}
                      </option>

                      {matchedUtility.commodity_2 === "" ? (
                        <option value="" hidden></option>
                      ) : (
                        <option value={matchedUtility.commodity_2}>
                          {matchedUtility.commodity_2}
                        </option>
                      )}
                      {matchedUtility.commodity_3 === "" ? (
                        <option value="" hidden></option>
                      ) : (
                        <option value={matchedUtility.commodity_3}>
                          {matchedUtility.commodity_3}
                        </option>
                      )}
                      {matchedUtility.commodity_4 === "" ? (
                        <option value="" hidden></option>
                      ) : (
                        <option value={matchedUtility.commodity_4}>
                          {matchedUtility.commodity_4}
                        </option>
                      )}

                      {matchedUtility.commodity_5 === "" ? (
                        <option value="" hidden></option>
                      ) : (
                        <option value={matchedUtility.commodity_5}>
                          {matchedUtility.commodity_5}
                        </option>
                      )}
                    </select>
                  </div>
                )}
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Dwelling Type
                  </label>
                  <select
                    name="dwelling"
                    onChange={handleChange}
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="Residential">Residential</option>
                    <option value="Commercial">Commercial</option>
                  </select>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Script
                  </label>
                  <select
                    name="script"
                    onChange={handleChange}
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="" selected disabled hidden>
                      Select Script
                    </option>
                    {allExistingScripts.map((post) => {
                      return (
                        <>
                          <option value={JSON.stringify(post.data)}>
                            {post.data.scriptName}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="">
                    <label class="block text-sm font-medium text-gray-700">
                      Unit Of Measurement
                    </label>
                    <select
                      name="unitMeasurement"
                      onChange={handleChange}
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                      <option value="" selected disabled hidden>
                        Select Option
                      </option>
                      <option value="Therm">Therm</option>
                      <option value="Mcf">Mcf</option>
                      <option value="ccf">ccf</option>
                    </select>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="">
                    <label class="block text-sm font-medium text-gray-700">
                      Product Type
                    </label>
                    <select
                      name="productType"
                      onChange={handleChange}
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                      <option value="" selected disabled hidden>
                        Select Option
                      </option>
                      <option value="Variable">Variable</option>
                      <option value="Fixed">Fixed</option>
                    </select>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Rate <span class="text-xs">(USD)</span>
                    <span class="text-red-400">*</span>
                  </label>
                  <div class="mt-1 flex rounded-md shadow-sm">
                    <span class="inline-flex items-center rounded-l-md border border-gray-300 bg-blue-100 px-3 text-gray-500 sm:text-sm">
                      $
                    </span>
                    <input
                      type="number"
                      name="rate"
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChange}
                      class="bg-blue-50 block w-full rounded-r-md border-l-0 border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Contract Term
                  </label>
                  <select
                    name="term"
                    onChange={handleChange}
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="12-Months">12 Months</option>
                    <option value="24-Months">24 Months</option>
                    <option value="36-Months">36 Months</option>
                    <option value="Month-To-Month">Mo. to Mo.</option>
                  </select>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Monthly Recurring Fee <span class="text-xs">(USD)</span>
                  </label>
                  <div class="mt-1 flex rounded-md shadow-sm">
                    <span class="inline-flex items-center rounded-l-md border border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                      $
                    </span>
                    <input
                      type="number"
                      name="monthlyFee"
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChange}
                      class="block w-full rounded-r-md border-l-0 border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Early Termination Fee <span class="text-xs">(USD)</span>
                  </label>
                  <div class="mt-1 flex rounded-md shadow-sm">
                    <span class="inline-flex items-center rounded-l-md border border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                      $
                    </span>
                    <input
                      type="number"
                      name="terminationFee"
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChange}
                      class="block w-full rounded-r-md border-l-0 border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-6">
                  <label class="mb-1 block text-sm font-medium text-gray-700">
                    Product Details
                  </label>
                  <textarea
                    rows="2"
                    name="details"
                    onChange={handleChange}
                    placeholder="Enter in any information about this product you want displayed to the Agent and the prospect"
                    class="block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  ></textarea>
                </div>{" "}
              </div>
            </div>
          </div>

          <div class="text-center mt-4 sm:mt-0">
            {SaveState === "saving" ? (
              <div class="animate-fade text-center mt-4 flex sm:mt-0">
                <button
                  disabled
                  type="button"
                  class=" w-full order-0 rounded-md bg-blue-700 px-8 py-5 text-lg font-medium text-white  focus:outline-none sm:order-1"
                >
                  <svg
                    role="status"
                    class="inline mr-3 w-7 h-7 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="blue-700"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Saving...
                </button>
              </div>
            ) : (
              <></>
            )}

            {SaveState === "saved" ? (
              <div class="animate-fade text-center mt-4 flex sm:mt-0">
                <button
                  disabled
                  type="button"
                  class="w-full order-0 rounded-md bg-green-800 px-8 py-5 text-lg font-medium text-white focus:outline-none sm:order-1"
                >
                  <svg
                    role="status"
                    class="inline mr-3 w-7 h-7 text-white animate-bounce"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  Saved..Returning Home
                </button>
              </div>
            ) : (
              <></>
            )}

            {NewProduct.name === "" &&
            NewProduct.utility === null &&
            NewProduct.commodity === "" &&
            NewProduct.rate === Number("") ? (
              <div class="animate-fade text-center mt-4 flex sm:mt-0">
                <button
                  type="button"
                  disabled
                  class="opacity-70 w-full order-0 rounded-md   bg-blue-800 px-8 py-5 text-lg font-medium text-white focus:outline-none sm:order-1"
                >
                  Fill Out Required Fields
                </button>
              </div>
            ) : (
              <div>
                {SaveState !== "unsaved" ? (
                  <div class="hidden" />
                ) : (
                  <div class="animate-fade text-center mt-4 flex sm:mt-0">
                    <button
                      type="button"
                      onClick={productSubmission}
                      class=" w-full order-0 rounded-md   bg-blue-800 px-8 py-5 text-lg font-medium text-white hover:bg-blue-700 focus:outline-none sm:order-1"
                    >
                      Submit New Product
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
};

export default CreateProduct;
