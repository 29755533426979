import React, { useState } from "react";

const ScriptDashItem = (props) => {
  return (
    <tbody class="border-b border-gray-200 divide-y divide-gray-100 bg-white">
      <tr>
        <td class="w-1/4 max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
          <div class="flex items-center space-x-3 lg:pl-2">
            <button
              class="hover:opacity-70"
              type="button"
              onClick={() => {
                props.scriptDetailID(props.scriptID);
                props.positionChange("Script-Details");
              }}
              class="truncate hover:text-gray-400"
            >
              <span>{props.name}</span>
            </button>
          </div>
        </td>
        <td class="px-6 py-3 text-sm font-medium text-gray-900">
          <div class="flex items-center space-x-3">
            <span class="inline-flex items-center rounded bg-green-200 px-3 py-2 text-xs font-medium text-lime-900">
              Active
            </span>
          </div>
        </td>
        <td class="whitespace-nowrap px-6 py-3 text-left text-sm text-gray-500 md:table-cell">
          <span> {props.vendor}</span>
        </td>

        <td class="whitespace-nowrap px-6 py-3 text-left text-sm text-gray-500 md:table-cell">
          <span> {props.dateAdded}</span>
        </td>
      </tr>

      {
        //"<!-- More projects... -->"
      }
    </tbody>
  );
};
export default ScriptDashItem;
