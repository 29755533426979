import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";

const CampaignDetails = (props) => {
  return (
    <div class="animate-fade create-order-1 p-10 bg-gradient-to-l from-sky-800 to-blue-900 lg:grid lg:grid-cols-8 lg:gap-x-5">
      <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <div>
          <div class="heading">
            <nav class="sm:hidden" aria-label="Back">
              <a
                href="#"
                class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                <svg
                  class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                    clip-rule="evenodd"
                  />
                </svg>
                Back
              </a>
            </nav>
            <nav class="hidden sm:flex" aria-label="Breadcrumb">
              <ol role="list" class="flex items-center space-x-4">
                <li>
                  <div class="flex">
                    <button
                      type="button"
                      onClick={() => {
                        const newPosition = "Campaign-Home";
                        props.onChange(newPosition);
                      }}
                      class="text-sm font-medium text-sky-50 hover:underline"
                    >
                      Campaigns
                    </button>
                  </div>
                </li>
                <li>
                  <div class="flex items-center">
                    <svg
                      class="h-5 w-5 flex-shrink-0 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <p class="ml-4 text-sm font-medium text-sky-50 underline">
                      Heloc Florida Details
                    </p>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
          <div class="mt-2 md:flex md:items-center md:justify-between">
            <div class="inline-flex">
              <h2 class="text-2xl font-bold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight">
                Heloc Florida
              </h2>

              <span class="ml-4 inline-flex items-center rounded bg-green-300 px-3 py-2 text-xs font-medium text-lime-900">
                Active
              </span>
              <span class="opacity-50 ml-2 inline-flex items-center rounded bg-red-300 px-3 py-2 text-xs font-medium text-red-900">
                Paused
              </span>
            </div>

            <div class="mt-4 flex sm:mt-0 sm:ml-4">
              <button
                type="button"
                class="order-0 inline-flex items-center rounded-md   bg-sky-500 px-8 py-3 text-sm font-medium text-white hover:bg-sky-400 focus:outline-none focus:ring-2 focus:outline-none focus:ring-offset-2 sm:order-1 sm:ml-3"
              >
                Edit Campaign
              </button>
              <button
                type="button"
                class="order-0 inline-flex items-center rounded-md   bg-blue-500 px-8 py-3 text-sm font-medium text-white hover:bg-blue-400 focus:outline-none focus:ring-2 focus:outline-none focus:ring-offset-2 sm:order-1 sm:ml-3"
              >
                Add Products
              </button>
            </div>
          </div>
          <div class="flex">
            <div class="mt-3 inline-flex  bg-sky-400 bg-opacity-20 rounded-md p-2">
              {" "}
              <p class="pr-4 ml-2 mt-1 text-xs text-white">
                Total TPVs <span class="font-bold text-sky-200">10</span>
              </p>
              <p class="ml-2 mt-1 text-xs text-white">||</p>
              <p class="px-4 ml-2 mt-1 text-xs text-white">
                Date Added{" "}
                <span class="font-bold text-sky-200">March 17, 2020</span>
              </p>
            </div>
          </div>
        </div>

        <div class="  ">
          <div class="mt-8 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          <a href="#" class="group inline-flex">
                            Product
                            <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                              <svg
                                class="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </span>
                          </a>
                        </th>
                        <th
                          scope="col"
                          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          <a href="#" class="group inline-flex">
                            Product ID
                            <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                              <svg
                                class="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </span>
                          </a>
                        </th>
                        <th
                          scope="col"
                          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          <a href="#" class="group inline-flex">
                            Script
                            <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                              <svg
                                class="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </span>
                          </a>
                        </th>
                        <th
                          scope="col"
                          class="w-1/5 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          <a href="#" class="group inline-flex">
                            Vendors
                            <span class="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                              <svg
                                class="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </span>
                          </a>
                        </th>

                        <th
                          class="hidden border-b border-gray-200 bg-gray-100 text-center text-sm font-semibold text-gray-900 md:table-cell"
                          scope="col"
                        >
                          <button class="pt-1 hover:opacity-50">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class=" text-tpvBlue w-7 h-7"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
                              />
                            </svg>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                      <tr>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          Heloc Florida
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          345346765
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          $5.99/mcf
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          March 17, 2020
                        </td>

                        <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <a
                            href="#"
                            class="text-indigo-600 hover:text-indigo-900"
                          ></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-center mt-4 flex">
                  <button
                    type="button"
                    class=" w-full order-0 rounded-md   bg-orange-500 px-8 py-5 text-lg font-medium text-white hover:bg-orange-600 focus:outline-none sm:order-1"
                  >
                    Save Campaign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignDetails;
