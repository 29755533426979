import React, { useState } from "react";

const ScriptQuestion = (props) => {
  return (
    <div class="animate-fade question inline-flex col-span-6 sm:col-span-6">
      <textarea
        rows="2"
        name={props.questionName}
        onChange={props.onChange}
        value={props.questionValue}
        class="block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
      ></textarea>

      <div class="ml-4 flex items-center">
        <div class="flex">
          <button
            type="button"
            name={props.questionName}
            onClick={props.counterDecrease}
            class="ml-2 inline-flex items-center justify-center rounded-md border border-red-200 bg-red-100 px-8 py-5 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScriptQuestion;
