import { vendor } from "postcss";
import React, { useState, Fragment, useEffect } from "react";

const CreateVendorAdmin = (props) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  //
  //
  //
  //
  //
  //
  //*Start FaunaDB*//
  //*Imports*//
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = new faunadb.Client({
    secret: "fnAE-IPexpACTQKJ0fFUK6GhjGLvDi6n2fGcxBwd",
  });
  const COLLECTION_NAME = "vendor-users";
  const [allExistingUsers, setallExistingUsers] = useState("");
  const [SaveState, setSaveState] = useState("unsaved");
  const [emailConfirmation, setemailConfirmation] = useState({
    response: "",
    time: "",
  });

  const [vendorAdmin, setvendorAdmin] = useState({
    firstName: "",
    lastName: "",
    email: "",
    vendor: {},
    userImage: "",
    phone: "",
    telType: "Cell",
  });
  //
  //
  //
  // Password Generation
  var chars =
    "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var passwordLength = 12;
  var password = "";
  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }
  //
  //

  //
  //
  //
  // Email Validation
  function validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  }

  const emailCheck = validateEmail(vendorAdmin.email);
  //
  //
  //
  //
  //
  //
  // Supplier Select Logic
  //
  //
  //
  const [checkedSuppliers, setcheckedSuppliers] = useState({});

  var supplierKeys = "";

  switch (Object.keys(checkedSuppliers).length === 0) {
    case false:
      var supplierKeys = Object.keys(checkedSuppliers);
      var finalSupplierSelect = supplierKeys.filter(function (key) {
        return checkedSuppliers[key];
      });
      break;
    default:
      var supplierKeys = "";
      var finalSupplierSelect = "";
      break;
  }
  //
  //
  //
  var userEmails = "Default";
  //
  switch (Object.keys(allExistingUsers).length === 0) {
    case false:
      userEmails = allExistingUsers.map((post) => {
        return post.data.email;
      });
      break;
    default:
      userEmails = "Default";
      break;
  }
  //
  //
  //
  //
  //
  //
  //
  //
  const handleCheckbox = (event) => {
    setcheckedSuppliers({
      ...checkedSuppliers,
      [event.target.name]: event.target.checked,
    });
  };
  //
  //
  // End Supplier Logic
  //
  //
  //
  function handleChange(e) {
    setvendorAdmin({
      ...vendorAdmin,
      [e.target.name]: e.target.value,
    });
  }
  //
  //
  //
  //
  //
  useEffect(() => {
    getAllUsers();
  }, []);
  //
  //
  const getAllUsers = async () => {
    {
      const allUsers = await client.query(
        q.Reverse(
          q.Map(
            q.Paginate(q.Documents(q.Collection(COLLECTION_NAME)), {
              size: 100000,
            }),
            q.Lambda((x) => q.Get(x))
          )
        )
      );

      setallExistingUsers(allUsers.data);
    }
  };
  //
  //
  //
  //
  // Handle Profile Img
  //
  //
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  //
  //
  //
  const handleImageUpload = async (e) => {
    const [file] = e.target.files;
    const base64 = await convertBase64(file);
    if (base64) {
      setvendorAdmin({
        ...vendorAdmin,
        userImage: base64,
        userImageName: file,
      });
    }
  };
  //
  //
  //
  //
  const clearImg = (e) => {
    setvendorAdmin({
      ...vendorAdmin,
      userImage: "",
    });
  };
  //
  //
  //
  //
  //
  //
  //
  // Start Email Confirmation
  //
  //
  //
  const sendWelcomeEmail = async () => {
    const emailTime = new Date().toGMTString();
    let response = await fetch(
      "/.netlify/functions/send_user_email/",
      //https://opengatetpv.co/.netlify/functions/send_user_email///
      // "http://localhost:8888/.netlify/functions/send_email/"//
      {
        method: "POST",
        body: JSON.stringify({
          firstName: vendorAdmin.firstName,
          lastName: vendorAdmin.lastName,
          email: vendorAdmin.email,
          role: "Vendor-Admin",
          vendor: JSON.parse(vendorAdmin.vendor),
          userImage: vendorAdmin.userImage,
          suppliers: finalSupplierSelect,
          phone: vendorAdmin.phone,
          adminEmail: props.activeUserEmail[0],
          supplier: props.activeSelectedDB,
          userPassword: password,
        }),
      }
    );
    const emailResponse = await response.json();

    setTimeout(function () {
      setemailConfirmation({
        ...emailConfirmation,
        response: emailResponse,
        time: emailTime,
      });
    }, 2000);
  };

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //Create Database Entry//
  //
  //
  //
  const createVendorAdmin = async () => {
    await client.query(
      q.Create(q.Collection(COLLECTION_NAME), {
        credentials: { password: password },
        data: {
          firstName: vendorAdmin.firstName,
          lastName: vendorAdmin.lastName,
          email: vendorAdmin.email,
          role: "Vendor-Admin",
          userImage: vendorAdmin.userImage,
          suppliers: finalSupplierSelect,
          vendor: JSON.parse(vendorAdmin.vendor),
        },
      })
    );
  };
  //
  //
  //
  //
  //
  //
  //
  function orderSubmissionEmail() {
    setTimeout(() => {
      createVendorAdmin();
    }, 3000);
    setTimeout(() => {
      sendWelcomeEmail();
    }, 3000);
    setTimeout(() => {
      setSaveState("saved");
    }, 2000);
  }
  //
  //
  //
  //
  //
  //
  //

  if (userEmails === "Default") {
    return (
      <main class="animate-fade dashboard">
        <div className="text-center min-h-screen bg-gray-100 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <div class="animate-fade p-4 rounded p-20 rounded-3xl  ">
              <div class="flex flex-col items-center space-y-2">
                <svg
                  aria-hidden="true"
                  class="mb-10 w-20 h-20 animate-spin text-gray-100 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <h1 class="text-6xl text-tpvBlue font-bold">Loading</h1>
                <p class="text-2xl text-gray-700">
                  Please wait while we load this screen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <>
        {typeof emailConfirmation.response === "object" ? (
          <>
            {Object.keys(emailConfirmation.response).length === 3 ? (
              <div class="animate-fade bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-sky-300 to-cyan-200 flex items-center justify-center h-screen ">
                <div class="animate-fade bg-white p-20 shadow-xl rounded-3xl  ">
                  <div class="text-red-600 flex flex-col items-center space-y-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="animate-pulse mb-5 w-28 h-28"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <h1 class="text-center text-6xl font-bold">
                      Order Failed{" "}
                    </h1>
                    <p class="max-w-lg text-2xl text-center">
                      There has been an error while sending this order, please
                      contact your system admin or create a new one.
                      <div>
                        <span class=" text-red-600 text-xs font-medium block mt-3 ">
                          Error:{" "}
                          {
                            emailConfirmation.response.response.body.errors[0]
                              .message
                          }
                        </span>
                      </div>
                    </p>
                    <div class=" ">
                      <div class=" ">
                        <a
                          href="/app"
                          class="mt-2 order-0 inline-flex items-center rounded-md bg-red-700 px-8 py-3 text-sm font-medium text-white hover:opacity-80 focus:outline-none sm:order-1"
                        >
                          Back To Order Dashboard
                        </a>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : (
              <div class="animate-fade bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-sky-300 to-cyan-200 flex items-center justify-center h-screen">
                {emailConfirmation.response[0].statusCode === 202 ? (
                  <div class="animate-fade success bg-white p-20 shadow-xl rounded-3xl  ">
                    <div class="text-emerald-400 flex flex-col items-center space-y-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="animate-bounce mb-3 w-28 h-28"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                        />
                      </svg>
                      <h1 class="text-center text-emerald-900 text-6xl font-bold">
                        Confirmation Sent{" "}
                      </h1>{" "}
                      <p class="max-w-md text-center text-gray-700 text-2xl">
                        Registration confirmation has been successfully
                        processed and sent to the new user.
                        <div>
                          <span class=" text-green-600 text-xs font-medium block mt-3 ">
                            Email Sent: {emailConfirmation.time}
                          </span>
                        </div>
                      </p>
                      <div class=" ">
                        <a
                          href="/app"
                          class="mt-2 order-0 inline-flex items-center rounded-md bg-green-700 px-8 py-3 text-sm font-medium text-white hover:opacity-80 focus:outline-none sm:order-1"
                        >
                          Back To Order Dashboard
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div />
                )}

                {emailConfirmation.response === "" ? (
                  <div class="animate-fade p-4 rounded bg-white p-20 shadow-xl rounded-3xl  ">
                    <div class="flex flex-col items-center space-y-2">
                      <svg
                        aria-hidden="true"
                        class="mb-10 w-20 h-20 animate-spin text-white fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <h1 class="text-6xl text-tpvBlue font-bold">
                        Order Processing
                      </h1>
                      <p class="text-2xl text-gray-700">
                        Please wait while we send your order.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            )}
          </>
        ) : (
          <div class="create-user p-10 bg-blue-300 bg-opacity-20 lg:grid lg:grid-cols-8 lg:gap-x-5">
            <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
              <div>
                <div class="heading">
                  <nav class="sm:hidden" aria-label="Back">
                    <a
                      href="#"
                      class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      <svg
                        class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Back
                    </a>
                  </nav>
                  <nav class="hidden sm:flex" aria-label="Breadcrumb">
                    <ol role="list" class="flex items-center space-x-4">
                      <li>
                        <div class="flex">
                          <button
                            type="button"
                            onClick={() => {
                              const newPosition = "Dashboard-Home";
                              props.onChange(newPosition);
                            }}
                            class="text-sm font-medium text-blue-900 hover:text-gray-700"
                          >
                            Users
                          </button>
                        </div>
                      </li>
                      <li>
                        <div class="flex items-center">
                          <svg
                            class="h-5 w-5 flex-shrink-0 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <p class="ml-4 text-sm font-medium text-blue-900 underline">
                            Create Vendor Admin
                          </p>
                        </div>
                      </li>
                    </ol>
                  </nav>
                </div>
                <div class="mt-2 md:flex md:items-center md:justify-between">
                  <div class="min-w-0 flex-1">
                    <h2 class="text-2xl font-bold leading-7 text-blue-900 sm:truncate sm:text-3xl sm:tracking-tight">
                      Create Vendor Admin
                    </h2>
                  </div>
                </div>
              </div>
              <form action="#" method="POST">
                <div class="shadow sm:overflow-hidden sm:rounded-md">
                  <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
                    <div>
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Personal Information
                      </h3>
                      <p class="mt-1 text-sm text-gray-500"></p>
                    </div>
                    <div class="sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <div class="mt-2 sm:col-span-2 sm:mt-0">
                        {vendorAdmin.userImage === "" ? (
                          <div class="flex items-center">
                            <span class="h-14 w-14 overflow-hidden rounded-full bg-gray-100">
                              <svg
                                class="h-full w-full text-gray-300"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                              </svg>
                            </span>
                            <input
                              class="ml-4"
                              type="file"
                              accept="image/*"
                              onChange={handleImageUpload}
                              multiple="false"
                            />
                          </div>
                        ) : (
                          <div class="flex items-center">
                            <img
                              class="h-14 w-14 text-gray-300"
                              src={vendorAdmin.userImage}
                            ></img>

                            <span class="ml-2"> Profile Img Loaded</span>
                            <button
                              type="button"
                              onClick={clearImg}
                              class="ml-4 rounded-md bg-white py-1.5 px-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              Clear Image
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="grid grid-cols-6 gap-6">
                      <div class="col-span-6 sm:col-span-3">
                        <label
                          for="first-name"
                          class="block text-sm font-medium text-gray-700"
                        >
                          First Name <span class="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          onChange={handleChange}
                          autocomplete="given-name"
                          class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                        />
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label
                          for="last-name"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Last Name <span class="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          onChange={handleChange}
                          autocomplete="family-name"
                          class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                        />
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label
                          for="last-name"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Primary Phone
                        </label>
                        <input
                          type="number"
                          name="phone"
                          onWheel={(e) => e.target.blur()}
                          id="phone"
                          onChange={handleChange}
                          autocomplete="family-name"
                          class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                        />
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                        <label class="capitalize block text-sm font-medium text-gray-700">
                          What type of phone is this?
                        </label>
                        <select
                          id="telType"
                          name="telType"
                          onChange={handleChange}
                          class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                        >
                          <option value="Cell">Cell</option>
                          <option value="Work">Work</option>
                          <option value="Home">Home</option>
                        </select>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        {vendorAdmin.email.length < 6 ? (
                          <label
                            for="email"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Email <span class="text-red-600">*</span>
                          </label>
                        ) : (
                          <>
                            {emailCheck === true ? (
                              <>
                                {userEmails.includes(vendorAdmin.email) ? (
                                  <label
                                    for="email"
                                    class="block text-sm font-medium text-red-600"
                                  >
                                    This Email Is Currently Registered
                                  </label>
                                ) : (
                                  <label
                                    for="email"
                                    class="block text-sm font-medium text-gray-700"
                                  >
                                    Email <span class="text-red-600">*</span>
                                  </label>
                                )}
                              </>
                            ) : (
                              <label
                                for="email"
                                class="block text-sm font-medium text-red-600"
                              >
                                Enter A Valid Email Address
                              </label>
                            )}
                          </>
                        )}

                        <input
                          type="text"
                          name="email"
                          id="email"
                          onChange={handleChange}
                          class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                        />
                      </div>

                      {vendorAdmin.email.length === 0}

                      <div class="col-span-6 sm:col-span-3">
                        <label
                          for="country"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Vendor Assigned <span class="text-red-600">*</span>
                        </label>
                        <select
                          id="vendor"
                          name="vendor"
                          onChange={handleChange}
                          class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                        >
                          <option value="" selected disabled hidden>
                            Select Vendor
                          </option>
                          {props.vendorAccess[0].map((post) => {
                            return (
                              <>
                                <option value={JSON.stringify(post)}>
                                  {post.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      <div class="col-span-6">
                        <fieldset>
                          <legend class="text-base font-semibold leading-6 text-gray-900">
                            Brand Access <span class="text-red-600">*</span>
                          </legend>
                          <div class="mt-4 divide-x divide-gray-200 border-t border-b border-gray-200">
                            {props.supplierAccess[0].map((post) => {
                              return (
                                <>
                                  <div class="mr-6 relative flex inline-flex items-start py-4">
                                    <div class="ml-5 flex h-6 items-center">
                                      <input
                                        id={post}
                                        name={post}
                                        type="checkbox"
                                        onChange={handleCheckbox}
                                        class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                      />
                                    </div>
                                    <div class="ml-3 min-w-0 flex-1 text-sm leading-6">
                                      <label
                                        for="person-1"
                                        class="select-none font-base text-gray-900"
                                      >
                                        {post.replace(/_/g, " ")}
                                      </label>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div>
                {vendorAdmin.firstName !== "" &&
                vendorAdmin.lastName !== "" &&
                vendorAdmin.email !== "" &&
                typeof vendorAdmin.vendor === "string" &&
                finalSupplierSelect.length >= 1 &&
                userEmails.includes(vendorAdmin.email) === false ? (
                  <div class="text-center mt-4 flex sm:mt-0">
                    {SaveState === "saved" ? (
                      <button
                        type="button"
                        disabled
                        class=" w-full order-0 rounded-md bg-green-800 px-8 py-5 text-lg font-medium text-white hover:bg-blue-700 focus:outline-none sm:order-1"
                      >
                        Sending Welcome Email...
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={orderSubmissionEmail}
                        class=" w-full order-0 rounded-md bg-blue-900 px-8 py-5 text-lg font-medium text-white hover:bg-blue-700 focus:outline-none sm:order-1"
                      >
                        Create New Vendor Admin
                      </button>
                    )}
                  </div>
                ) : (
                  <div class="text-center mt-4 flex sm:mt-0">
                    <button
                      type="button"
                      disabled
                      class="opacity-60 w-full order-0 rounded-md bg-blue-900 px-8 py-5 text-lg font-medium text-white focus:outline-none sm:order-1"
                    >
                      Fill Out All Required Fields
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
};

export default CreateVendorAdmin;
