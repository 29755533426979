import React, { useState, useEffect } from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const CompleteAgentCall = (props) => {
  //
  //
  const [pullOrder, setpullOrder] = useState("");
  const [questions, setquestions] = useState("");
  //
  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "orders";
  //
  //
  //
  //
  //
  const findOrder = async () => {
    {
      const foundOrder = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("find-enrollment"), Number(props.orderNumber))
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setpullOrder(foundOrder.data[0].data);
      setquestions(foundOrder.data[0].data.Product.script.tpvQuestions);
    }
  };
  //
  //
  //

  //
  //
  //

  const BacktoDash = async () => {
    await client
      .query(
        q.Update(
          q.Select(
            "ref",
            q.Get(
              q.Match(q.Index("find-enrollment"), Number(props.orderNumber))
            )
          ),
          {
            data: {
              agentCallIn: {
                Status: "",
                Questions: "",
              },
            },
          }
        )
      )
      .then(function (res) {
        console.log("Result:", res);
        window.location.href = `/app`;
      });
  };
  //
  //
  //
  //
  //
  useEffect(() => {
    setInterval(() => {
      findOrder();
    }, 6000);

    // findOrder();
  }, []);
  //
  //
  //
  const people = [
    {
      name: "Lindsay Walton",
      title: "Front-end Developer",
      email: "lindsay.walton@example.com",
      role: "Member",
    },
    {
      name: "Lindsay Walton",
      title: "Front-end Developer",
      email: "lindsay.walton@example.com",
      role: "Member",
    },
    // More people...
  ];

  //
  //
  //
  //
  //
  //
  //
  console.log(pullOrder);
  console.log(questions[0]);
  //
  //
  if (Object.keys(pullOrder).length === 0) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                aria-hidden="true"
                class="mb-10 w-20 h-20 animate-spin text-white fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <h1 class="text-6xl text-tpvBlue font-bold">
                Preparing for Verification
              </h1>
              <p class="text-2xl text-gray-700">
                If order do not load please contact system admin.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <>
        <div class="min-h-screen animate-fade bg-gray-900 pb-28">
          <div class="pt-5">
            <div class="mt-10 flex items-center justify-center">
              <div class="ml-2 animate-fade p-8 rounded bg-white shadow-xl rounded-xl  ">
                <div class="flex flex-col items-center space-y-2 text-purple-800">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="animate-pulse mb-2 w-8 h-8"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M15 3.75a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V5.56l-4.72 4.72a.75.75 0 11-1.06-1.06l4.72-4.72h-2.69a.75.75 0 01-.75-.75z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <h1 class="text-md pb-2 text-tpvBlue font-medium">
                    Stay on the call for the entire process of verification
                  </h1>

                  <p class="text-lg text-gray-700">
                    Business Name:{" "}
                    <span class="text-green-700 font-bold">
                      {pullOrder.businessName}
                    </span>
                  </p>
                  <p class="text-lg text-gray-700">
                    Customer order number:{" "}
                    <span class="text-green-700 font-bold">
                      {props.orderNumber}
                    </span>
                  </p>
                  <p class="text-lg text-gray-700">
                    Customer primary:{" "}
                    <span class="text-green-700 font-bold">
                      {pullOrder.primaryFirstName} {pullOrder.primaryLastName}
                    </span>
                  </p>

                  <p className="pt-2 max-w-md text-center text-xs leading-6 text-gray-500">
                    You can restart the verification at a later time - but
                    within 24 hours.
                  </p>
                  <div class="">
                    <button
                      onClick={() => BacktoDash()}
                      class="mt-4 ml-4 order-0 inline-flex items-center rounded-md bg-tpvBlue px-8 py-3 text-sm font-medium text-white hover:opacity-80 focus:outline-none sm:order-1"
                    >
                      Back To Order Dashboard
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center"></div>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      {pullOrder.agentCallIn.Status === "Begin Verification" ||
                      pullOrder.agentCallIn.Status ===
                        "Verification Complete" ? (
                        <table className="divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Q#
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Question
                              </th>

                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Customer Response
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            <tr className="even:bg-gray-50">
                              <td className="whitespace-nowrap w-32 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"></td>
                              <td className="w-2/3 px-3 py-4 text-sm text-blue-500">
                                Verification will begin now....
                              </td>
                              <td className="w-1/3 whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                            </tr>
                          </tbody>

                          <tbody className="divide-y divide-gray-200 bg-white">
                            {pullOrder.agentCallIn.Questions.map(
                              (question, index) => (
                                <tr key={question} className="even:bg-gray-100">
                                  <td className="whitespace-nowrap w-32 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {index + 1}
                                  </td>
                                  <td className="w-2/3 px-3 py-4 text-sm text-gray-500">
                                    {question.charAt(0).toUpperCase() +
                                      question.split("$")[0].slice(1)}
                                  </td>

                                  <td className="w-1/3 font-semibold whitespace-nowrap px-3 py-4 text-sm">
                                    {question.split("$")[1] === "YES" ? (
                                      <span className="inline-flex items-center rounded-md bg-green-50 px-4 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        YES
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <table className="animate-pulse divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Q#
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Question
                              </th>

                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Customer Response
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            <tr className="even:bg-gray-50">
                              <td className="whitespace-nowrap w-32 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"></td>
                              <td className="w-2/3 px-3 py-4 text-sm text-gray-500">
                                Please merge call with customer then press 1 on
                                the call when you're ready to begin
                                verification.
                              </td>
                              <td className="w-1/3 whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {pullOrder.agentCallIn.Status === "Verification Complete" ? (
              <div class="mx-8">
                <div className="mt-4 rounded-md bg-green-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-green-800">
                        Verification Email Sent:
                      </h3>
                      <div className="mt-2 text-sm text-green-700">
                        <p>
                          <span class="font-bold">Code:</span>{" "}
                          {pullOrder.agentCallIn.verificationEmail},{" "}
                          <span class="font-bold">Time:</span>{" "}
                          {pullOrder.agentCallIn.timeCompleted}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => (window.location.href = `/app`)}
                  class="mt-6 animate-pulse flex items-center justify-center w-full mx-auto order-0 rounded-md bg-orange-500 py-5 text-lg font-medium text-white focus:outline-none sm:order-1"
                >
                  Verification Complete, Return Home
                </button>{" "}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
};
export default CompleteAgentCall;
