import React, { useState, useEffect } from "react";
import axios from "axios";
import CreateOrderForm from "./CreateOrderForm";
import OrderSubmitted from "./OrderSubmitted";
import OrderSubmittedEmail from "./OrderSubmittedEmail";

const CreateOrder = (props) => {
  //orderForm Navigation//
  function orderFormScreenChange(newValue) {
    setTimeout(function () {
      setorderFormScreen(newValue);
    }, 100);
  }

  //Get all Order Numbers, Products//
  useEffect(() => {
    getOrderNumbers();
    getProducts();
    getLocation();
  }, []);

  const [orderFormScreen, setorderFormScreen] = useState("");

  const [allExistingOrderNumbers, setallExistingOrderNumbers] = useState("");
  const [allExistingProducts, setallExistingProducts] = useState("");
  const [emailConfirmation, setemailConfirmation] = useState({
    response: "",
    time: "",
  });
  const [TwilioConfirmation, setTwilioConfirmation] = useState({
    status: "",
    message: "",
  });

  var orderStep = "";

  const [NewOrder, setNewOrder] = useState({
    firstName: "",
    lastName: "",
    actHolderFirst: "",
    actHolderLast: "",
    actHolderRelation: "Account-Holder",
    Language: "English",
    billingTel: "",
    telType: "Cell",
    email: "",
    said: "",
    accountNumber_1: "",
    accountNumber_2: "",
    serviceAddress: "",
    serviceSuite: "",
    serviceCity: "",
    serviceState: "AL",
    serviceZip: "",
    isBillingAddService: false,
    billingAddress: "",
    billingSuite: "",
    billingCity: "",
    billingState: "",
    billingZip: "",
    Product: "",
    deliveryMethod: "Text-Sms",
    verifyPhone: "",
    verifyEmail: "",
  });

  const [SubmitDisabled, setSubmitDisabled] = useState(false);

  function handleChange(e) {
    setNewOrder({
      ...NewOrder,
      [e.target.name]: e.target.value,
    });
  }

  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "orders";

  //
  //
  // Pull All Existing Order Numbers //
  //
  //
  //
  const getOrderNumbers = async () => {
    {
      const existingOrderNumbers = await client.query(
        q.Paginate(q.Match(q.Index("order-numbers")), { size: 6000 })
      );

      setallExistingOrderNumbers(existingOrderNumbers.data);
    }
  };
  //
  //
  //
  //
  //
  // Pull All Products //
  //
  //
  //
  const getProducts = async () => {
    {
      const allProducts = await client.query(
        q.Map(
          q.Paginate(q.Documents(q.Collection("products"))),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallExistingProducts(allProducts.data);
    }
  };

  //
  //
  //
  // searchProductSelected(ProductID) //
  // We want to search fauna collection for product matching ID selected from form
  //
  //
  // New Order Number //
  //
  //
  //
  //var orderNumberCheck = Math.floor(10000 + Math.random() * 90000);//
  var orderNumberCheck = Math.floor(1000000000 + Math.random() * 9000000000);
  var newOrderNumber = "";

  //
  //
  switch (allExistingOrderNumbers.includes(orderNumberCheck)) {
    case true:
      while (allExistingOrderNumbers.includes(orderNumberCheck) === true) {
        orderNumberCheck = Math.floor(1000000000 + Math.random() * 9000000000);
        var newOrderNumber = orderNumberCheck;
      }
      break;
    case false:
      var newOrderNumber = orderNumberCheck;
      break;
  }

  //
  //
  //
  //
  // GPS Stuff
  //
  //
  //
  //
  const [gpsData, setgpsData] = useState({
    lat: "",
    long: "",
    accuracy: "",
  });
  //
  //
  //
  //
  //
  //
  function getLocation() {
    if (!navigator.geolocation) {
    } else {
      navigator.geolocation.getCurrentPosition(success, showGpsError);
    }
  }

  function success(position) {
    setgpsData({
      ...gpsData,
      lat: position.coords.latitude,
      long: position.coords.longitude,
      accuracy: position.coords.accuracy,
    });
  }

  function showGpsError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        break;
      case error.POSITION_UNAVAILABLE:
        break;
      case error.TIMEOUT:
        break;
      case error.UNKNOWN_ERROR:
        break;
    }
  }
  //
  //
  //
  //
  //
  //
  //
  //
  //Create Database Entry//
  //
  //
  //
  const createOrderEntry = async (_callback) => {
    const inputDate = new Date().toGMTString();
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    await client.query(
      q.Create(q.Collection(COLLECTION_NAME), {
        data: {
          firstName: NewOrder.firstName,
          lastName: NewOrder.lastName,
          actHolderFirst: NewOrder.actHolderFirst,
          actHolderLast: NewOrder.actHolderLast,
          actHolderRelation: NewOrder.actHolderRelation,
          Language: NewOrder.Language,
          billingTel: NewOrder.billingTel,
          telType: NewOrder.telType,
          email: NewOrder.email,
          said: NewOrder.said,
          accountNumber_1: NewOrder.accountNumber_1,
          serviceAddress: NewOrder.serviceAddress,
          serviceSuite: NewOrder.serviceSuite,
          serviceCity: NewOrder.serviceCity,
          serviceState: NewOrder.serviceState,
          serviceZip: NewOrder.serviceZip,
          isBillingAddService: NewOrder.isBillingAddService,
          billingAddress: NewOrder.billingAddress,
          billingSuite: NewOrder.billingSuite,
          billingCity: NewOrder.billingCity,
          billingState: NewOrder.billingState,
          billingZip: NewOrder.billingZip,
          Product: JSON.parse(NewOrder.Product),
          deliveryMethod: NewOrder.deliveryMethod,
          verifyPhone: NewOrder.verifyPhone.replace(/[^+\d]+/g, ""),
          verifyEmail: NewOrder.verifyEmail,
          dateAdded: inputDate,
          dateAddedShort: formatDate(inputDate),
          orderNumber: newOrderNumber,
          agent: props.activeUserEmail.toString(),
          orderStatus: "Pending",
          latitude: gpsData.lat,
          longitude: gpsData.long,
          accuracy: gpsData.accuracy,
          supplier: props.activeSelectedDB,
        },
      })
    );
  };

  //
  //
  // End Fauna DB Shenanigans //
  //
  //
  //
  //
  //
  //
  // Begin SMS nonsense //
  //
  //

  switch (NewOrder.Product === "") {
    case true:
      var customerSupplier = "no supply";
      break;
    case false:
      var customerSupplier = JSON.parse(NewOrder.Product);
      var SupplierName = customerSupplier.supplier;
      break;
  }
  //
  //
  //
  //
  // Send SMS Function
  //
  //
  //
  const sendSMS = async () => {
    let response = await fetch(
      "https://opengatetpv.co/.netlify/functions/send_sms/",
      // "https://opengatetpv.co/.netlify/functions/send_sms/",//
      // "http://localhost:8888/.netlify/functions/send_sms/"//
      {
        method: "POST",
        body: JSON.stringify({
          user: NewOrder.verifyPhone.replace(/[^+\d]+/g, ""),
          orderNumber: newOrderNumber,
          supplier: props.activeSelectedDB,
          lastName: NewOrder.lastName,
        }),
      }
    );

    const twilioStatus = await response.status;
    const twilioMessage = await response.json();

    setTimeout(function () {
      setTwilioConfirmation({
        ...TwilioConfirmation,
        status: twilioStatus,
        message: twilioMessage,
      });
    }, 2000);
  };

  var twilioResponseMessage = "";

  switch (TwilioConfirmation.status) {
    case 400:
      twilioResponseMessage = TwilioConfirmation.message;
      break;
    case 200:
      twilioResponseMessage = TwilioConfirmation.message.dateUpdated;
      break;
  }

  //
  //
  // End SMS
  //
  //
  //
  //
  //
  // Start Email Confirmation
  //
  //
  //
  const sendEmail = async () => {
    const emailTime = new Date().toGMTString();
    let response = await fetch(
      "https://opengatetpv.co/.netlify/functions/send_email/",
      // "https://opengatetpv.co/.netlify/functions/send_email///
      // "http://localhost:8888/.netlify/functions/send_email/"//
      {
        method: "POST",
        body: JSON.stringify({
          user: NewOrder.verifyEmail,
          orderNumber: newOrderNumber,
          supplier: props.activeSelectedDB,
          firstName: NewOrder.firstName,
          lastName: NewOrder.lastName,
        }),
      }
    );
    const emailResponse = await response.json();

    setTimeout(function () {
      setemailConfirmation({
        ...emailConfirmation,
        response: emailResponse,
        time: emailTime,
      });
    }, 2000);
  };

  //
  //
  //
  //
  //

  switch (orderFormScreen) {
    case "Order-Submitted":
      orderStep = (
        <OrderSubmitted
          emailConfirmation={emailConfirmation}
          twilioResponseMessage={twilioResponseMessage}
          twilioStatus={TwilioConfirmation.status}
          positionChange={props.onChange}
        />
      );
      break;
    case "Order-Submitted-Email":
      orderStep = (
        <OrderSubmittedEmail
          emailConfirmation={emailConfirmation}
          twilioResponseMessage={twilioResponseMessage}
          twilioStatus={TwilioConfirmation.status}
          positionChange={props.onChange}
        />
      );
      break;
    default:
      orderStep = (
        <CreateOrderForm
          gpsData={gpsData}
          activeUserVendor={props.activeUserVendor}
          activeUserSupplier={props.activeUserSupplier}
          emailConfirmation={emailConfirmation}
          NewOrder={NewOrder}
          allExistingProducts={allExistingProducts}
          orderFormScreenChange={orderFormScreenChange}
          positionChange={props.onChange}
          addData={handleChange}
          createOrder={createOrderEntry}
          sms={sendSMS}
          email={sendEmail}
        />
      );
      break;
  }

  if (gpsData.lat === "") {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white text-red-600 p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="animate-pulse mb-5 w-28 h-28"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  clip-rule="evenodd"
                />
              </svg>
              <h1 class="text-6xl text-tpvRed font-bold">Location Not Found</h1>
              <p class="text-2xl text-gray-700">
                You must accept location services before creating an order.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else return <div>{orderStep}</div>;
};

export default CreateOrder;
