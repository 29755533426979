import React, { useState } from "react";

const ProductSelect = (props) => {
  return (
    <div class="animate-fade question inline-flex col-span-6 sm:col-span-6">
      <div class="w-full">
        <select
          name="unitMeasurement"
          onChange={props.onChange}
          class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-5 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
        >
          <option value="" selected disabled hidden>
            Select Option
          </option>
          <option value="Therm">Therm</option>
          <option value="Mcf">Mcf</option>
          <option value="ccf">ccf</option>
        </select>
      </div>

      <div class="ml-4 flex items-center">
        <div class="flex">
          <button
            type="button"
            name={props.questionName}
            onClick={props.counterDecrease}
            class="ml-2 inline-flex items-center justify-center rounded-md border border-red-200 bg-red-100 px-8 py-5 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductSelect;
