import React, { useState } from "react";

const CreateUser = (props) => {
  return (
    <div class="create-order-1 p-10 bg-blue-300 bg-opacity-20 lg:grid lg:grid-cols-8 lg:gap-x-5">
      <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <div>
          <div class="heading">
            <nav class="sm:hidden" aria-label="Back">
              <a
                href="#"
                class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                <svg
                  class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                    clip-rule="evenodd"
                  />
                </svg>
                Back
              </a>
            </nav>
            <nav class="hidden sm:flex" aria-label="Breadcrumb">
              <ol role="list" class="flex items-center space-x-4">
                <li>
                  <div class="flex">
                    <button
                      type="button"
                      onClick={() => {
                        const newPosition = "Dashboard-Home";
                        props.onChange(newPosition);
                      }}
                      class="text-sm font-medium text-blue-900 hover:text-gray-700"
                    >
                      Users
                    </button>
                  </div>
                </li>
                <li>
                  <div class="flex items-center">
                    <svg
                      class="h-5 w-5 flex-shrink-0 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <p class="ml-4 text-sm font-medium text-blue-900 underline">
                      Create User
                    </p>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
          <div class="mt-2 md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
              <h2 class="text-2xl font-bold leading-7 text-blue-900 sm:truncate sm:text-3xl sm:tracking-tight">
                Create User
              </h2>
            </div>
          </div>
        </div>
        <form action="#" method="POST">
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Personal Information
                </h3>
                <p class="mt-1 text-sm text-gray-500"></p>
              </div>

              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="first-name"
                    class="block text-sm font-medium text-gray-700"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autocomplete="given-name"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="last-name"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autocomplete="family-name"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="last-name"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Mobile Phone
                  </label>
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autocomplete="family-name"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="last-name"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autocomplete="family-name"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="country"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Role
                  </label>
                  <select
                    id="country"
                    name="country"
                    autocomplete="country-name"
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option>Admin</option>
                    <option>Supplier</option>
                    <option>Channel Manager</option>
                    <option>Sales Manager</option>
                    <option>Agent</option>
                  </select>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="country"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Vendor Assigned
                  </label>
                  <select
                    id="country"
                    name="country"
                    autocomplete="country-name"
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option>Spartan Call Center</option>
                    <option>Callective Door To Door</option>
                  </select>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="last-name"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Agent ID
                  </label>
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autocomplete="family-name"
                    placeholder="AG-30495"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div class="sm:col-span-6">
                  <input
                    class="cursor-pointer absolute block py-2 px-4 w-full opacity-0 pin-r pin-t"
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    accept="image/*"
                  ></input>

                  <button
                    type="button"
                    class="mt-1  bg-white font-medium text-blue-500 block w-full rounded-md border-2 border-dashed border-gray-300 py-5 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    Upload Profile Image
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="text-center mt-4 flex sm:mt-0">
          <button
            type="button"
            class="w-full order-0 rounded-md   bg-blue-900 px-8 py-5 text-lg font-medium text-white hover:bg-blue-700 focus:outline-none sm:order-1"
          >
            Submit New User
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
