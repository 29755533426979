import React, { useState, useEffect, Fragment } from "react";
import { Transition, Popover } from "@headlessui/react";
import IvrStatus from "./IvrStatus";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";

const DashboardScreen = (props) => {
  //
  //
  //
  useEffect(() => {
    getAllOrders();
    // setInterval(() => {
    //   getAllOrders();
    // }, 3000);
  }, []);
  //
  //
  // Filtering Stuff
  //
  // Expired - timed out
  // Undeliverable - undelivered
  // Pending - delivered
  // Complete - complete
  //
  //
  //
  //
  const [filterSelected, setfilterSelected] = useState({});
  const [isDataFiltered, setisDataFiltered] = useState(false);
  const [orderNoFilter, setorderNoFilter] = useState("default");
  const [orderNoControl, setorderNoControl] = useState("default");
  // Filter Control used to update states
  const [filterControl, setfilterControl] = useState(0);
  //
  const [statusFilterControl, setstatusFilterControl] = useState({
    complete: false,
    pending: false,
    expired: false,
    undeliverable: false,
  });

  const statusFilterChange = (event) => {
    setstatusFilterControl({
      ...statusFilterControl,
      [event.target.name]: event.target.checked,
    });
  };

  //
  //
  //
  // Order Number Filter
  //
  //
  //
  const handleOrderNoSortDefault = () => {
    setallExistingOrders(
      allExistingOrders.sort(function (a, b) {
        return b.ref.value.id - a.ref.value.id;
      })
    );
    setisDataFiltered(false);
    setfilterControl(filterControl + 1);
    setorderNoControl("default");
  };
  //
  //
  //
  const handleOrderNoSortDesc = () => {
    setallExistingOrders(
      allExistingOrders.sort(function (a, b) {
        return b.data.orderNumber - a.data.orderNumber;
      })
    );
    setisDataFiltered(true);
    window.history.replaceState(null, null, "?o=blahh");
    // setfilterControl(filterControl + 1);
    setorderNoControl("descending");
  };
  //
  //
  //
  const handleOrderNoSortAsc = () => {
    setallExistingOrders(
      allExistingOrders.sort(function (a, b) {
        return b.data.orderNumber - a.data.orderNumber;
      })
    );
    setallExistingOrders(allExistingOrders.reverse());

    setisDataFiltered(true);
    setfilterControl(filterControl + 1);
    setorderNoControl("ascending");
  };

  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const [allExistingOrders, setallExistingOrders] = useState("");

  //
  // Order Detail Screens
  //
  //
  //
  //
  //
  // Pull all Orders
  //
  //
  //
  //

  const getAllOrders = async () => {
    {
      const allOrders = await client.query(
        q.Reverse(
          q.Map(
            q.Paginate(
              q.Match(q.Index("agent-order-search"), props.activeUserEmail),
              { size: 6000 }
            ),
            q.Lambda((x) => q.Get(x))
          )
        )
      );

      setallExistingOrders(allOrders.data);
    }
  };

  //
  const CountButton = () => {
    const [count, setCount] = React.useState(0);

    return <button className="bg-black text-white">blahhhh testing</button>;
  };
  //
  //
  //
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  //
  function handleOnClick(rowData) {
    alert("navigation");
  }
  //
  //
  const testObject = { 1: "1", 2: "2", 3: "3", 4: "" };

  //
  //
  //
  //
  //
  if (Object.keys(allExistingOrders).length === 0) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div class="lg:border-b lg:border-gray-200 lg:py-4 lg:flex lg:items-center lg:justify-between lg:px-8">
          <div class="hidden lg:flex flex-1 justify-between lg:mx-auto">
            <div class="flex flex-1">
              <label for="search-field" class="sr-only">
                Search
              </label>
              <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                  aria-hidden="true"
                >
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search-field"
                  name="search-field"
                  class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                  placeholder="Search Orders"
                  type="search"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                aria-hidden="true"
                class="mb-10 w-20 h-20 animate-spin text-white fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <h1 class="text-6xl text-tpvBlue font-bold">Loading Orders</h1>
              <p class="text-2xl text-gray-700">
                If orders do not load please contact system admin.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <>
        <main class="hidden lg:block animate-fade dashboard">
          <div class="lg:border-b lg:border-gray-200 lg:py-4 lg:flex lg:items-center lg:justify-between lg:px-8">
            <div class="hidden lg:flex flex-1 justify-between lg:mx-auto">
              <div class="flex flex-1">
                <label for="search-field" class="sr-only">
                  Search
                </label>
                <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div
                    class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                    aria-hidden="true"
                  >
                    <svg
                      class="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    id="search-field"
                    name="search-field"
                    class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                    placeholder="Search Orders"
                    type="search"
                  />
                </div>
              </div>
            </div>

            <div class="hidden mt-4 sm:mt-0 sm:ml-4">
              <button
                type="button"
                onClick={() => {
                  const newPosition = "Create-Order";
                  props.onChange(newPosition);
                }}
                class="order-0 inline-flex items-center rounded-md bg-gradient-to-r from-orange-500 to-tpvRed px-12 py-3 text-sm font-medium text-white hover:opacity-80 focus:outline-none sm:order-1 sm:ml-3"
              >
                New Order
              </button>
            </div>
            <div class="hidden lg:flex order-selection">
              <span class="isolate inline-flex rounded-md">
                <button
                  type="button"
                  onClick={() => {
                    const newPosition = "Create-Order-Commercial";
                    props.onChange(newPosition);
                  }}
                  class="relative inline-flex items-center rounded-l-md border border-white bg-gradient-to-l from-blue-700 to-sky-500 px-12 py-3 text-sm font-medium text-white hover:opacity-70 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="mr-2 w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zM12.75 12a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V18a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V12z"
                      clip-rule="evenodd"
                    />
                    <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                  </svg>
                  Commercial{" "}
                </button>

                <button
                  type="button"
                  onClick={() => {
                    const newPosition = "Create-Order";
                    props.onChange(newPosition);
                  }}
                  class="relative -ml-px inline-flex items-center rounded-r-md border border-white bg-gradient-to-r from-orange-500 to-tpvRed px-12 py-3 text-sm font-medium text-white hover:opacity-70 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="mr-2 w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zM12.75 12a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V18a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V12z"
                      clip-rule="evenodd"
                    />
                    <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                  </svg>
                  Residential
                </button>
              </span>
            </div>
            <div class="w-full lg:hidden">
              <button
                type="button"
                onClick={() => {
                  const newPosition = "Create-Order-Commercial";
                  props.onChange(newPosition);
                }}
                class="mb-2 w-full bg-gradient-to-l from-blue-700 to-sky-500 py-5 text-xl font-medium text-white hover:opacity-80 focus:outline-none "
              >
                New Commercial Order
              </button>
              <button
                type="button"
                onClick={() => {
                  const newPosition = "Create-Order";
                  props.onChange(newPosition);
                }}
                class="w-full bg-gradient-to-r from-orange-500 to-tpvRed py-5 text-xl font-medium text-white hover:opacity-80 focus:outline-none "
              >
                New Residential Order
              </button>
            </div>
          </div>
          <div class="mt-8 flow-root overflow-hidden">
            <div class="flex justify-between">
              <div class="ml-6">
                <span class="opacity-60 inline-flex rounded-md bg-white px-4 py-2.5 text-sm font-semibold bg-purple-50 text-purple-700 ring-1 ring-inset ring-purple-700/50">
                  {props.activeUser[0].data.vendor.name}
                </span>
                <h2 class="pt-3  pb-5 text-2xl font-semibold text-gray-900">
                  Your Recent Orders{" "}
                  <span class="text-gray-400 italic">
                    {props.activeSelectedDB}
                  </span>{" "}
                </h2>
              </div>
            </div>
            <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
              <div class="inline-block overflow-scroll max-w-7xl min-w-full py-2 align-middle sm:px-6 lg:px-12">
                <table class="min-w-full divide-y divide-gray-300 flex">
                  <DataGrid
                    getRowClassName={(params) => {
                      if (params.row.dwelling === "Commercial") {
                        return "commercial";
                      }

                      if (params.row.isIVRverification === true) {
                        return "ivr";
                      }
                    }}
                    //
                    //
                    sx={{
                      ".commercial": {
                        bgcolor: "#f2faff",
                        "&:hover": {
                          bgcolor: "#e8f6fe",
                        },
                      },
                      ".ivr": {
                        bgcolor: "#fefceb",
                        "&:hover": {
                          bgcolor: "#fdfae0",
                        },
                      },
                      ".MuiDataGrid-columnHeaders": {
                        background: `linear-gradient(
                        90deg,
                        rgba(170, 170, 255, 0.2) 35%,
                        rgba(171, 241, 255, 0.3) 100%
                      )`,
                      },
                    }}
                    columns={[
                      {
                        field: "id",
                        headerName: "Order No.",
                        width: 250,
                        renderCell: (params) => {
                          if (params.row.dwelling === "Commercial") {
                            return (
                              <button
                                class="text-black font-medium hover:opacity-70"
                                type="button"
                                onClick={() => {
                                  props.orderDetailID(params.value);
                                  props.onChange("Commercial-Order-Details");
                                }}
                              >
                                {" "}
                                <span class="truncate">{params.value}</span>
                              </button>
                            );
                          }

                          if (params.row.dwelling === "Residential") {
                            return (
                              <button
                                class="text-black font-medium hover:opacity-70"
                                type="button"
                                onClick={() => {
                                  props.orderDetailID(params.value);
                                  props.onChange("Order-Details");
                                }}
                              >
                                {" "}
                                <span class="truncate">{params.value}</span>
                              </button>
                            );
                          }
                        },
                      },
                      {
                        field: "status",
                        headerName: "Status",
                        width: 225,
                        renderCell: (params) => {
                          if (params.row.isIVRverification === true) {
                            return (
                              <IvrStatus status={params.row.IVRcallStatus} />
                            );
                          }
                          if (params.value === "Expired") {
                            return (
                              <span class="inline-flex items-center rounded-md bg-violet-100 px-4 py-3 text-xs font-medium text-violet-900 ring-1 ring-inset ring-violet-600/50">
                                Timed Out
                              </span>
                            );
                          }

                          if (params.value === "Undeliverable") {
                            return (
                              <span class="inline-flex items-center rounded-md bg-yellow-100 px-4 py-3 text-xs font-medium text-yellow-900 ring-1 ring-inset ring-yellow-600/50">
                                Undelivered
                              </span>
                            );
                          }

                          if (params.value === "Pending") {
                            return (
                              <span class="inline-flex items-center rounded-md bg-orange-100 px-4 py-3 text-xs font-medium text-orange-900 ring-1 ring-inset ring-orange-600/50">
                                Delivered
                              </span>
                            );
                          }

                          if (params.value === "Complete") {
                            return (
                              <span class="inline-flex items-center rounded-md bg-green-100 px-4 py-3 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/50">
                                Completed
                              </span>
                            );
                          }
                        },
                      },

                      {
                        field: "dateAdded",
                        headerName: "Date Created",
                        width: 320,
                        renderCell: (params) => {
                          if (params.row.status === "Pending") {
                            return params.value;
                          }

                          if (params.row.status === "Complete") {
                            return (
                              <>
                                <span>
                                  Created - {params.value}
                                  <span class="text-green-500 font-medium block">
                                    Verified - {params.row.verified}
                                  </span>
                                </span>
                              </>
                            );
                          }
                        },
                      },

                      {
                        field: "name",
                        headerName: "Customer Name",
                        width: 260,
                        renderCell: (params) => {
                          if (params.row.dwelling === "Commercial") {
                            return params.row.business.replace(/-/g, " ");
                          }

                          if (params.row.dwelling === "Residential") {
                            return params.value.replace(/-/g, " ");
                          }
                        },
                      },

                      {
                        field: "dwelling",
                        headerName: "Dwelling",
                        width: 80,
                        renderCell: (params) => {
                          if (params.value === "Commercial") {
                            return (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-8 h-8 text-cyan-700"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                                />
                              </svg>
                            );
                          }

                          if (params.value === "Residential") {
                            return (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-8 h-8 text-orange-700"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                />
                              </svg>
                            );
                          }
                        },
                      },

                      {
                        field: "delivery",
                        headerName: "Delivery",
                        width: 100,
                        renderCell: (params) => {
                          if (params.value === "Text-Sms") {
                            return (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-8 h-8 text-gray-500"
                              >
                                <title>Text-Sms</title>
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                                />
                              </svg>
                            );
                          }

                          if (params.value === "Email") {
                            return (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-8 h-8 text-gray-500"
                              >
                                <title>Email</title>
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                />
                              </svg>
                            );
                          }

                          if (params.value === "IVR") {
                            return (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-8 h-8 text-gray-500"
                              >
                                <title>IVR</title>
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                                />
                              </svg>
                            );
                          }

                          if (params.value === "Agent-Call") {
                            return (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-8 h-8 text-gray-500"
                              >
                                <title>Agent-Call</title>
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
                                />
                              </svg>
                            );
                          }
                        },
                      },
                    ]}
                    columnHeaderHeight={80}
                    rowHeight={70}
                    rows={allExistingOrders.map((post) =>
                      //
                      ({
                        id: post.data.orderNumber,
                        key: post.ref.value.id,
                        name: post.data.firstName + " " + post.data.lastName,
                        status: post.data.orderStatus,
                        vendorName: post.data.Product.vendor.name,
                        positionChange: props.onChange,
                        orderDetailID: props.orderDetailID,
                        dateAdded: post.data.dateAdded,
                        dateVerified: post.data.timeVerified,
                        delivery: post.data.deliveryMethod,
                        dwelling: post.data.Product.dwelling,
                        business: post.data.businessName,
                        isIVRverification: post.data.isIVRverification,
                        IVRcallStatus: post.data.IVRcallStatus,
                        verified: post.data.timeVerified,
                      })
                    )}
                    // onRowClick={(param) => handleOnClick(param.row)}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </table>
              </div>
            </div>
          </div>
        </main>

        <mobile class="lg:hidden animate-fade dashboard flex-1">
          <div class="lg:border-b lg:border-gray-200 lg:py-4 lg:flex lg:items-center lg:justify-between lg:px-8">
            <div class="w-full lg:hidden">
              <button
                type="button"
                onClick={() => {
                  const newPosition = "Create-Order-Commercial";
                  props.onChange(newPosition);
                }}
                class="mb-2 w-full bg-gradient-to-l from-blue-700 to-sky-500 py-5 text-xl font-medium text-white hover:opacity-80 focus:outline-none "
              >
                New Commercial Order
              </button>
              <button
                type="button"
                onClick={() => {
                  const newPosition = "Create-Order";
                  props.onChange(newPosition);
                }}
                class="w-full bg-gradient-to-r from-orange-500 to-tpvRed py-5 text-xl font-medium text-white hover:opacity-80 focus:outline-none "
              >
                New Residential Order
              </button>
            </div>
          </div>
          <div class="mt-8 flow-root overflow-auto">
            <div class="flex justify-center">
              <div class="ml-6 text-center">
                <span class="opacity-60 inline-flex rounded-md bg-white px-4 py-2.5 text-sm font-semibold bg-purple-50 text-purple-700 ring-1 ring-inset ring-purple-700/50">
                  {props.activeUser[0].data.vendor.name}
                </span>
                <h2 class="pt-3  pb-5 text-2xl font-semibold text-gray-900">
                  Your Recent Orders{" "}
                  <span class="block text-center text-gray-400 italic">
                    {props.activeSelectedDB}
                  </span>{" "}
                </h2>
              </div>
            </div>
            <div class="">
              <div class="inline-block overflow-hidden max-w-7xl min-w-full py-2 align-middle px-2">
                <div class="hidden inline-flex mb-4">
                  <div className="order no z-40 w-full ">
                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`
                ${open ? "" : "text-opacity-90"}
                ${
                  orderNoControl === "ascending" ||
                  orderNoControl === "descending"
                    ? "bg-blue-50"
                    : "ring-gray-700/50 bg-white"
                }



                group inline-flex rounded-md px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset hover:bg-gray-50`}
                          >
                            <span className="whitespace-nowrap">
                              Order Number
                            </span>
                            <ChevronDownIcon
                              className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 text-gray-600 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                              aria-hidden="true"
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute mt-3 w-screen max-w-sm px-4 sm:px-0 ">
                              <div className="absolute right-30 z-10 overflow-hidden rounded-lg shadow-xl ring-1 ring-black/20">
                                <div className="">
                                  <div className="relative bg-white pt-6 pb-16  pl-3 pr-12">
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="order-no-asc"
                                        name="notification-method"
                                        type="radio"
                                        onClick={() =>
                                          setorderNoFilter("ascending")
                                        }
                                        checked={orderNoFilter === "ascending"}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="order-no-asc"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Ascending
                                      </label>
                                    </div>
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="order-no-desc"
                                        name="notification-method"
                                        type="radio"
                                        onClick={() =>
                                          setorderNoFilter("descending")
                                        }
                                        checked={orderNoFilter === "descending"}
                                        className="mt-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="order-no-desc"
                                        className="mt-2 ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Descending
                                      </label>
                                    </div>
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="order-no-default"
                                        name="notification-method"
                                        type="radio"
                                        onClick={() =>
                                          setorderNoFilter("default")
                                        }
                                        checked={orderNoFilter === "default"}
                                        className="mt-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="order-no-default"
                                        className="mt-2 ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Default
                                      </label>
                                    </div>
                                  </div>

                                  <div className="">
                                    {orderNoFilter === "default" ? (
                                      <button
                                        onClick={() =>
                                          handleOrderNoSortDefault(close())
                                        }
                                        className="absolute bottom-0 w-full rounded-b-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      >
                                        Apply
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {orderNoFilter === "descending" ? (
                                      <button
                                        onClick={() =>
                                          handleOrderNoSortDesc(close())
                                        }
                                        className="absolute bottom-0 w-full rounded-b-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      >
                                        Apply
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {orderNoFilter === "ascending" ? (
                                      <button
                                        onClick={() =>
                                          handleOrderNoSortAsc(close())
                                        }
                                        className="absolute bottom-0 w-full rounded-b-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      >
                                        Apply
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>

                  <div className="status ml-3 z-40 w-full ">
                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-700/50 hover:bg-gray-50`}
                          >
                            <span className="whitespace-nowrap">Status</span>
                            <ChevronDownIcon
                              className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 text-gray-600 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                              aria-hidden="true"
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute mt-3 w-screen max-w-sm px-4 sm:px-0 ">
                              <div className="absolute right-30 z-10 overflow-hidden rounded-lg shadow-xl ring-1 ring-black/20">
                                <div className="">
                                  <div className="relative bg-white pt-6 pb-16  pl-3 pr-12">
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="complete"
                                        name="complete"
                                        type="checkbox"
                                        defaultChecked={false}
                                        value={statusFilterControl.complete}
                                        onChange={statusFilterChange}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="complete"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Complete
                                      </label>
                                    </div>
                                    <div className="mt-2 flex items-center hover:opacity-80">
                                      <input
                                        id="pending"
                                        name="pending"
                                        type="checkbox"
                                        defaultChecked={false}
                                        value={statusFilterControl.pending}
                                        onChange={statusFilterChange}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="pending"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Delivered
                                      </label>
                                    </div>
                                    <div className="mt-2 flex items-center hover:opacity-80">
                                      <input
                                        id="expired"
                                        name="expired"
                                        type="checkbox"
                                        defaultChecked={false}
                                        value={statusFilterControl.expired}
                                        onChange={statusFilterChange}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="expired"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Timed Out
                                      </label>
                                    </div>
                                    <div className="mt-2 flex items-center hover:opacity-80">
                                      <input
                                        id="undeliverable"
                                        name="undeliverable"
                                        type="checkbox"
                                        defaultChecked={false}
                                        value={
                                          statusFilterControl.undeliverable
                                        }
                                        onChange={statusFilterChange}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="undeliverable"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Issue
                                      </label>
                                    </div>
                                  </div>

                                  <div className="">
                                    <button
                                      onClick={() => close()}
                                      className="absolute bottom-0 w-full rounded-b-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>

                  <div className="more ml-3 z-40 w-full ">
                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex rounded-md bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-700/50 hover:bg-gray-50`}
                          >
                            <span className="whitespace-nowrap">More</span>
                            <ChevronDownIcon
                              className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 text-gray-600 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                              aria-hidden="true"
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute mt-3 w-screen max-w-sm px-4 sm:px-0 ">
                              <div className="absolute right-30 z-10 overflow-hidden rounded-lg shadow-xl ring-1 ring-black/20">
                                <div className="">
                                  <div className="relative bg-white pt-6 pb-16  pl-3 pr-12">
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="asc"
                                        name="notification-method"
                                        type="radio"
                                        // defaultChecked={notificationMethod.id === 'email'}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="asc"
                                        className="ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Ascending
                                      </label>
                                    </div>
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="desc"
                                        name="notification-method"
                                        type="radio"
                                        // defaultChecked={notificationMethod.id === 'email'}
                                        className="mt-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="desc"
                                        className="mt-2 ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Descending
                                      </label>
                                    </div>
                                    <div className="flex items-center hover:opacity-80">
                                      <input
                                        id="desc"
                                        name="notification-method"
                                        type="radio"
                                        // defaultChecked={notificationMethod.id === 'email'}
                                        className="mt-2 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                      <label
                                        for="desc"
                                        className="mt-2 ml-3 block text-sm leading-6 text-gray-600"
                                      >
                                        Default
                                      </label>
                                    </div>
                                  </div>

                                  <div className="">
                                    <button
                                      onClick={() => close()}
                                      className="absolute bottom-0 w-full rounded-b-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>
                </div>

                <table class="min-w-full divide-y divide-gray-300 flex">
                  <DataGrid
                    //
                    //
                    slots={{
                      columnHeaders: () => null,
                    }}
                    //
                    //
                    getRowClassName={(params) => {
                      if (params.row.dwelling === "Commercial") {
                        return "commercial";
                      }

                      if (params.row.isIVRverification === true) {
                        return "ivr";
                      }
                    }}
                    //
                    //
                    sx={{
                      ".MuiDataGrid-columnHeaders": {
                        background: `linear-gradient(
                        90deg,
                        rgba(170, 170, 255, 0.2) 35%,
                        rgba(171, 241, 255, 0.3) 100%
                      )`,
                      },
                    }}
                    columns={[
                      {
                        field: "id",
                        headerName: "Order No.",
                        headerAlign: "left",
                        width: 130,
                        renderCell: (params) => {
                          if (params.row.dwelling === "Commercial") {
                            return (
                              <button
                                class="text-black font-medium hover:opacity-70"
                                type="button"
                                onClick={() => {
                                  props.orderDetailID(params.value);
                                  props.onChange("Commercial-Order-Details");
                                }}
                              >
                                {" "}
                                <span class="truncate">{params.value}</span>
                              </button>
                            );
                          }

                          if (params.row.dwelling === "Residential") {
                            return (
                              <button
                                class="text-black font-medium hover:opacity-70"
                                type="button"
                                onClick={() => {
                                  props.orderDetailID(params.value);
                                  props.onChange("Order-Details");
                                }}
                              >
                                {" "}
                                <span class="truncate">{params.value}</span>
                              </button>
                            );
                          }
                        },
                      },
                      {
                        field: "status",
                        headerName: "Status",
                        headerAlign: "left",
                        width: 140,
                        renderCell: (params) => {
                          if (params.row.isIVRverification === true) {
                            return (
                              <IvrStatus status={params.row.IVRcallStatus} />
                            );
                          }
                          if (params.value === "Expired") {
                            return (
                              <span class="inline-flex items-center rounded-md bg-violet-100 px-4 py-3 text-xs font-medium text-violet-900 ring-1 ring-inset ring-violet-600/50">
                                Timed Out
                              </span>
                            );
                          }

                          if (params.value === "Undeliverable") {
                            return (
                              <span class="inline-flex items-center rounded-md bg-yellow-100 px-4 py-3 text-xs font-medium text-yellow-900 ring-1 ring-inset ring-yellow-600/50">
                                Undelivered
                              </span>
                            );
                          }

                          if (params.value === "Pending") {
                            return (
                              <span class="inline-flex items-center rounded-md bg-orange-100 px-4 py-3 text-xs font-medium text-orange-900 ring-1 ring-inset ring-orange-600/50">
                                Delivered
                              </span>
                            );
                          }

                          if (params.value === "Complete") {
                            return (
                              <span class="inline-flex items-center rounded-md bg-green-100 px-4 py-3 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/50">
                                Completed
                              </span>
                            );
                          }
                        },
                      },

                      {
                        field: "dwelling",
                        headerName: "Dwelling",
                        headerAlign: "left",
                        width: 80,
                        renderCell: (params) => {
                          if (params.value === "Commercial") {
                            return (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-8 h-8 text-cyan-700"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                                />
                              </svg>
                            );
                          }

                          if (params.value === "Residential") {
                            return (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-8 h-8 text-orange-700"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                />
                              </svg>
                            );
                          }
                        },
                      },
                      {
                        field: "delivery",
                        headerName: "Delivery",
                        headerAlign: "left",
                        width: 100,
                        renderCell: (params) => {
                          if (params.value === "Text-Sms") {
                            return (
                              <div class="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-8 h-8 text-gray-500"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                                  />
                                </svg>
                              </div>
                            );
                          }

                          if (params.value === "Email") {
                            return (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-8 h-8 text-gray-500"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                />
                              </svg>
                            );
                          }

                          if (params.value === "IVR") {
                            return (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-8 h-8 text-gray-500"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                                />
                              </svg>
                            );
                          }
                        },
                      },
                    ]}
                    columnHeaderHeight={80}
                    rowHeight={70}
                    rows={allExistingOrders.map((post) =>
                      //
                      ({
                        id: post.data.orderNumber,
                        key: post.ref.value.id,
                        name: post.data.firstName + " " + post.data.lastName,
                        status: post.data.orderStatus,
                        vendorName: post.data.Product.vendor.name,
                        positionChange: props.onChange,
                        orderDetailID: props.orderDetailID,
                        dateAdded: post.data.dateAdded,
                        dateVerified: post.data.timeVerified,
                        delivery: post.data.deliveryMethod,
                        dwelling: post.data.Product.dwelling,
                        business: post.data.businessName,
                        isIVRverification: post.data.isIVRverification,
                        IVRcallStatus: post.data.IVRcallStatus,
                        verified: post.data.timeVerified,
                      })
                    )}
                    // onRowClick={(param) => handleOnClick(param.row)}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </table>
              </div>
            </div>
          </div>
        </mobile>
      </>
    );
};

export default DashboardScreen;
