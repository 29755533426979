import React, { useState, useEffect } from "react";
import OrderDashItem from "./ReportDashItem";
import axios from "axios";

const ReportDashboardScreen = (props) => {
  useEffect(() => {
    getAllNoSales();
    getAllOrders();
  }, []);
  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "orders";
  const [allExistingOrders, setallExistingOrders] = useState("");
  const [allNoSales, setallNoSales] = useState("");

  const [loadOrderDetails, setloadOrderDetails] = useState("blah");
  //
  //
  // Order Detail Screens

  //
  //
  //
  //
  // Pull all Completed Orders
  //
  //
  //
  //
  const getAllOrders = async () => {
    {
      const allOrders = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("completed-tpvs-by-vendor"), [
              Number(props.activeUserVendor),
              "Complete",
            ]),
            { size: 6000 }
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallExistingOrders(allOrders.data);
    }
  };

  //
  //
  //
  //
  //
  const getAllNoSales = async () => {
    {
      const noSales = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("completed-tpvs-by-vendor"), [
              Number(props.activeUserVendor),
              "Pending",
            ])
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallNoSales(noSales.data);
    }
  };

  //
  //
  //
  //
  if (
    Object.keys(allExistingOrders).length === 0 ||
    Object.keys(allNoSales).length === 0
  ) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                aria-hidden="true"
                class="mb-10 w-20 h-20 animate-spin text-white fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <h1 class="text-6xl text-tpvBlue font-bold">Loading Orders</h1>
              <p class="text-2xl text-gray-700">
                If orders do not load please contact system admin.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <main class="animate-fade dashboard flex-1">
        <div class="lg:border-b lg:border-gray-200 lg:py-4 lg:flex lg:items-center lg:justify-between lg:px-8">
          <div class="hidden lg:flex flex-1 justify-between lg:mx-auto">
            <div class="flex flex-1">
              <label for="search-field" class="sr-only">
                Search
              </label>
              <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                  aria-hidden="true"
                >
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search-field"
                  name="search-field"
                  class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                  placeholder="Search Orders"
                  type="search"
                />
              </div>
            </div>
            <div class="hidden lg:block order-selection">
              <span class="isolate inline-flex rounded-md">
                <button
                  type="button"
                  onClick={() => {
                    const newPosition = "View-Commercial-TPVs";
                    props.onChange(newPosition);
                  }}
                  class="inline-flex items-center rounded-md bg-gradient-to-l from-orange-500 to-tpvRed px-12 py-3 text-sm font-medium text-white hover:opacity-70 focus:outline-none"
                >
                  Commercial
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const newPosition = "Dashboard-Home";
                    props.onChange(newPosition);
                  }}
                  class="ml-2 inline-flex items-center rounded-md bg-gradient-to-l from-blue-700 to-sky-500 px-12 py-3 text-sm font-medium text-white hover:opacity-70 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="mr-2 w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                    />
                  </svg>
                  Downloads
                </button>
              </span>
            </div>
          </div>
        </div>
        <div class="mt-8 bg-gradient-to-r from-green-400 to-white">
          <div class="inline-block min-w-full align-middle">
            <h2 class="px-4 py-5 sm:px-6 text-2xl font-semibold text-white">
              Completed Residential TPVs
            </h2>

            <table class="w-full">
              <thead>
                <tr class="border-t border-gray-200">
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Account #</span>
                  </th>

                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility Name</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility Address</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility City</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility State</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility Zip</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Service Level</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility Contact Name</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility Phone</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Sale Date</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Deal Start Date</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Deal End Date</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Sales Agent</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Deal Group Code</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Utility</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Sales Group Code</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Language</span>
                  </th>
                </tr>
              </thead>

              {allExistingOrders.map((post) => {
                //

                return (
                  <OrderDashItem
                    key={post.ref.value.id}
                    order={post}
                    positionChange={props.onChange}
                  />
                );
              })}
            </table>

            <div
              class="bg-gray-50 py-3 text-sm font-semibold text-gray-900"
              colspan="6"
            >
              <span class="inline-flex items-center rounded-md bg-purple-100 px-5 py-1 text-sm font-medium text-purple-800">
                Completed Orders: {allExistingOrders.length}
              </span>
            </div>
          </div>
        </div>

        <div class="no sales mt-14 bg-gradient-to-r from-red-400 to-white">
          <div class="inline-block min-w-full align-middle">
            <h2 class="px-4 py-5 sm:px-6 text-2xl font-semibold text-white">
              Timed Out TPVs
            </h2>
            <table class="w-full ">
              <thead>
                <tr class="border-t border-gray-200">
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Account #</span>
                  </th>

                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility Name</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility Address</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility City</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility State</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility Zip</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Service Level</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility Contact Name</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Facility Phone</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Sale Date</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Deal Start Date</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Deal End Date</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Sales Agent</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Deal Group Code</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Utility</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Sales Group Code</span>
                  </th>
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-xs font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Language</span>
                  </th>
                </tr>
              </thead>

              {allNoSales.map((post) => {
                //

                return (
                  <OrderDashItem
                    key={post.ref.value.id}
                    order={post}
                    positionChange={props.onChange}
                  />
                );
              })}
            </table>

            <div
              class="bg-gray-50 py-3 text-sm font-semibold text-gray-900"
              colspan="6"
            >
              <span class="inline-flex items-center rounded-md bg-red-100 px-5 py-1 text-sm font-medium text-red-800">
                Uncompleted Orders: {allNoSales.length}
              </span>
            </div>
          </div>
        </div>
      </main>
    );
};

export default ReportDashboardScreen;
