import React, { useState, useEffect } from "react";
import axios from "axios";

function formatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

function formatVerifyPhoneNumber(value) {
  if (!value) return value;
  const verifyPhoneNumber = value.replace(/[^\d]/g, "");
  const verifyPhoneNumberLength = verifyPhoneNumber.length;
  if (verifyPhoneNumberLength < 4) return verifyPhoneNumber;
  if (verifyPhoneNumberLength < 7) {
    return `(${verifyPhoneNumber.slice(0, 3)}) ${verifyPhoneNumber.slice(3)}`;
  }
  return `(${verifyPhoneNumber.slice(0, 3)}) ${verifyPhoneNumber.slice(
    3,
    6
  )}-${verifyPhoneNumber.slice(6, 10)}`;
}

const CreateOrderForm = (props) => {
  const [isBillingTheSame, setisBillingTheSame] = useState(false);
  const [ProductSelected, setProductSelected] = useState(null);
  const [allExistingUtilities, setallExistingUtilities] = useState("");
  const [SaveState, setSaveState] = useState("unsaved");

  const [inputValue, setInputValue] = useState("");
  const [inputVerifyPhoneValue, setInputVerifyPhone] = useState("");

  const handleInput = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setInputValue(formattedPhoneNumber);
  };

  const handleVerifyInput = (e) => {
    const formattedVerifyPhoneNumber = formatVerifyPhoneNumber(e.target.value);
    setInputVerifyPhone(formattedVerifyPhoneNumber);
  };

  const handleChange = (event) => {
    setisBillingTheSame(!isBillingTheSame);
  };
  //
  //
  //
  function selectProduct(e) {
    //props.searchProductSelected(ProductID); "This gives CreateOrder the product ID to search and fills in missing data"
    setProductSelected(e.target.value);
    props.addData(e);
  }
  //
  //
  //
  useEffect(() => {
    getAllUtilities();
    getAllProducts();
  }, []);
  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "products";
  const [allExistingProducts, setallExistingProducts] = useState("");
  const [allFilteredProducts, setallFilteredProducts] = useState("");
  const [selectedUtility, setselectedUtility] = useState({
    utility: null,
    commodity: null,
  });
  //
  //
  function handleUtility(e) {
    setselectedUtility({
      ...selectedUtility,
      [e.target.name]: e.target.value,
    });
  }
  //
  //
  // Pull all Products
  //
  //
  //
  //
  const getAllProducts = async () => {
    {
      const allProducts = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("products-by-vendor-and-dwelling"), [
              "Residential",
              Number(props.activeUserVendor),
            ])
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallExistingProducts(allProducts.data);
    }
  };

  //
  //
  //
  // Product Selected
  //
  //
  var selectedProductParsed = "default";

  switch (typeof ProductSelected === "string") {
    case true:
      var selectedProductParsed = JSON.parse(ProductSelected);
      break;
    case false:
      var selectedProductParsed = "not string";
      break;
  }

  //
  //
  //
  // Pull all Utilities
  //
  //
  //
  //
  const getAllUtilities = async () => {
    {
      const allUtilities = await client.query(
        q.Map(
          q.Paginate(q.Documents(q.Collection("utilities"))),
          q.Lambda((x) => q.Get(x))
        )
      );

      setTimeout(function () {
        setallExistingUtilities(allUtilities.data);
      }, 2000);
    }
  };
  //
  //
  //
  //
  var matchedUtility = null;

  switch (typeof selectedUtility.utility === "string") {
    case true:
      var matchedUtility = JSON.parse(selectedUtility.utility);
      break;
    default:
      var matchedUtility = null;
      break;
  }

  //
  //
  //
  //
  //
  //
  //
  //
  var matchedCommodity = null;

  switch (matchedUtility === null) {
    case false:
      var matchedCommodity = selectedUtility.commodity;
      break;
    default:
      var matchedCommodity = null;
      break;
  }

  //
  //
  //
  //
  //
  //
  //
  // Form Submission
  //
  //
  //
  function orderSubmissionSms() {
    //props.searchProductSelected(ProductID); "This gives CreateOrder the product ID to search and fills in missing data"
    props.createOrder();
    props.sms();
    props.orderFormScreenChange("Order-Submitted");
  }

  //
  //

  function orderSubmissionEmail() {
    setTimeout(() => {
      props.createOrder();
    }, 3000);
    setTimeout(() => {
      props.email();
    }, 3000);
    setTimeout(() => {
      setSaveState("saved");
    }, 2000);
    setTimeout(() => {
      props.orderFormScreenChange("Order-Submitted-Email");
    }, 3500);
  }
  //
  //
  //
  if (Object.keys(allExistingUtilities).length === 0) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                aria-hidden="true"
                class="mb-10 w-20 h-20 animate-spin text-white fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <h1 class="text-6xl text-tpvBlue font-bold">Loading</h1>
              <p class="text-2xl text-gray-700">
                If order screen do not load please contact system admin.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <div class="animate-fade create-order-1 p-10 bg-resiBg lg:grid lg:grid-cols-8 lg:gap-x-5">
        <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
          <div>
            <div class="heading">
              <nav class="sm:hidden" aria-label="Back">
                <a
                  href="#"
                  class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <svg
                    class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Back
                </a>
              </nav>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <button
                        type="button"
                        onClick={() => {
                          const newPosition = "Dashboard-Home";
                          props.positionChange(newPosition);
                        }}
                        class="animate-pulse text-lg font-medium text-white hover:underline hover:text-gray-300"
                      >
                        Home
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="h-5 w-5 flex-shrink-0 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <p class="ml-4 text-sm font-medium text-white underline">
                        Create Residential Order
                      </p>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight">
                  Create Residential Order
                </h2>
              </div>

              <div class="">
                <h2 class="text-2xl text-white sm:truncate sm:text-xs sm:tracking-tight">
                  GPS -
                  <span class="text-blue-300">
                    Lat {""}:{""} {props.gpsData.lat}
                  </span>
                  <span class="ml-4 text-blue-300">
                    Long {""}:{""} {props.gpsData.long}
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Customer Information
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Enter in the customer’s service address and contact
                  information.
                </p>
              </div>

              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700 ">
                    First name<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    onChange={props.addData}
                    autocomplete="given-name"
                    class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    Last Name<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    onChange={props.addData}
                    autocomplete="given-name"
                    class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    Account Holder First name<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="actHolderFirst"
                    id="actHolderFirst"
                    onChange={props.addData}
                    autocomplete="given-name"
                    class="mt-1 bg-red-50 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    Account Holder Last Name <span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="actHolderLast"
                    id="actHolderLast"
                    onChange={props.addData}
                    autocomplete="family-name"
                    class="mt-1 bg-red-50 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    Relationship With Account Holder
                    <span class="text-red-400">*</span>
                  </label>
                  <select
                    id="actHolderRelation"
                    name="actHolderRelation"
                    onChange={props.addData}
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="Account-Holder">Account Holder</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Authorized-User">Authorized User</option>
                  </select>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    Language<span class="text-red-400">*</span>
                  </label>
                  <select
                    id="Language"
                    name="Language"
                    onChange={props.addData}
                    autocomplete="country-name"
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="English">English</option>
                    <option value="Spanish">Spanish</option>
                  </select>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    Billing Telephone<span class="text-red-400">*</span>
                  </label>
                  <div onChange={(e) => handleInput(e)}>
                    <input
                      onChange={props.addData}
                      onWheel={(e) => e.target.blur()}
                      class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      value={inputValue}
                      name="billingTel"
                      id="billingTel"
                    />
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    What type of phone is this?
                  </label>
                  <select
                    id="telType"
                    name="telType"
                    onChange={props.addData}
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="Cell">Cell</option>
                    <option value="Work">Work</option>
                    <option value="Home">Home</option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    Email address<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    onChange={props.addData}
                    autocomplete="email"
                    class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    Service Address<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="serviceAddress"
                    id="serviceAddress"
                    onChange={props.addData}
                    autocomplete="street-address"
                    class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div class="col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    Service Address 2 (e.g Suite/Apt)
                  </label>
                  <input
                    type="text"
                    name="serviceSuite"
                    id="serviceSuite"
                    onChange={props.addData}
                    class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    City<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="serviceCity"
                    id="serviceCity"
                    onChange={props.addData}
                    class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    State<span class="text-red-400">*</span>
                  </label>
                  <select
                    id="serviceState"
                    name="serviceState"
                    onChange={props.addData}
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option selected="selected" value="AL">
                      Alabama
                    </option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-3 lg:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    Zip<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="serviceZip"
                    id="serviceZip"
                    onChange={props.addData}
                    autocomplete="postal-code"
                    class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="billing relative billing section col-span-6 sm:col-span-3 lg:col-span-6">
                  <div class="col-span-6 sm:col-span-3 lg:col-span-3">
                    <fieldset>
                      <div
                        class="text-base font-medium text-gray-900"
                        aria-hidden="true"
                      >
                        Billing Address
                      </div>
                      <div class="mt-4 space-y-4">
                        <div class="flex items-start">
                          <div class="flex h-5 items-center">
                            <input
                              type="checkbox"
                              checked={isBillingTheSame === false}
                              onChange={handleChange}
                              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            />
                          </div>
                          <div class="ml-3 text-sm">
                            <label class="capitalize font-medium text-gray-700">
                              My Billing Information is the same as my Service
                              Information {isBillingTheSame}
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    {isBillingTheSame === true ? (
                      <div class="bg-sky-100 p-7 rounded-xl mt-8 grid grid-cols-6 gap-6">
                        <div class="col-span-3">
                          <label class="capitalize block text-sm font-medium text-gray-700">
                            Billing Address
                          </label>
                          <input
                            type="text"
                            name="billingAddress"
                            id="billingAddress"
                            onChange={props.addData}
                            autocomplete="street-address"
                            class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                        <div class="col-span-3">
                          <label class="capitalize block text-sm font-medium text-gray-700">
                            Billing Address 2 (e.g Suite/Apt)
                          </label>
                          <input
                            type="text"
                            name="billingSuite"
                            id="billingSuite"
                            onChange={props.addData}
                            class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                          <label class="capitalize block text-sm font-medium text-gray-700">
                            City
                          </label>
                          <input
                            type="text"
                            name="billingCity"
                            id="billingCity"
                            onChange={props.addData}
                            autocomplete="address-level2"
                            class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label class="capitalize block text-sm font-medium text-gray-700">
                            State
                          </label>
                          <select
                            id="billingState"
                            name="billingState"
                            onChange={props.addData}
                            class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          >
                            <option selected value="">
                              --Select--
                            </option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                        </div>
                        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label class="capitalize block text-sm font-medium text-gray-700">
                            ZIP / Postal code
                          </label>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name="billingZip"
                            id="billingZip"
                            onChange={props.addData}
                            autocomplete="postal-code"
                            class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-sky-50 py-6 px-4 sm:p-6">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Order Information
                </h3>
                <p class="mt-1 text-sm text-gray-500">Select a product.</p>
              </div>

              <div class="grid grid-cols-6 gap-6 items-center">
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Select Utility<span class="text-red-400">*</span>
                  </label>
                  <select
                    name="utility"
                    onChange={handleUtility}
                    class="mb-6 mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="" selected disabled hidden>
                      Select Utility
                    </option>
                    {allExistingUtilities.map((post) => {
                      return (
                        <>
                          <option value={JSON.stringify(post.data)}>
                            {post.data.name} - {post.data.nickname}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  {matchedUtility === null ? (
                    <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Commodity<span class="text-red-400">*</span>
                      </label>
                      <select
                        name="commodity"
                        disabled
                        onChange={handleUtility}
                        class="mb-6 mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      >
                        <option value="" selected disabled hidden>
                          Select Utility First
                        </option>
                      </select>
                    </div>
                  ) : (
                    <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Commodity<span class="text-red-400">*</span>
                      </label>
                      <select
                        name="commodity"
                        onChange={handleUtility}
                        class="mb-6 mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      >
                        <option value="" selected disabled hidden>
                          Select Commodity
                        </option>

                        <option value={matchedUtility.commodity_1}>
                          {matchedUtility.commodity_1}
                        </option>

                        {matchedUtility.commodity_2 === "" ? (
                          <option value="" hidden></option>
                        ) : (
                          <option value={matchedUtility.commodity_2}>
                            {matchedUtility.commodity_2}
                          </option>
                        )}
                        {matchedUtility.commodity_3 === "" ? (
                          <option value="" hidden></option>
                        ) : (
                          <option value={matchedUtility.commodity_3}>
                            {matchedUtility.commodity_3}
                          </option>
                        )}
                        {matchedUtility.commodity_4 === "" ? (
                          <option value="" hidden></option>
                        ) : (
                          <option value={matchedUtility.commodity_4}>
                            {matchedUtility.commodity_4}
                          </option>
                        )}

                        {matchedUtility.commodity_5 === "" ? (
                          <option value="" hidden></option>
                        ) : (
                          <option value={matchedUtility.commodity_5}>
                            {matchedUtility.commodity_5}
                          </option>
                        )}
                      </select>
                    </div>
                  )}

                  {matchedCommodity === null ? (
                    <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Product<span class="text-red-400">*</span>
                      </label>
                      <select
                        name="Product"
                        disabled
                        onChange={selectProduct}
                        class="mb-6 mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      >
                        <option value="" selected disabled hidden>
                          Select Commodity First
                        </option>
                      </select>
                    </div>
                  ) : (
                    <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Product<span class="text-red-400">*</span>
                      </label>
                      <select
                        name="Product"
                        onChange={selectProduct}
                        class="mb-6 mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      >
                        <option value="" selected disabled hidden>
                          Select Product
                        </option>

                        {allExistingProducts.map((post) => {
                          return (
                            <>
                              {post.data.commodity === matchedCommodity &&
                              post.data.utility.name === matchedUtility.name ? (
                                <option value={JSON.stringify(post.data)}>
                                  {post.data.name}
                                </option>
                              ) : (
                                <option value="" disabled hidden />
                              )}
                            </>
                          );
                        })}
                      </select>
                    </div>
                  )}
                </div>

                <div class="col-span-6 sm:col-span-3">
                  {ProductSelected === null ? (
                    <div class="hidden animate-fade border-l-4 border-blue-800 bg-blue-400 rounded-r-xl p-4">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <svg
                            class="h-7 w-7 text-blue-100"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                        <div class="ml-3">
                          <p class="underline text-lg text-white">
                            Select Product
                          </p>

                          <p class="pt-2 text-sm text-white">
                            You must select a product before completing this
                            order.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div class="animate-fade border-l-4 border-purple-400 bg-gradient-to-r from-purple-800 to-purple-900 rounded-r-xl p-4">
                      <div class="flex">
                        <div class="ml-3">
                          <p class="underline text-lg text-yellow-200">
                            {selectedProductParsed.name}{" "}
                            {selectedProductParsed.dwelling} --{" "}
                            {selectedProductParsed.vendor.supplierVendorID}
                          </p>
                          <p class="mt-1 text-lg text-yellow-200">
                            <span class="font-bold">
                              ${selectedProductParsed.rate}/
                              {selectedProductParsed.unitMeasurement}
                            </span>{" "}
                            for{" "}
                            <span class="font-bold">
                              {selectedProductParsed.term}
                            </span>
                          </p>

                          <p class="mt-2 font-medium text-md text-white">
                            Early Termination Fee: $
                            {selectedProductParsed.terminationFee}
                          </p>

                          <p class="mt-1 font-medium text-md text-white">
                            Monthly Fee: ${selectedProductParsed.monthlyFee}
                          </p>

                          <p class="mt-1 font-medium text-md text-white">
                            {" "}
                            Supplier: {selectedProductParsed.supplier}
                          </p>

                          <p class="max-w-2xl border-t mt-3 pt-2 text-sm text-yellow-200">
                            {selectedProductParsed.details}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div class="border-t border-blue-200" />

              {matchedUtility === null ? (
                <div class="hidden pt-4 grid grid-cols-6 gap-6" />
              ) : (
                <div class="pt-4 grid grid-cols-6 gap-6">
                  <div class="col-span-3">
                    <label class="capitalize block text-sm font-medium text-gray-700">
                      {matchedUtility.accountFieldName}
                      <span class="text-red-400">*</span>
                    </label>
                    <input
                      type="text"
                      name="accountNumber_1"
                      onChange={props.addData}
                      class="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                  </div>

                  <div class="col-span-3">
                    {props.NewOrder.accountNumber_2.length == 0 ? (
                      <label class="capitalize block text-sm font-medium text-gray-700">
                        Re-Enter {matchedUtility.accountFieldName}
                        <span class="text-red-400">*</span>
                      </label>
                    ) : (
                      <>
                        {props.NewOrder.accountNumber_1 ===
                        props.NewOrder.accountNumber_2 ? (
                          <label class="capitalize block text-sm font-medium text-green-600">
                            {matchedUtility.accountFieldName}
                          </label>
                        ) : (
                          <label class="capitalize block text-sm font-medium text-red-500">
                            {matchedUtility.accountFieldName} Must Match
                            <span class="text-red-400">*</span>
                          </label>
                        )}
                      </>
                    )}

                    <input
                      type="text"
                      name="accountNumber_2"
                      onChange={props.addData}
                      class={
                        props.NewOrder.accountNumber_2.length == 0
                          ? "mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          : `${
                              props.NewOrder.accountNumber_1 ===
                              props.NewOrder.accountNumber_2
                                ? "mt-1 block w-full rounded-md border border-green-400 py-2 px-3 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                                : "mt-1 block w-full rounded-md border border-red-300 py-2 px-3 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                            }`
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Verification Information
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  How would your customer like to verify this order?
                </p>
              </div>

              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label class="capitalize block text-sm font-medium text-gray-700">
                    Delivery Method<span class="text-red-400">*</span>
                  </label>
                  <select
                    id="deliveryMethod"
                    name="deliveryMethod"
                    onChange={props.addData}
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="Text-Sms">Text/Sms</option>
                    <option value="Email">Email</option>
                  </select>
                </div>

                {props.NewOrder.deliveryMethod === "Text-Sms" ? (
                  <div class="col-span-6 sm:col-span-3">
                    <label class="capitalize block text-sm font-medium text-gray-700">
                      Verification Phone Number
                      <span class="text-red-400">*</span>
                    </label>
                    <div
                      value={props.NewOrder.verifyPhone}
                      onChange={(e) => handleVerifyInput(e)}
                    >
                      <input
                        onChange={props.addData}
                        onWheel={(e) => e.target.blur()}
                        class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                        value={inputVerifyPhoneValue}
                        name="verifyPhone"
                      />
                    </div>
                  </div>
                ) : (
                  <div class="col-span-6 sm:col-span-3">
                    <label class="capitalize block text-sm font-medium text-gray-700">
                      Verification Email<span class="text-red-400">*</span>
                    </label>
                    <input
                      type="email"
                      name="verifyEmail"
                      value={props.NewOrder.verifyEmail}
                      onChange={props.addData}
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {props.NewOrder.deliveryMethod === "Text-Sms" ? (
            <div>
              {props.NewOrder.firstName !== "" &&
              props.NewOrder.lastName !== "" &&
              props.NewOrder.actHolderFirst !== "" &&
              props.NewOrder.actHolderLast !== "" &&
              props.NewOrder.email !== "" &&
              props.NewOrder.serviceAddress !== "" &&
              props.NewOrder.serviceCity !== "" &&
              props.NewOrder.serviceZip !== "" &&
              props.NewOrder.Product !== "" &&
              props.NewOrder.billingTel !== "" &&
              props.NewOrder.accountNumber_1 ===
                props.NewOrder.accountNumber_2 &&
              props.NewOrder.verifyPhone.length === 10 ? (
                <div class="text-center mt-4 flex sm:mt-0">
                  <button
                    type="button"
                    onClick={orderSubmissionSms}
                    class=" w-full order-0 rounded-md   bg-tpvRed px-8 py-5 text-lg font-medium text-white hover:bg-red-500 focus:outline-none sm:order-1"
                  >
                    Submit New Order
                  </button>
                </div>
              ) : (
                <div class="text-center mt-4 flex sm:mt-0">
                  <button
                    type="button"
                    disabled
                    class="opacity-60 w-full order-0 rounded-md bg-tpvRed px-8 py-5 text-lg font-medium text-white focus:outline-none sm:order-1"
                  >
                    Fill Out All Required Fields
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div>
              {props.NewOrder.firstName !== "" &&
              props.NewOrder.lastName !== "" &&
              props.NewOrder.actHolderFirst !== "" &&
              props.NewOrder.actHolderLast !== "" &&
              props.NewOrder.email !== "" &&
              props.NewOrder.serviceAddress !== "" &&
              props.NewOrder.serviceCity !== "" &&
              props.NewOrder.serviceZip !== "" &&
              props.NewOrder.Product !== "" &&
              props.NewOrder.billingTel !== "" &&
              props.NewOrder.accountNumber_1 ===
                props.NewOrder.accountNumber_2 &&
              props.NewOrder.verifyEmail !== "" ? (
                <div class="text-center mt-4 flex sm:mt-0">
                  {SaveState === "saved" ? (
                    <button
                      type="button"
                      disabled
                      onClick={orderSubmissionEmail}
                      class=" w-full order-0 rounded-md   bg-tpvRed px-8 py-5 text-lg font-medium text-white hover:bg-red-500 focus:outline-none sm:order-1"
                    >
                      Sending...
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={orderSubmissionEmail}
                      class=" w-full order-0 rounded-md   bg-tpvRed px-8 py-5 text-lg font-medium text-white hover:bg-red-500 focus:outline-none sm:order-1"
                    >
                      Submit New Order Email
                    </button>
                  )}
                </div>
              ) : (
                <div class="text-center mt-4 flex sm:mt-0">
                  <button
                    type="button"
                    disabled
                    class="opacity-60 w-full order-0 rounded-md bg-tpvRed px-8 py-5 text-lg font-medium text-white focus:outline-none sm:order-1"
                  >
                    Fill Out All Required Fields
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
};

export default CreateOrderForm;
