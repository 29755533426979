import React, { useState } from "react";
import ReportDashboardScreen from "./ReportDashboardScreen";
import ReportDashboardScreenCommercial from "./ReportDashboardScreenCommercial";
import ReportDownloads from "./ReportDownloads";

const Dashboard = (props) => {
  //Order Navigation//
  function orderScreenChange(newValue) {
    setTimeout(function () {
      setorderScreen(newValue);
    }, 100);
  }
  const [orderScreen, setorderScreen] = useState("Dashboard-Home");
  const [orderID, setorderID] = useState();

  function orderDetailID(newValue) {
    setTimeout(function () {
      setorderID(newValue);
    }, 100);
  }

  var orderPage = "";
  switch (orderScreen) {
    case "Dashboard-Home":
      orderPage = (
        <ReportDownloads
          activeUser={props.activeUser}
          activeSelectedDB={props.activeSelectedDB}
          activeUserSupplier={props.activeUserSupplier}
          activeUserVendor={props.activeUserVendor}
          orderDetailID={orderDetailID}
          onChange={orderScreenChange}
        />
      );
      break;
    case "View-Resi-TPVs":
      orderPage = (
        <ReportDashboardScreen
          activeUserSupplier={props.activeUserSupplier}
          activeUserVendor={props.activeUserVendor}
          orderDetailID={orderDetailID}
          onChange={orderScreenChange}
        />
      );
      break;
    case "View-Commercial-TPVs":
      orderPage = (
        <ReportDashboardScreenCommercial
          activeUserSupplier={props.activeUserSupplier}
          activeUserVendor={props.activeUserVendor}
          orderDetailID={orderDetailID}
          onChange={orderScreenChange}
        />
      );
      break;

    default:
      orderPage = <ReportDashboardScreen onChange={orderScreenChange} />;
  }

  return <div class="">{orderPage}</div>;
};

export default Dashboard;
