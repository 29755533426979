import React, { useState } from "react";
import CreateVendor from "./CreateVendor";
import VendorDashScreen from "./VendorDashScreen";
import VendorDetails from "./VendorDetails";
import VSimpleScreen from "../FilteredScreens/VSimpleScreen";
import VCompanyScreen from "../FilteredScreens/VCompanyScreen";
import VContactScreen from "../FilteredScreens/VContactScreen";

const VendorDashboard = (props) => {
  // Navigation/ /
  function appScreenChange(newValue) {
    setTimeout(function () {
      setapplicationScreen(newValue);
    }, 100);
  }
  const [applicationScreen, setapplicationScreen] = useState("Dashboard-Home");

  const [companyID, setcompanyID] = useState();

  function vendorDetailID(newValue) {
    setTimeout(function () {
      setcompanyID(newValue);
    }, 100);
  }

  var appPage = "";
  switch (applicationScreen) {
    case "Create-Company":
      appPage = (
        <CreateVendor
          activeSelectedDB={props.activeSelectedDB}
          activeUser={props.activeUser}
          activeUserSupplier={props.activeUserSupplier}
          activeUserVendor={props.activeUserVendor}
          activeUserEmail={props.activeUserEmail}
          activeUserRole={props.activeUserRole}
          onChange={appScreenChange}
        />
      );
      break;

    case "Dashboard-Home":
      appPage = (
        <VendorDashScreen
          companyDetailID={vendorDetailID}
          onChange={appScreenChange}
          activeUserSupplier={props.activeUserSupplier}
        />
      );
      break;
    case "Company-Details":
      appPage = (
        <VendorDetails companyID={companyID} onChange={appScreenChange} />
      );
      break;
    case "VSimple-Screen":
      appPage = (
        <VSimpleScreen
          companyDetailID={vendorDetailID}
          onChange={appScreenChange}
        />
      );
      break;
    case "VCompany-Screen":
      appPage = (
        <VCompanyScreen
          companyDetailID={vendorDetailID}
          onChange={appScreenChange}
        />
      );
      break;
    case "VContact-Screen":
      appPage = (
        <VContactScreen
          companyDetailID={vendorDetailID}
          onChange={appScreenChange}
        />
      );
      break;
    case "Company-Details":
      appPage = (
        <VendorDetails companyID={companyID} onChange={appScreenChange} />
      );
      break;
    default:
      appPage = (
        <VendorDashScreen
          companyDetailID={vendorDetailID}
          onChange={appScreenChange}
        />
      );
  }

  return <div class="">{appPage}</div>;
};

export default VendorDashboard;
