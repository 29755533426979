import React, { useState, useEffect, Fragment } from "react";
import OrderDashItem from "./OrderDashItem";
import IvrStatus from "./IvrStatus";
import { Menu, Transition } from "@headlessui/react";
import { DataGrid } from "@mui/x-data-grid";
import ReactPaginate from "react-paginate";
import { Tab } from "@headlessui/react";

const DatagridComponent = (props) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  //
  //
  //
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  //
  //
  //
  //
  useEffect(() => {
    setInterval(() => {
      setallExistingOrders(props.allExistingOrders);
    }, 1000);
  }, [props.allExistingOrders]);

  //
  const [allExistingOrders, setallExistingOrders] = useState("");

  //

  const dataArray = props.allExistingOrders;
  //
  //
  //
  if (Object.keys(props.allExistingOrders).length === 0) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                aria-hidden="true"
                class="mb-10 w-20 h-20 animate-spin text-white fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <h1 class="text-6xl text-tpvBlue font-bold">Loading Orders</h1>
              <p class="text-2xl text-gray-700">
                If orders do not load please contact system admin.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <main class="z-10 animate-fade dashboard flex-1">
        <DataGrid
          //
          // Hide header
          //
          slots={{
            columnHeaders: () => null,
          }}
          /////////////////
          getRowClassName={(params) => {
            if (params.row.dwelling === "Commercial") {
              return "commercial";
            }

            if (params.row.isIVRverification === true) {
              return "ivr";
            }
          }}
          //
          //
          // sx={{
          //   ".commercial": {
          //     bgcolor: "#f2faff",
          //     "&:hover": {
          //       bgcolor: "#e8f6fe",
          //     },
          //   },
          //   ".ivr": {
          //     bgcolor: "#fefceb",
          //     "&:hover": {
          //       bgcolor: "#fdfae0",
          //     },
          //   },
          //   ".MuiDataGridComponent-columnHeaders": {
          //     background: `linear-gradient(
          //               90deg,
          //               rgba(170, 170, 255, 0.2) 35%,
          //               rgba(171, 241, 255, 0.3) 100%
          //             )`,
          //   },
          // }}
          columns={[
            {
              field: "id",
              headerName: "Order No.",
              width: 175,
              renderCell: (params) => {
                if (params.row.dwelling === "Commercial") {
                  return (
                    <button
                      class="text-black font-medium hover:opacity-70"
                      type="button"
                      onClick={() => {
                        props.orderDetailID(params.value);
                        props.onChange("Order-Details");
                      }}
                    >
                      {" "}
                      <span class="truncate">{params.value}</span>
                    </button>
                  );
                }

                if (params.row.dwelling === "Residential") {
                  return (
                    <button
                      class="text-black font-medium hover:opacity-70"
                      type="button"
                      onClick={() => {
                        props.orderDetailID(params.value);
                        props.onChange("Order-Details");
                      }}
                    >
                      {" "}
                      <span class="truncate">{params.value}</span>
                    </button>
                  );
                }
              },
            },
            {
              field: "status",
              headerName: "Status",
              width: 200,
              renderCell: (params) => {
                if (params.row.isIVRverification === true) {
                  return <IvrStatus status={params.row.IVRcallStatus} />;
                }
                if (params.value === "Expired") {
                  return (
                    <span class="inline-flex items-center rounded-md bg-violet-100 px-4 py-3 text-xs font-medium text-violet-900 ring-1 ring-inset ring-violet-600/50">
                      Timed Out
                    </span>
                  );
                }

                if (params.value === "Undeliverable") {
                  return (
                    <span class="inline-flex items-center rounded-md bg-yellow-100 px-4 py-3 text-xs font-medium text-yellow-900 ring-1 ring-inset ring-yellow-600/50">
                      Undelivered
                    </span>
                  );
                }

                if (params.value === "Pending") {
                  return (
                    <span class="inline-flex items-center rounded-md bg-orange-100 px-4 py-3 text-xs font-medium text-orange-900 ring-1 ring-inset ring-orange-600/50">
                      Delivered
                    </span>
                  );
                }

                if (params.value === "Complete") {
                  return (
                    <span class="inline-flex items-center rounded-md bg-green-100 px-4 py-3 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/50">
                      Completed
                    </span>
                  );
                }
              },
            },

            {
              field: "dateAdded",
              headerName: "Date Created",
              width: 330,
              renderCell: (params) => {
                if (params.row.status === "Pending") {
                  return "Created" + ":" + " " + params.value;
                }

                if (params.row.status === "Complete") {
                  return (
                    <>
                      <span>
                        Created - {params.value}
                        <span class="text-green-500 font-medium block">
                          Verified - {params.row.verified}
                        </span>
                      </span>
                    </>
                  );
                }
              },
            },

            {
              field: "name",
              headerName: "Customer Name",
              width: 260,
              renderCell: (params) => {
                if (params.row.dwelling === "Commercial") {
                  return params.row.business.replace(/-/g, " ");
                }

                if (params.row.dwelling === "Residential") {
                  return params.value.replace(/-/g, " ");
                }
              },
            },

            {
              field: "dwelling",
              headerName: "Dwelling",
              width: 80,
              renderCell: (params) => {
                if (params.value === "Commercial") {
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-8 h-8 text-cyan-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                      />
                    </svg>
                  );
                }

                if (params.value === "Residential") {
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-8 h-8 text-orange-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                      />
                    </svg>
                  );
                }
              },
            },

            {
              field: "delivery",
              headerName: "Delivery Method",
              width: 100,
              renderCell: (params) => {
                if (params.value === "Text-Sms") {
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-8 h-8 text-gray-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                      />
                    </svg>
                  );
                }

                if (params.value === "Email") {
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-8 h-8 text-gray-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                      />
                    </svg>
                  );
                }

                if (params.value === "IVR") {
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-8 h-8 text-gray-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                      />
                    </svg>
                  );
                }
              },
            },
          ]}
          columnHeaderHeight={50}
          rowHeight={70}
          rows={dataArray.map((post) =>
            //
            ({
              id: post.data.orderNumber,
              key: post.ref.value.id,
              name: post.data.firstName + " " + post.data.lastName,
              status: post.data.orderStatus,
              vendorName: post.data.Product.vendor.name,
              positionChange: props.onChange,
              orderDetailID: props.orderDetailID,
              dateAdded: post.data.dateAdded,
              dateVerified: post.data.timeVerified,
              delivery: post.data.deliveryMethod,
              dwelling: post.data.Product.dwelling,
              business: post.data.businessName,
              isIVRverification: post.data.isIVRverification,
              IVRcallStatus: post.data.IVRcallStatus,
              verified: post.data.timeVerified,
            })
          )}
          // onRowClick={(param) => handleOnClick(param.row)}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
        />
      </main>
    );
};

export default DatagridComponent;
