import React, { useState } from "react";

const ProductDashItem = (props) => {
  return (
    <tbody class="border-b border-gray-200 divide-y divide-gray-100 bg-white">
      <tr>
        <td class="w-1/4 max-w-0 lg:whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
          <div class="flex items-center space-x-3 lg:pl-2">
            <button
              class="truncate hover:text-gray-400 hover:opacity-70"
              type="button"
              onClick={() => {
                props.productDetailID(props.productID);
                props.positionChange("Product-Details");
              }}
            >
              <span>{props.name}</span>
            </button>
          </div>
        </td>
        <td class="w-1/6 px-6 py-3 text-sm font-medium text-gray-900">
          <div class="flex items-center space-x-3 ">
            <span class="font-normal text-gray-500">{props.productID}</span>
          </div>
        </td>

        <td class="hidden lg:whitespace-nowrap px-6 py-3 text-left text-sm text-gray-500 md:table-cell">
          <span> {props.utilityName}</span>
        </td>
        <td class="hidden lg:whitespace-nowrap px-6 py-3 text-left text-sm text-gray-500 lg:table-cell">
          <span> {props.script}</span>
        </td>
        <td class="hidden lg:whitespace-nowrap px-6 py-3 text-left text-sm text-gray-500 lg:table-cell">
          <span> {props.details}</span>
        </td>
      </tr>
    </tbody>
  );
};
export default ProductDashItem;
