import React, { useState } from "react";
import CreateProduct from "./CreateProduct";
import ProductDashScreen from "./ProductDashScreen";
import ProductDetails from "./ProductDetails";
import EditProduct from "./EditProduct";
import PSimpleScreen from "../FilteredScreens/PSimpleDashScreen";
import PDetailsScreen from "../FilteredScreens/PDetailsDashScreen";
import PTypeScreen from "../FilteredScreens/PTypeDashScreen";

const ProductDashboard = (props) => {
  // Navigation/ /
  function appScreenChange(newValue) {
    setTimeout(function () {
      setapplicationScreen(newValue);
    }, 100);
  }
  const [applicationScreen, setapplicationScreen] = useState("Dashboard-Home");
  const [productID, setproductID] = useState();

  function productDetailID(newValue) {
    setTimeout(function () {
      setproductID(newValue);
    }, 100);
  }

  var appPage = "";

  switch (applicationScreen) {
    case "Create-Product":
      appPage = (
        <CreateProduct
          activeSelectedDB={props.activeSelectedDB}
          activeUserVendor={props.activeUserVendor}
          activeUserSupplier={props.activeUserSupplier}
          onChange={appScreenChange}
        />
      );
      break;
    case "Edit-Product":
      appPage = (
        <EditProduct productID={productID} onChange={appScreenChange} />
      );
      break;
    case "Dashboard-Home":
      appPage = (
        <ProductDashScreen
          activeSelectedDB={props.activeSelectedDB}
          activeUserVendor={props.activeUserVendor}
          activeUserSupplier={props.activeUserSupplier}
          productDetailID={productDetailID}
          onChange={appScreenChange}
        />
      );
      break;
    case "Product-Details":
      appPage = (
        <ProductDetails
          activeSelectedDB={props.activeSelectedDB}
          activeUserSupplier={props.activeUserSupplier}
          productID={productID}
          onChange={appScreenChange}
        />
      );
      break;
    case "PSimple-Screen":
      appPage = (
        <PSimpleScreen
          productDetailID={productDetailID}
          onChange={appScreenChange}
        />
      );
      break;
    case "PDetails-Screen":
      appPage = (
        <PDetailsScreen
          productDetailID={productDetailID}
          onChange={appScreenChange}
        />
      );
      break;
    case "PType-Screen":
      appPage = (
        <PTypeScreen
          productDetailID={productDetailID}
          onChange={appScreenChange}
        />
      );
      break;
    case "Product-Details":
      appPage = (
        <ProductDetails
          activeUserSupplier={props.activeUserSupplier}
          productID={productID}
          onChange={appScreenChange}
        />
      );
      break;

    default:
      appPage = (
        <ProductDashScreen
          activeSelectedDB={props.activeSelectedDB}
          activeUserSupplier={props.activeUserSupplier}
          productDetailID={productDetailID}
          productID={productID}
          onChange={appScreenChange}
        />
      );
  }

  return <div class="">{appPage}</div>;
};

export default ProductDashboard;
