import React, { useState, useEffect } from "react";
import { DocumentArrowDownIcon } from "@heroicons/react/20/solid";
import OrderSubmittedIVR from "./OrderSubmittedIVR";

const CommercialOrderDetails = (props) => {
  useEffect(() => {
    getAllOrders();
    setredialIVR(false);
  }, []);
  //
  //

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }
  //
  //
  //
  //
  //
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "orders";
  const [allExistingOrders, setallExistingOrders] = useState("");

  const [redialIVR, setredialIVR] = useState(false);

  //
  //
  //
  //
  //
  // Pull all Orders
  //
  //
  //
  //
  const getAllOrders = async () => {
    {
      const allOrders = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("order-details-by-ref"), Number(props.orderID))
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallExistingOrders(allOrders.data);
    }
  };

  var matchedOrder = null;

  switch (typeof allExistingOrders === "object") {
    case allExistingOrders === undefined:
      var matchedOrder = "existing orders is empty";
      break;
    case true:
      var matchedOrder = allExistingOrders[0];
      var dateAdded = matchedOrder.data.dateAdded;
      var customer = matchedOrder.data;
      var dateCreated = new Date(dateAdded);
      var dateVerified = matchedOrder.data.timeVerified;
      var timeVerified = new Date(dateVerified);
      break;
  }
  //
  //

  //
  //
  const downloadPDF = async (e) => {
    var pdfWindow = window.open("", "_blank");
    pdfWindow.document.write("Loading PDF...");
    var base64str = matchedOrder.data.enrollmentPdf;

    // decode base64 string, remove space for IE compatibility
    var binary = atob(base64str.replace(/\s/g, ""));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    var blob = new Blob([view], { type: "application/pdf" });

    // opens pdf in new tab
    // var newWindow = window.open();
    var url = (window.URL || window.webkitURL).createObjectURL(blob);

    pdfWindow.location.href = url;
  };

  //
  //
  if (Object.keys(allExistingOrders).length === 0) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white lg:p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                aria-hidden="true"
                class="mb-10 w-20 h-20 animate-spin text-white fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <h1 class="text-6xl text-tpvBlue font-bold">Loading Order</h1>
              <p class="text-2xl text-gray-700">
                If order do not load please contact system admin.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <div>
        {redialIVR === false ? (
          <div class="animate-fade create-order-1 p-4 lg:p-10 bg-tpvBlue lg:grid lg:grid-cols-8 lg:gap-x-5">
            <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
              <div>
                <div class="heading">
                  <nav class="flex" aria-label="Breadcrumb">
                    <ol role="list" class="flex items-center space-x-4">
                      <li>
                        <div class="flex">
                          <button
                            type="button"
                            onClick={() => {
                              const newPosition = "Dashboard-Home";
                              props.onChange(newPosition);
                            }}
                            class="animate-pulse text-lg font-medium text-white hover:underline hover:text-gray-300"
                          >
                            Home
                          </button>
                        </div>
                      </li>
                      <li>
                        <div class="flex items-center">
                          <svg
                            class="h-5 w-5 flex-shrink-0 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <p class="ml-4 text-sm font-medium text-white underline">
                            Order Details
                          </p>
                        </div>
                      </li>
                    </ol>
                  </nav>
                </div>
                <div class="mt-2 lg:flex lg:items-center lg:justify-between">
                  <div class="lg:flex items-center ">
                    <h2 class="py-4 text-2xl font-bold leading-7 text-white sm:text-3xl sm:tracking-tight">
                      Commercial Order Details{" "}
                      {matchedOrder.data.orderStatus === "Pending" ? (
                        <>
                          <span class="text-orange-300">{props.orderID}</span>{" "}
                        </>
                      ) : (
                        <div class="hidden" />
                      )}
                      {matchedOrder.data.orderStatus === "Complete" ? (
                        <>
                          <span class="text-green-400">{props.orderID}</span>{" "}
                        </>
                      ) : (
                        <div class="hidden" />
                      )}
                      {matchedOrder.data.orderStatus === "Issue" ? (
                        <>
                          {" "}
                          <span class="text-red-500">{props.orderID}</span>{" "}
                        </>
                      ) : (
                        <div class="hidden" />
                      )}
                      {matchedOrder.data.orderStatus === "Expired" ? (
                        <>
                          {" "}
                          <span class="text-violet-300">
                            {props.orderID}
                          </span>{" "}
                        </>
                      ) : (
                        <div class="hidden" />
                      )}
                    </h2>

                    {matchedOrder.data.orderStatus === "Expired" ? (
                      <div class="lg:px-6 lg:py-3 text-sm font-medium text-gray-500">
                        <span class="mt-4 lg:mt-0 flex justify-center w-full text-center items-center rounded-md bg-violet-100 px-4 py-3 text-xs font-medium text-violet-900 ring-1 ring-inset ring-violet-600/50">
                          Timed Out
                        </span>
                      </div>
                    ) : (
                      <div class="hidden" />
                    )}

                    {matchedOrder.data.orderStatus === "Pending" ? (
                      <div class="lg:px-6 lg:py-3 text-sm font-medium text-gray-500">
                        <span class="mt-4 lg:mt-0 flex justify-center w-full text-center items-center rounded-md bg-orange-100 px-4 py-3 text-xs font-medium text-orange-900 ring-1 ring-inset ring-orange-600/50">
                          Delivered
                        </span>
                      </div>
                    ) : (
                      <div class="hidden" />
                    )}
                    {matchedOrder.data.deliveryMethod === "IVR" ? (
                      <div class=" lg:py-3 text-sm font-medium text-gray-500">
                        <button
                          onClick={() => setredialIVR(true)}
                          class="mt-4 lg:mt-0 flex justify-center w-full text-center items-center rounded-md bg-emerald-100 px-4 py-3 text-xs font-medium text-emerald-900 ring-1 ring-inset ring-emerald-600/50 hover:opacity-80"
                        >
                          Redial IVR
                        </button>
                      </div>
                    ) : (
                      <div class="hidden" />
                    )}
                    {matchedOrder.data.orderStatus === "Complete" ? (
                      <>
                        <div class="lg:px-6 lg:py-3 text-sm font-medium text-gray-500">
                          <span class="mt-4 lg:mt-0 flex justify-center w-full text-center items-center rounded-md bg-green-100 px-4 py-3 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/50">
                            Complete
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={() => downloadPDF()}
                          className="mt-4 lg:mt-0 flex justify-center w-full text-center items-center gap-x-2 rounded-md bg-sky-500 px-3.5 py-2.5 text-xs font-medium text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                        >
                          <DocumentArrowDownIcon
                            className="-ml-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                          View/Download PDF
                        </button>
                      </>
                    ) : (
                      <div class="hidden" />
                    )}
                    {matchedOrder.data.orderStatus === "Issue" ? (
                      <div class="lg:px-6 lg:py-3 text-sm font-medium text-gray-500">
                        <span class="mt-4 lg:mt-0 flex justify-center w-full text-center items-center rounded-md bg-red-100 px-4 py-3 text-xs font-medium text-red-900 ring-1 ring-inset ring-red-600/50">
                          Failed/Rejected
                        </span>
                      </div>
                    ) : (
                      <div class="hidden" />
                    )}
                  </div>
                </div>

                <div class="mt-4 lg:mt-0 lg:flex lg:items-center lg:justify-between">
                  <div class="lg:flex items-center">
                    <h2 class="pt-2 text-white text-xs sm:tracking-tight lg:pt-0">
                      Date Added -
                      <span class="text-sky-200">
                        {""} {dateCreated.toLocaleString()}
                      </span>
                    </h2>

                    <div class="lg:ml-10">
                      <h2 class="pt-2 text-white text-xs sm:tracking-tight lg:pt-0">
                        Date Verified -
                        {matchedOrder.data.orderStatus === "Complete" ? (
                          <span class="text-green-400">
                            {""} {timeVerified.toLocaleString()}
                          </span>
                        ) : (
                          <div class="hidden"></div>
                        )}
                      </h2>
                      <h2 class="pt-2 text-white text-xs sm:tracking-tight lg:pt-0">
                        GPS -
                        <span class="text-blue-300">
                          Lat {""}:{""} {matchedOrder.data.latitude}
                        </span>
                        <span class="ml-4 text-blue-300">
                          Long {""}:{""} {matchedOrder.data.longitude}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="mb-8 overflow-hidden bg-white shadow sm:rounded-lg">
                  <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Business Information
                    </h3>
                  </div>
                  <div class="border-t border-gray-200">
                    <dl>
                      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Business Name
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {matchedOrder.data.businessName.replace(/-/g, " ")}
                        </dd>
                      </div>

                      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          {" "}
                          Primary Contact First Name
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {matchedOrder.data.primaryFirstName.replace(
                            /-/g,
                            " "
                          )}
                        </dd>
                      </div>
                      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Primary Contact Last Name
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {matchedOrder.data.primaryLastName.replace(/-/g, " ")}
                        </dd>
                      </div>
                      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          DM Position
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {matchedOrder.data.dmPosition}
                        </dd>
                      </div>
                      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Language
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {matchedOrder.data.Language}
                        </dd>
                      </div>
                      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Billing Telephone
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {formatPhoneNumber(matchedOrder.data.billingTel)}
                        </dd>
                      </div>

                      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Email Address
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {matchedOrder.data.businessEmail}
                        </dd>
                      </div>
                      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Service Address
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {matchedOrder.data.serviceAddress +
                            ` ` +
                            matchedOrder.data.serviceSuite +
                            "," +
                            ` ` +
                            matchedOrder.data.serviceCity +
                            ` ` +
                            matchedOrder.data.serviceState +
                            ` ` +
                            matchedOrder.data.serviceZip}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>

                <div class="mb-8 overflow-hidden bg-white shadow sm:rounded-lg">
                  <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Order Information Details
                    </h3>
                  </div>
                  <div class="border-t border-gray-200">
                    <dl>
                      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Date Created
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {dateCreated.toLocaleString()}
                        </dd>
                      </div>
                      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Commodity
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {matchedOrder.data.Product.commodity}
                        </dd>
                      </div>
                      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Product
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {matchedOrder.data.Product.name}
                        </dd>
                      </div>

                      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">Agent</dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {matchedOrder.data.agent}
                        </dd>
                      </div>
                      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          {matchedOrder.data.Product.utility.accountFieldName}
                          <span class="text-xs font-medium text-gray-400">
                            {" "}
                            - up to 10
                          </span>
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          <div class="flex justify-start">
                            <div class="">
                              <span class="block">
                                {" "}
                                {matchedOrder.data.accountNumber_1}
                              </span>
                              <span class="block">
                                {" "}
                                {matchedOrder.data.accountNumber_2}
                              </span>
                              <span class="block">
                                {" "}
                                {matchedOrder.data.accountNumber_3}
                              </span>
                              <span class="block">
                                {" "}
                                {matchedOrder.data.accountNumber_4}
                              </span>
                              <span class="block">
                                {" "}
                                {matchedOrder.data.accountNumber_5}
                              </span>
                            </div>
                            <div class="ml-5">
                              <span class="block">
                                {" "}
                                {matchedOrder.data.accountNumber_6}
                              </span>
                              <span class="block">
                                {" "}
                                {matchedOrder.data.accountNumber_7}
                              </span>
                              <span class="block">
                                {" "}
                                {matchedOrder.data.accountNumber_8}
                              </span>
                              <span class="block">
                                {" "}
                                {matchedOrder.data.accountNumber_9}
                              </span>
                              <span class="block">
                                {" "}
                                {matchedOrder.data.accountNumber_10}
                              </span>
                            </div>
                          </div>
                        </dd>
                      </div>

                      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Verification Phone Number
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {formatPhoneNumber(matchedOrder.data.verifyPhone)}
                        </dd>
                      </div>
                      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Verification Email
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {matchedOrder.data.verifyEmail}
                        </dd>
                      </div>
                      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Delivery Method
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {matchedOrder.data.deliveryMethod}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>

                {matchedOrder.data.orderStatus === "Complete" ? (
                  <div class="overflow-hidden bg-green-200 shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Order Verification Details
                      </h3>
                    </div>
                    <div class="border-t border-gray-200">
                      <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Status
                          </dt>
                          <dd class="mt-1 text-sm text-green-500 sm:col-span-2 sm:mt-0">
                            Complete
                          </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Date Signed
                          </dt>
                          <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                            {matchedOrder.data.timeVerified}
                          </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Latitude:
                          </dt>
                          <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                            {matchedOrder.data.latitude}
                          </dd>
                        </div>
                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Longitude
                          </dt>
                          <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                            {matchedOrder.data.longitude}
                          </dd>
                        </div>

                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Signature
                          </dt>
                          <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                            <img
                              class="w-1/2"
                              src={matchedOrder.data.signaturePng}
                            />
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                ) : (
                  <div class="hidden" />
                )}
                <div class="text-center flex mt-4 sm:mt-0 pt-8">
                  <button
                    type="button"
                    onClick={() => {
                      const newPosition = "Dashboard-Home";
                      props.onChange(newPosition);
                    }}
                    class="text-sm font-medium text-white hover:text-gray-300 w-full order-0 rounded-md  bg-blue-500 px-8 py-5 text-lg font-medium text-white hover:bg-opacity-80 focus:outline-none"
                  >
                    Back to Order Dashboard
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {redialIVR === true ? (
          <OrderSubmittedIVR
            orderNumber={Number(props.orderID)}
            customer={customer}
            activeUserSupplier={props.activeUserSupplier}
            activeSelectedDB={props.activeSelectedDB}
            dwelling={"comm"}
          />
        ) : (
          <></>
        )}
      </div>
    );
};

export default CommercialOrderDetails;
