import React, { useState } from "react";

const SecuritySettings = () => {
  const [Setting, setSetting] = useState({
    DuplicatePhone: "No",
    SlammingNotification: "No",
    TimeLock: "No",
    DuplicateIPAddress: "No",
    SalesVolume: "No",
    AgentGPSRequired: "No",
    DuplicateAccounts: "No",
  });

  //

  const handleChange = (e) => {
    setSetting({ ...Setting, [e.target.name]: e.target.value });
  };

  return (
    <div class="animate-fade create-order-1 md:p-10 p-4 pt-2 bg-blue-300 bg-opacity-20 lg:grid lg:grid-cols-8 lg:gap-x-5">
      <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <div>
          <div class="mt-2 md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
              <h2 class="pt-5 lg:pt-0 text-2xl font-bold leading-7 text-blue-900 sm:truncate sm:text-3xl sm:tracking-tight">
                Security Settings
              </h2>
            </div>
          </div>
        </div>
        <form action="#" method="POST">
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div class="pt-4 pb-4  rounded-lg ">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Security Settings
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Set security parameters here. Note: these security settings
                  will apply to all campaigns and users etc. You can come back
                  and update these at any time.
                </p>
              </div>

              <div class="grid lg:grid-cols-4 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <p class="mt-1 text-sm text-gray-500 font-semibold">
                    Duplicate Phone Number
                  </p>

                  <div class="col-span-2 sm:col-span-3">
                    <p class="mt-1 text-sm text-gray-500">
                      Do you want to allow an order that has both primary phone
                      number and verification contact number to go through? (If
                      you choose "YES" OpenGate TPV will allow the order to go
                      through and generate an email notification that there has
                      been a duplicate phone number. If you choose "NO" the
                      order will be stopped immediately).
                    </p>
                  </div>
                </div>
                <div class="columns-2 lg:ml-8 ml-2">
                  <div class="flex items-center md:mb-4 md:py-5">
                    <input
                      name="DuplicatePhone"
                      type="radio"
                      value="Yes"
                      onChange={handleChange}
                      checked={Setting.DuplicatePhone === "Yes"}
                      class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 lg:h-7 lg:w-7 h-5 w-5 rounded"
                    ></input>

                    <label
                      for="DuplicatePhone"
                      class="text-sm lg:ml-3 ml-1 font-medium text-blue-900"
                    >
                      Yes
                    </label>
                  </div>

                  <div class="flex items-center md:mb-4 md:py-5 lg:pl-0 pl-2">
                    <input
                      name="DuplicatePhone"
                      type="radio"
                      value="No"
                      checked={Setting.DuplicatePhone === "No"}
                      onChange={handleChange}
                      class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 lg:h-7 lg:w-7 h-5 w-5 rounded"
                    ></input>
                    <label
                      for="DuplicatePhone"
                      class="text-sm lg:ml-3 ml-1 font-medium text-blue-900"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="text-center mt-4 flex sm:mt-0">
          <button
            type="button"
            class="w-full order-0 rounded-md   bg-blue-900 px-8 py-5 text-lg font-medium text-white hover:bg-blue-700 focus:outline-none sm:order-1"
          >
            Apply Settings
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecuritySettings;
