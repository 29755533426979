import React, { useState } from "react";
import ScriptQuestion from "./ScriptQuestion";

const CreateScript = (props) => {
  const [SaveState, setSaveState] = useState("unsaved");
  const [allExistingScriptNumbers, setallExistingScriptNumbers] = useState("");
  const [NewScript, setNewScript] = useState({
    scriptName: "",
    scriptInformation: "",
    legalDescription: "",
  });

  const [ScriptQuestions, setScriptQuestions] = useState({
    tpvQuestion_1: "",
    tpvQuestion_2: "",
    tpvQuestion_3: "",
    tpvQuestion_4: "",
    tpvQuestion_5: "",
    tpvQuestion_6: "",
    tpvQuestion_7: "",
    tpvQuestion_8: "",
    tpvQuestion_9: "",
    tpvQuestion_10: "",
    tpvQuestion_11: "",
    tpvQuestion_12: "",
    tpvQuestion_13: "",
    tpvQuestion_14: "",
    tpvQuestion_15: "",
    tpvQuestion_16: "",
    tpvQuestion_17: "",
    tpvQuestion_18: "",
    tpvQuestion_19: "",
    tpvQuestion_20: "",
  });

  function handleChange(e) {
    setNewScript({
      ...NewScript,
      [e.target.name]: e.target.value,
    });
  }

  function questionChange(e) {
    setScriptQuestions({
      ...ScriptQuestions,
      [e.target.name]: e.target.value,
    });
  }
  //
  //
  //
  //
  // Question Counter //
  const [QuestionCount, setQuestionCount] = useState(4);
  //
  // Increase
  const counterIncrease = () => {
    if (QuestionCount < 20) {
      setQuestionCount((count) => count + 1);
    }
  };
  //
  //
  // Decrease
  //
  //
  //
  const counterDecrease = (e) => {
    if (QuestionCount > 1) {
      setQuestionCount((count) => count - 1);
    }
    //
    //Clear question if deleted //
    //
    setScriptQuestions({
      ...ScriptQuestions,
      [e.currentTarget.name]: "",
    });
  };

  //
  //
  //
  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "scripts";

  //
  //
  //
  //
  //
  // //
  //
  // Pull All Existing Order Numbers //
  //
  //
  //
  /*const getProductNumbers = async () => {
    {
      const existingOrderNumbers = await client.query(
        q.Paginate(q.Match(q.Index("product-numbers")), { size: 6000 })
      );

      setallExistingScriptNumbers(existingOrderNumbers.data);
    }
  };*/
  //
  //
  //
  // New Order Number //
  //
  //
  //
  //var scriptNumberCheck = Math.floor(10000 + Math.random() * 90000);//
  var scriptNumberCheck = Math.floor(1000000000 + Math.random() * 9000000000);
  var newScriptNumber = "";

  //
  //
  switch (allExistingScriptNumbers.includes(scriptNumberCheck)) {
    case true:
      while (allExistingScriptNumbers.includes(scriptNumberCheck) === true) {
        scriptNumberCheck = Math.floor(1000000000 + Math.random() * 9000000000);
        var newScriptNumber = scriptNumberCheck;
      }
      break;
    case false:
      var newScriptNumber = scriptNumberCheck;
      break;
  }

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //Create Database Entry//
  //
  //
  //
  //

  function scriptSubmission() {
    setTimeout(() => {
      setSaveState("saving");
    }, 100);
    setTimeout(() => {
      createScriptEntry();
    }, 3000);
    setTimeout(() => {
      setSaveState("saved");
    }, 2000);
    setTimeout(() => {
      props.onChange("Dashboard-Home");
    }, 4500);
  }

  const createScriptEntry = async (_callback) => {
    const inputDate = new Date().toGMTString();
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    await client.query(
      q.Create(q.Collection(COLLECTION_NAME), {
        data: {
          scriptName: NewScript.scriptName,
          scriptInformation: NewScript.scriptInformation,
          legalDescription: NewScript.legalDescription,
          scriptVendorID: props.activeUserVendor,
          tpvQuestions: [
            ScriptQuestions.tpvQuestion_1,
            ScriptQuestions.tpvQuestion_2,
            ScriptQuestions.tpvQuestion_3,
            ScriptQuestions.tpvQuestion_4,
            ScriptQuestions.tpvQuestion_5,
            ScriptQuestions.tpvQuestion_6,
            ScriptQuestions.tpvQuestion_7,
            ScriptQuestions.tpvQuestion_8,
            ScriptQuestions.tpvQuestion_9,
            ScriptQuestions.tpvQuestion_10,
            ScriptQuestions.tpvQuestion_11,
            ScriptQuestions.tpvQuestion_12,
            ScriptQuestions.tpvQuestion_13,
            ScriptQuestions.tpvQuestion_14,
            ScriptQuestions.tpvQuestion_15,
            ScriptQuestions.tpvQuestion_16,
            ScriptQuestions.tpvQuestion_17,
            ScriptQuestions.tpvQuestion_18,
            ScriptQuestions.tpvQuestion_19,
            ScriptQuestions.tpvQuestion_20,
          ],
          dateAdded: inputDate,
          dateAddedShort: formatDate(inputDate),
          scriptID: newScriptNumber,
        },
      })
    );
    setTimeout(() => {
      setSaveState("saved");
    }, 6000);
    props.onChange("Dashboard-Home");
  };
  //
  //
  // End Fauna DB Shenanigans //
  //
  //
  //
  //
  return (
    <div class="create-order-1 p-10 bg-emerald-300 bg-opacity-20 lg:grid lg:grid-cols-8 lg:gap-x-5">
      <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <div>
          <div class="heading">
            <nav class="sm:hidden" aria-label="Back">
              <a
                href="#"
                class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                <svg
                  class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                    clip-rule="evenodd"
                  />
                </svg>
                Back
              </a>
            </nav>
            <nav class="hidden sm:flex" aria-label="Breadcrumb">
              <ol role="list" class="flex items-center space-x-4">
                <li>
                  <div class="flex">
                    <button
                      type="button"
                      onClick={() => {
                        props.onChange("Dashboard-Home");
                      }}
                      class="text-sm font-medium text-blue-900 hover:underline"
                    >
                      Scripts
                    </button>
                  </div>
                </li>

                <li>
                  <div class="flex items-center">
                    <svg
                      class="h-5 w-5 flex-shrink-0 text-blue-900"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <p class="ml-4 text-sm font-medium text-blue-900 underline">
                      Create Script
                    </p>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
          <div class="mt-2 md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
              <h2 class="text-2xl font-bold leading-7 text-blue-900 sm:truncate sm:text-3xl sm:tracking-tight">
                Create Script
              </h2>
            </div>
          </div>
        </div>

        <div class="shadow sm:overflow-hidden sm:rounded-md">
          <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                TPV Script Information
              </h3>
              <p class=" text-sm text-gray-500">
                Enter all script information and questions below.
              </p>
            </div>

            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-6 sm:col-span-6">
                <label class="block text-sm font-medium text-gray-700">
                  Script Name
                </label>
                <input
                  type="text"
                  name="scriptName"
                  onChange={handleChange}
                  autocomplete="given-name"
                  class=" block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="shadow sm:overflow-hidden sm:rounded-md">
          <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <div class="flex items-center">
                {" "}
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Script Information
                </h3>
                <button class="hover:opacity-80 text-tpvBlue">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="ml-1 w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                    />
                  </svg>
                </button>
              </div>
              <p class=" text-sm text-gray-500">
                Enter in whatever information you want displayed 1st to the
                prospect before they select TPV questions.
              </p>
            </div>

            <div class="grid grid-cols-6 gap-6 items-center">
              <div class="inline-flex col-span-6 sm:col-span-6">
                <textarea
                  rows="2"
                  name="scriptInformation"
                  onChange={handleChange}
                  placeholder="“To complete your new gas order click on the yes-no questions below. If you have questions . . .”"
                  class="block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-6 gap-6 ">
          <div class="col-span-3 bg-white shadow sm:overflow-hidden sm:rounded-md">
            <div class=" py-6 px-4 sm:p-6">
              <div class="mb-6 ">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  TPV Script Questions
                </h3>
                <p class=" text-sm text-gray-500">
                  Enter the TPV questions you want your prospect to answer YES
                  or NO to.
                </p>
              </div>

              <div class="mb-6 questions grid grid-cols-6 gap-6 items-center">
                {QuestionCount >= 1 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[0]}
                    questionValue={Object.values(ScriptQuestions)[0]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 2 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[1]}
                    questionValue={Object.values(ScriptQuestions)[1]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 3 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[2]}
                    questionValue={Object.values(ScriptQuestions)[2]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 4 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[3]}
                    questionValue={Object.values(ScriptQuestions)[3]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 5 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[4]}
                    questionValue={Object.values(ScriptQuestions)[4]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 6 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[5]}
                    questionValue={Object.values(ScriptQuestions)[5]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 7 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[6]}
                    questionValue={Object.values(ScriptQuestions)[6]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 8 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[7]}
                    questionValue={Object.values(ScriptQuestions)[7]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 9 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[8]}
                    questionValue={Object.values(ScriptQuestions)[8]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 10 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[9]}
                    questionValue={Object.values(ScriptQuestions)[9]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 11 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[10]}
                    questionValue={Object.values(ScriptQuestions)[10]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 12 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[11]}
                    questionValue={Object.values(ScriptQuestions)[11]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 13 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[12]}
                    questionValue={Object.values(ScriptQuestions)[12]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 14 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[13]}
                    questionValue={Object.values(ScriptQuestions)[13]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 15 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[14]}
                    questionValue={Object.values(ScriptQuestions)[14]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 16 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[15]}
                    questionValue={Object.values(ScriptQuestions)[15]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 17 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[16]}
                    questionValue={Object.values(ScriptQuestions)[16]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 18 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[17]}
                    questionValue={Object.values(ScriptQuestions)[17]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 19 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[18]}
                    questionValue={Object.values(ScriptQuestions)[18]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
                {QuestionCount >= 20 ? (
                  <ScriptQuestion
                    questionName={Object.keys(ScriptQuestions)[19]}
                    questionValue={Object.values(ScriptQuestions)[19]}
                    onChange={questionChange}
                    counterDecrease={counterDecrease}
                  />
                ) : (
                  <div class="hidden" />
                )}
              </div>
              <div class="text-center">
                <button
                  type="button"
                  onClick={counterIncrease}
                  class="text-center mx-auto w-full order-0 items-center rounded-md   bg-blue-900 px-8 py-5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none  sm:order-1 "
                >
                  Add A Question +
                </button>
              </div>
            </div>
          </div>

          <div class="col-span-3 bg-sky-50 border border-blue-800 shadow sm:overflow-hidden sm:rounded-md">
            <div class=" sm:rounded-md   py-6 px-4 sm:p-6">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-700">
                  Dynamic Variables
                </h3>
                <p class=" text-sm text-gray-900">
                  Use these variables in your questions above to load relatable
                  data about the customer.
                </p>
              </div>
              <div class="border-b mt-4 mb-4   border-blue-700" />

              <div class="space-y-1 grid grid-cols-6">
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[customer]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Customer's full Name
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[addr1]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Service address line 1 on order
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class="font-bold text-xs text-gray-900">[agent]</p>
                    <p class="ml-2 text-xs text-gray-900">
                      Sales Rep full Name
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[addr2]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Service address line 2 on order
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[supplier]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Sales Rep full Name
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[city]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Service address city on order
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[date]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Current date from customer's perspective
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[state]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Service address state on order
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[rate]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Associated product's rate ($)
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[zipcode]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Service address zipcode on order
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[unit]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Associated product's unit (e.g. kWh)
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[bill_addr1]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Billing address line 1 on order
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[term]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Associated product's term (in months)
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[bill_addr2]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Billing address line 2 on order
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[type]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Associated product's type (e.g. Variable, Fixed)
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[bill_city]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Billing address city on order
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[utility]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Associated product's utility Name (full)
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">[bill_state]</p>
                    <p class="ml-2  text-xs text-gray-900">
                      Billing address state on order
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">
                      [utility_short]
                    </p>
                    <p class="ml-2  text-xs text-gray-900">
                      Associated product's utility Name (short)
                    </p>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div class="inline-flex">
                    <p class=" font-bold text-xs text-gray-900">
                      [bill_zipcode]
                    </p>
                    <p class="ml-2  text-xs text-gray-900">
                      Billing address zipcode on order
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="shadow sm:overflow-hidden sm:rounded-md">
          <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <div class="flex items-center">
                {" "}
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  SMS/Text Opt In
                </h3>
              </div>
              <p class=" text-sm text-gray-500">
                Legal jargon pertaining to this script.
              </p>
            </div>

            <div class="grid grid-cols-6 gap-6 items-center">
              <div class="inline-flex col-span-6 sm:col-span-6">
                <textarea
                  rows="2"
                  name="legalDescription"
                  onChange={handleChange}
                  placeholder="Sample legal copy for customer..."
                  class="block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center flex mt-4 sm:mt-0">
          <button
            type="button"
            class="w-1/2 order-0 rounded-md  bg-orange-700 px-8 py-5 text-lg font-medium text-white hover:bg-opacity-80 focus:outline-none"
          >
            Preview
          </button>
          {SaveState === "saving" ? (
            <button
              disabled
              type="button"
              class="animate-fade ml-3 w-1/2 order-0 rounded-md  bg-blue-700 px-8 py-5 text-lg font-medium text-white focus:outline-none"
            >
              <svg
                role="status"
                class="inline mr-3 w-7 h-7 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="blue-700"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Saving...
            </button>
          ) : (
            <></>
          )}

          {SaveState === "saved" ? (
            <button
              disabled
              type="button"
              class="animate-fade ml-3 w-1/2 order-0 rounded-md  bg-green-800 px-8 py-5 text-lg font-medium text-white focus:outline-none"
            >
              <svg
                role="status"
                class="inline mr-3 w-7 h-7 text-white animate-bounce"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
              Saved..Returning Home
            </button>
          ) : (
            <></>
          )}

          {SaveState === "unsaved" ? (
            <>
              {NewScript.scriptName === "" ? (
                <button
                  disabled
                  type="button"
                  class="animate-fade ml-3 w-1/2 order-0 rounded-md  bg-blue-700 px-8 py-5 text-lg font-medium text-white opacity-60 focus:outline-none"
                >
                  Fill Out All Required Fields
                </button>
              ) : (
                <button
                  type="button"
                  onClick={scriptSubmission}
                  class="animate-fade ml-3 w-1/2 order-0 rounded-md  bg-blue-700 px-8 py-5 text-lg font-medium text-white hover:bg-opacity-80 focus:outline-none"
                >
                  Submit New Script
                </button>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateScript;
