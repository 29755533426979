import React, { useState, useEffect, useRef } from "react";

const CreateVendor = (props) => {
  //Get all Order Numbers//
  useEffect(() => {
    getVendorIDs();
  }, []);
  const [SaveState, setSaveState] = useState("unsaved");
  const [allExistingVendorIDs, setallExistingVendorIDs] = useState("");
  const [NewVendorLogo, setNewVendorLogo] = useState(null);
  const [NewVendor, setNewVendor] = useState({
    name: "",
    website: "",
    phone: "",
    VendorType: "Door-To-Door",
    customerServiceEmail: "",
    techSupportEmail: "",
    PrimaryContactFirstName: "",
    PrimaryContactLastName: "",
    PrimaryContactEmail: "",
    PrimaryContactTitle: "",
    PrimaryContactOfficePhone: "",
    PrimaryContactCellPhone: "",
    SecondaryContactFirstName: "",
    SecondaryContactLastName: "",
    SecondaryContactEmail: "",
    SecondaryContactTitle: "",
    SecondaryContactOfficePhone: "",
    SecondaryContactCellPhone: "",
    CompanyAddress: "",
    CompanySuite: "",
    CompanyCity: "",
    CompanyState: "AL",
    CompanyZip: "",
    supplierVendorID: "",
  });

  function handleChange(e) {
    setNewVendor({
      ...NewVendor,
      [e.target.name]: e.target.value,
    });
  }
  //
  //
  //
  //
  // Vendor Logo Stuff
  //
  //
  const uploadLogo = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setNewVendorLogo(base64);
    // setNewVendorLogo(URL.createObjectURL(e.target.files[0]));//
  };
  //
  //
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  //
  //
  function LogoClear() {
    setNewVendorLogo(null);
  }
  //
  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "vendors";
  //
  //
  //
  // //
  //
  // Pull All Existing Vendor Numbers //
  //
  //
  //
  const getVendorIDs = async () => {
    {
      const existingVendorNumbers = await client.query(
        q.Paginate(q.Match(q.Index("product-numbers")), { size: 6000 })
      );

      setallExistingVendorIDs(existingVendorNumbers.data);
    }
  };
  //
  //
  //
  // New Order Number //
  //
  //
  //
  //var vendorIdCheck = Math.floor(10000 + Math.random() * 90000);//
  var vendorIdCheck = Math.floor(1000000000 + Math.random() * 9000000000);
  var NewVendorNumber = "";

  //
  //
  switch (allExistingVendorIDs.includes(vendorIdCheck)) {
    case true:
      while (allExistingVendorIDs.includes(vendorIdCheck) === true) {
        vendorIdCheck = Math.floor(1000000000 + Math.random() * 9000000000);
        var NewVendorNumber = vendorIdCheck;
      }
      break;
    case false:
      var NewVendorNumber = vendorIdCheck;
      break;
  }

  //
  //
  //
  //
  //
  //
  //

  function vendorSubmission() {
    setTimeout(() => {
      setSaveState("saving");
    }, 100);
    setTimeout(() => {
      createVendorEntry();
    }, 3000);
    setTimeout(() => {
      setSaveState("saved");
    }, 2000);
    setTimeout(() => {
      props.onChange("Dashboard-Home");
    }, 4500);
  }

  const createVendorEntry = async (_callback) => {
    const inputDate = new Date().toGMTString();
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    await client.query(
      q.Create(q.Collection(COLLECTION_NAME), {
        data: {
          name: NewVendor.name,
          website: NewVendor.website,
          phone: NewVendor.phone,
          customerServiceEmail: NewVendor.customerServiceEmail,
          techSupportEmail: NewVendor.techSupportEmail,
          PrimaryContactFirstName: NewVendor.PrimaryContactFirstName,
          PrimaryContactLastName: NewVendor.PrimaryContactLastName,
          PrimaryContactEmail: NewVendor.PrimaryContactEmail,
          PrimaryContactTitle: NewVendor.PrimaryContactTitle,
          PrimaryContactOfficePhone: NewVendor.PrimaryContactOfficePhone,
          PrimaryContactCellPhone: NewVendor.PrimaryContactCellPhone,
          SecondaryContactFirstName: NewVendor.SecondaryContactFirstName,
          SecondaryContactLastName: NewVendor.SecondaryContactLastName,
          SecondaryContactEmail: NewVendor.SecondaryContactEmail,
          SecondaryContactTitle: NewVendor.SecondaryContactTitle,
          SecondaryContactOfficePhone: NewVendor.SecondaryContactOfficePhone,
          SecondaryContactCellPhone: NewVendor.SecondaryContactCellPhone,
          CompanyAddress: NewVendor.CompanyAddress,
          CompanySuite: NewVendor.CompanySuite,
          CompanyCity: NewVendor.CompanyCity,
          CompanyState: NewVendor.CompanyState,
          CompanyZip: NewVendor.CompanyZip,
          CompanyLogo: NewVendorLogo,
          openGateVendorID: NewVendorNumber,
          supplierVendorID: NewVendor.supplierVendorID,
          VendorType: NewVendor.VendorType,
          dateAdded: inputDate,
          dateAddedShort: formatDate(inputDate),
        },
      })
    );
    setTimeout(() => {
      setSaveState("saved");
    }, 6000);
    props.onChange("Dashboard-Home");
  };
  //
  //
  // End Fauna DB Shenanigans //
  //

  //
  //
  //
  //

  if (Object.keys(allExistingVendorIDs).length === 0) {
    return (
      <main class="animate-fade dashboard">
        <div className="text-center min-h-screen bg-gray-100 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <div class="animate-fade p-4 rounded p-20 rounded-3xl  ">
              <div class="flex flex-col items-center space-y-2">
                <svg
                  aria-hidden="true"
                  class="mb-10 w-20 h-20 animate-spin text-gray-100 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <h1 class="text-6xl text-tpvBlue font-bold">Loading</h1>
                <p class="text-2xl text-gray-700">
                  Please wait while we load this screen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <div class="animate-fade p-10 bg-blue-300 lg:grid lg:grid-cols-8 lg:gap-x-5">
        <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
          <div>
            <div class="heading">
              <nav class="sm:hidden" aria-label="Back">
                <a
                  href="#"
                  class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <svg
                    class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Back
                </a>
              </nav>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <button
                        type="button"
                        onClick={() => {
                          const newPosition = "Dashboard-Home";
                          props.onChange(newPosition);
                        }}
                        class="text-sm font-medium text-blue-900 hover:text-gray-700"
                      >
                        Vendors
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="h-5 w-5 flex-shrink-0 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <p class="ml-4 text-sm font-medium text-blue-900 underline">
                        Create Vendor
                      </p>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-blue-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  Create Vendor
                </h2>
              </div>
            </div>
          </div>
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Vendor Information
                </h3>
              </div>

              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Vendor Name<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    autocomplete="given-name"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Vendor ID/Nickname
                  </label>
                  <input
                    type="text"
                    name="supplierVendorID"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Vendor Website
                  </label>
                  <input
                    type="text"
                    name="website"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Vendor Type
                  </label>
                  <select
                    type="text"
                    name="VendorType"
                    onChange={handleChange}
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="Door-To-Door">Door-To-Door</option>
                    <option value="Call-Center">Call Center</option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="phone"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Customer Service Email<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="email"
                    name="customerServiceEmail"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Tech Support Email
                  </label>
                  <input
                    type="email"
                    name="techSupportEmail"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Address 1
                  </label>
                  <input
                    type="text"
                    name="CompanyAddress"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Address 2
                  </label>
                  <input
                    type="text"
                    name="CompanySuite"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    City
                  </label>
                  <input
                    type="text"
                    name="CompanyCity"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    State
                  </label>
                  <select
                    type="text"
                    name="CompanyState"
                    onChange={handleChange}
                    class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option selected="selected" value="AL">
                      Alabama
                    </option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Zip
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="CompanyZip"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div class="col-span-6 sm:col-span-1">
                <input
                  class="py-8 z-50 -mb-20 opacity-0 mx-auto block w-full text-lg text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  name="CompanyLogo"
                  type="file"
                  onChange={uploadLogo}
                  accept="image/*"
                  hidden
                ></input>
                <button
                  type="button"
                  class="mt-1 bg-white font-medium text-blue-500 block w-full rounded-md border-2 border-dashed border-gray-300 py-5 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                >
                  Upload Vendor Logo{" "}
                  <img class="w-1/6 rounded-full mx-auto" src={NewVendorLogo} />
                </button>{" "}
                <button
                  class="mt-2 flex justify-center font-medium text-gray-500 text-sm hover:underline mx-auto"
                  type="button"
                  onClick={LogoClear}
                >
                  Clear Logo
                </button>
              </div>
            </div>
          </div>

          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Primary Contact Information
                </h3>
              </div>

              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    First Name<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="PrimaryContactFirstName"
                    onChange={handleChange}
                    autocomplete="given-name"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Last Name<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="PrimaryContactLastName"
                    onChange={handleChange}
                    autocomplete="family-name"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Title
                  </label>
                  <input
                    type="text"
                    name="PrimaryContactTitle"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Email<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="email"
                    name="PrimaryContactEmail"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Office Phone
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="PrimaryContactOfficePhone"
                    onChange={handleChange}
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Cell Phone
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="PrimaryContactCellPhone"
                    onChange={handleChange}
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Secondary Contact Information
                </h3>
              </div>

              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="SecondaryContactFirstName"
                    onChange={handleChange}
                    autocomplete="given-name"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="SecondaryContactLastName"
                    onChange={handleChange}
                    autocomplete="family-name"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Title
                  </label>
                  <input
                    type="text"
                    name="SecondaryContactTitle"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="SecondaryContactEmail"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Office Phone
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="SecondaryContactOfficePhone"
                    onChange={handleChange}
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Cell Phone
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="SecondaryContactCellPhone"
                    onChange={handleChange}
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mt-4 sm:mt-0">
            {SaveState === "saving" ? (
              <div class="animate-fade text-center mt-4 flex sm:mt-0">
                <button
                  disabled
                  type="button"
                  class=" w-full order-0 rounded-md bg-blue-700 px-8 py-5 text-lg font-medium text-white  focus:outline-none sm:order-1"
                >
                  <svg
                    role="status"
                    class="inline mr-3 w-7 h-7 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="blue-700"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Saving...
                </button>
              </div>
            ) : (
              <></>
            )}

            {SaveState === "saved" ? (
              <div class="animate-fade text-center mt-4 flex sm:mt-0">
                <button
                  disabled
                  type="button"
                  class="w-full order-0 rounded-md bg-green-800 px-8 py-5 text-lg font-medium text-white focus:outline-none sm:order-1"
                >
                  <svg
                    role="status"
                    class="inline mr-3 w-7 h-7 text-white animate-bounce"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  Saved..Returning Home
                </button>
              </div>
            ) : (
              <></>
            )}

            {NewVendor.name === "" &&
            NewVendor.customerServiceEmail === "" &&
            NewVendor.PrimaryContactFirstName === "" &&
            NewVendor.PrimaryContactLastName === "" &&
            NewVendor.PrimaryContactEmail === "" ? (
              <div class="animate-fade text-center mt-4 flex sm:mt-0">
                <button
                  type="button"
                  disabled
                  class="opacity-70 w-full order-0 rounded-md   bg-blue-800 px-8 py-5 text-lg font-medium text-white focus:outline-none sm:order-1"
                >
                  Fill Out Required Fields
                </button>
              </div>
            ) : (
              <div>
                {SaveState !== "unsaved" ? (
                  <div class="hidden" />
                ) : (
                  <div class="animate-fade text-center mt-4 flex sm:mt-0">
                    <button
                      type="button"
                      onClick={vendorSubmission}
                      class=" w-full order-0 rounded-md   bg-blue-800 px-8 py-5 text-lg font-medium text-white hover:bg-blue-700 focus:outline-none sm:order-1"
                    >
                      Submit New Vendor
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
};

export default CreateVendor;
