import React, { useState, useEffect } from "react";

const ScriptDetails = (props) => {
  useEffect(() => {
    getAllScripts();
  }, []);
  //
  //
  //
  //
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "scripts";
  const [allExistingScripts, setallExistingScripts] = useState("");
  //
  //
  //
  //
  //
  // Pull all Scripts
  //
  //
  //
  //
  const getAllScripts = async () => {
    {
      const allScripts = await client.query(
        q.Map(
          q.Paginate(q.Documents(q.Collection(COLLECTION_NAME))),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallExistingScripts(allScripts.data);
    }
  };

  var matchedScript = null;

  switch (typeof allExistingScripts === "object") {
    case allExistingScripts === undefined:
      var matchedScript = "existing scripts is empty";
      break;
    case true:
      var matchedScript = allExistingScripts.find(
        (x) => x.data.scriptID === Number(props.scriptID)
      );
      break;
  }
  //
  //
  //
  //

  //
  //
  if (Object.keys(allExistingScripts).length === 0) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                aria-hidden="true"
                class="mb-10 w-20 h-20 animate-spin text-white fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <h1 class="text-6xl text-tpvBlue font-bold">Loading Script</h1>
              <p class="text-2xl text-gray-700">
                If script does not load please contact system admin.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <div class="create-order-1 p-10 bg-blue-300 bg-opacity-20 lg:grid lg:grid-cols-8 lg:gap-x-5">
        <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
          <div>
            <div class="heading">
              <nav class="sm:hidden" aria-label="Back">
                <a
                  href="#"
                  class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <svg
                    class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Back
                </a>
              </nav>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <button
                        type="button"
                        onClick={() => {
                          const newPosition = "Dashboard-Home";
                          props.onChange(newPosition);
                        }}
                        class="text-sm font-medium text-blue-900 hover:underline"
                      >
                        Scripts
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="h-5 w-5 flex-shrink-0 text-blue-900"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <a
                        href="#"
                        class="pointer-events-none ml-4 text-sm font-medium text-blue-900 underline"
                      >
                        Script Details
                      </a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-blue-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  Script Details{" "}
                  <span class="text-blue-400">
                    {matchedScript.data.scriptID}
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <form action="#" method="POST">
            <div class="shadow sm:overflow-hidden sm:rounded-md">
              <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
                <div>
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    TPV Script Information
                  </h3>
                  <p class=" text-sm text-gray-500">
                    Enter all script information and questions below.
                  </p>
                </div>

                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-6">
                    <label
                      for="first-name"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Script Name
                    </label>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      disabled
                      value={matchedScript.data.scriptName}
                      autocomplete="given-name"
                      class=" block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>

          <form action="#" method="POST">
            <div class="border border-blue-300 shadow sm:overflow-hidden sm:rounded-md">
              <div class="space-y-6 bg-gray-50 py-6 px-4 sm:p-6">
                <div>
                  <div class="flex items-center">
                    {" "}
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Script Information
                    </h3>
                  </div>
                </div>

                <div class="grid grid-cols-6 gap-6 items-center">
                  <div class="inline-flex col-span-6 sm:col-span-6">
                    <p class="text-sm text-gray-500">
                      {matchedScript.data.scriptInformation}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="grid grid-cols-6 gap-6 ">
            <div class="col-span-3 bg-white shadow sm:overflow-hidden sm:rounded-md">
              <div class="space-y-6 py-6 px-4 sm:p-6">
                <div>
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    TPV Script Questions
                  </h3>
                </div>

                <div class="grid grid-cols-6 gap-6 items-center">
                  {matchedScript.data.tpvQuestions
                    .filter((n) => n)
                    .map((post) => {
                      return (
                        <div class="inline-flex col-span-6 sm:col-span-6">
                          <p class="text-sm text-blue-700">
                            {post}{" "}
                            <span class="font-bold">
                              {" "}
                              Vars: {post.match(/\[.+?\]/g)}
                            </span>
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <div class="col-span-3 bg-sky-50 border border-blue-800 shadow sm:overflow-hidden sm:rounded-md">
              <div class=" sm:rounded-md   py-6 px-4 sm:p-6">
                <div>
                  <h3 class="text-lg font-medium leading-6 text-gray-700">
                    Dynamic Variables
                  </h3>
                  <p class=" text-sm text-gray-900">
                    Use these variables in your questions above to load
                    relatable data about the customer.
                  </p>
                </div>
                <div class="border-b mt-4 mb-4   border-blue-700" />

                <div class="space-y-1 grid grid-cols-6">
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[customer]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Customer's full name
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[addr1]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Service address line 1 on order
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class="font-bold text-xs text-gray-900">[agent]</p>
                      <p class="ml-2 text-xs text-gray-900">
                        Sales Rep full name
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[addr2]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Service address line 2 on order
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[supplier]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Sales Rep full name
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[city]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Service address city on order
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[date]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Current date from customer's perspective
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[state]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Service address state on order
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[rate]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Associated product's rate ($)
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[zipcode]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Service address zipcode on order
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[unit]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Associated product's unit (e.g. kWh)
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">
                        [bill_addr1]
                      </p>
                      <p class="ml-2  text-xs text-gray-900">
                        Billing address line 1 on order
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[term]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Associated product's term (in months)
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">
                        [bill_addr2]
                      </p>
                      <p class="ml-2  text-xs text-gray-900">
                        Billing address line 2 on order
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[type]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Associated product's type (e.g. Variable, Fixed)
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">
                        [bill_city]
                      </p>
                      <p class="ml-2  text-xs text-gray-900">
                        Billing address city on order
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">[utility]</p>
                      <p class="ml-2  text-xs text-gray-900">
                        Associated product's utility name (full)
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">
                        [bill_state]
                      </p>
                      <p class="ml-2  text-xs text-gray-900">
                        Billing address state on order
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">
                        [utility_short]
                      </p>
                      <p class="ml-2  text-xs text-gray-900">
                        Associated product's utility name (short)
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <div class="inline-flex">
                      <p class=" font-bold text-xs text-gray-900">
                        [bill_zipcode]
                      </p>
                      <p class="ml-2  text-xs text-gray-900">
                        Billing address zipcode on order
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="border border-blue-300 shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-gray-50 py-6 px-4 sm:p-6">
              <div>
                <div class="flex items-center">
                  {" "}
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    SMS/Text Opt In
                  </h3>
                </div>
                <p class=" text-sm text-gray-500">
                  Legal jargon pertaining to this script.
                </p>
              </div>

              <div class="grid grid-cols-6 gap-6 items-center">
                <div class="inline-flex col-span-6 sm:col-span-6">
                  <p class="text-sm text-gray-500">
                    {matchedScript.data.legalDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mt-4 flex sm:mt-0">
            <button
              type="button"
              onClick={() => {
                const newPosition = "Edit-Script";
                props.onChange(newPosition);
              }}
              class=" w-1/2 order-0 rounded-md   bg-orange-600 px-8 py-5 text-lg font-medium text-white hover:bg-opacity-80 focus:outline-none sm:order-1"
            >
              Edit
            </button>

            <button
              type="button"
              onClick={() => {
                const newPosition = "Dashboard-Home";
                props.onChange(newPosition);
              }}
              class="ml-3 w-1/2 order-0 rounded-md   bg-blue-700 px-8 py-5 text-lg font-medium text-white hover:bg-opacity-80 focus:outline-none sm:order-1"
            >
              Back To Dashboard Home
            </button>
          </div>
        </div>
      </div>
    );
};

export default ScriptDetails;
