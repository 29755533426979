import React, { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CampaignDashboard = (props) => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  //
  //
  //
  const isCampaignActive = async (id, condition) => {
    // setCreativeStateLoading(true);
    await fetch("/.netlify/functions/is_creative_active", {
      method: "PUT",
      body: JSON.stringify({
        creative_id: id,
        active: condition,
      }),
    });

    // setTimeout(function () {
    //   setCreativeStateLoading(false);
    // }, 1000);
  };
  //
  //
  //

  return (
    <main class="animate-fade products flex-1">
      <div class="lg:border-b lg:border-gray-200 lg:py-4 lg:flex lg:items-center lg:justify-between lg:px-8">
        <div class="hidden lg:flex flex-1 justify-between lg:mx-auto">
          <div class="flex flex-1">
            <form class="lg:flex lg:w-full lg:ml-0" action="#" method="GET">
              <label for="search-field" class="sr-only">
                Search
              </label>
              <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                  aria-hidden="true"
                >
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search-field"
                  name="search-field"
                  class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                  placeholder="Search Campaigns"
                  type="search"
                />
              </div>
            </form>
          </div>
        </div>

        <div class="hidden mt-4 lg:flex sm:mt-0 sm:ml-4">
          <button
            type="button"
            onClick={() => {
              const newPosition = "Create-Campaign";
              props.onChange(newPosition);
            }}
            class="flex flex-1 order-0 lg:inline-flex items-center rounded-md bg-tpvRed px-8 py-3 text-sm font-medium text-white hover:bg-red-500 focus:outline-none sm:order-1 sm:ml-3"
          >
            Add A New Campaign
          </button>
        </div>

        <div class="w-full lg:hidden">
          <button
            type="button"
            onClick={() => {
              const newPosition = "Create-Campaign";
              props.onChange(newPosition);
            }}
            class="w-full bg-tpvRed py-5 text-xl font-medium hover:bg-red-500 text-white hover:opacity-80 focus:outline-none "
          >
            New Campaign
          </button>
        </div>
      </div>

      <div class="lg:mt-8 mt-4">
        <div class="inline-block min-w-full border-b border-gray-200 align-middle">
          <div class="ml-6">
            <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
              {props.activeUser[0].data.vendor.name}
            </span>
            <h2 class="pt-3  pb-5 text-2xl font-semibold text-gray-900">
              Available Campaigns{" "}
              <span class="text-gray-400 italic">{props.activeSelectedDB}</span>{" "}
            </h2>
          </div>

          <table class="min-w-full divide-y divide-gray-300 flex lg:px-3">
            <DataGrid
              //
              //
              sx={{
                ".MuiDataGrid-root": {
                  "&:hover": {
                    bgcolor: "#e8f6fe",
                  },
                },
                ".MuiDataGrid-columnHeaders": {
                  background: `linear-gradient(90deg, rgba(170,255,233,0.26094187675070024) 35%, rgba(243,255,171,0.3533788515406162) 100%)`,
                },
              }}
              columns={[
                {
                  field: "id",
                  headerName: "Campaign No.",
                  width: 250,
                  renderCell: (params) => {
                    return (
                      <button
                        class="text-black font-medium hover:opacity-70"
                        type="button"
                        onClick={() => {
                          const newPosition = "Campaign-Details";
                          props.onChange(newPosition);
                        }}
                      >
                        {" "}
                        <span class="truncate">{params.value}</span>
                      </button>
                    );
                  },
                },

                {
                  field: "name",
                  headerName: "Campaign Name",
                  width: 250,
                },

                {
                  field: "status",
                  headerName: "Status",
                  width: 250,
                  renderCell: (params) => {
                    if (params.value === true) {
                      return (
                        <span class="isolate inline-flex rounded-md shadow-sm">
                          <button
                            type="button"
                            onClick={() =>
                              isCampaignActive(params.row.id, false)
                            }
                            class="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-red-200 hover:text-red-900 focus:z-10"
                          >
                            Inactive
                          </button>

                          <button
                            type="button"
                            disabled
                            class="relative -ml-px inline-flex items-center rounded-r-md bg-green-200 px-3 py-2 text-sm font-semibold text-green-900 ring-1 ring-inset ring-green-300 hover:bg-green-200 hover:text-green-900 focus:z-10"
                          >
                            Active
                          </button>
                        </span>
                      );
                    }

                    if (params.value === false) {
                      return (
                        <span class="isolate inline-flex rounded-md shadow-sm">
                          <button
                            type="button"
                            disabled
                            class="relative inline-flex items-center rounded-l-md bg-red-200 px-3 py-2 text-sm font-semibold text-red-900 ring-1 ring-inset ring-red-300"
                          >
                            Inactive
                          </button>

                          <button
                            type="button"
                            onClick={() =>
                              isCampaignActive(params.row.id, true)
                            }
                            class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-green-300 hover:bg-green-200 hover:text-green-900 focus:z-10"
                          >
                            Active
                          </button>
                        </span>
                      );
                    }
                  },
                },
                {
                  field: "vendor",
                  headerName: "Vendors",
                  width: 250,
                },
                {
                  field: "orders",
                  headerName: "Orders",
                  width: 250,
                },
              ]}
              columnHeaderHeight={80}
              rowHeight={70}
              // rows={allExistingOrders.map((post) =>
              //   //
              //   ({
              //     id: post.data.orderNumber,
              //     key: post.ref.value.id,
              //     name: post.data.firstName + " " + post.data.lastName,
              //     status: post.data.orderStatus,
              //     vendorName: post.data.Product.vendor.name,
              //     positionChange: props.onChange,
              //     orderDetailID: props.orderDetailID,
              //     dateAdded: post.data.dateAdded,
              //     dateVerified: post.data.timeVerified,
              //     dwelling: post.data.Product.dwelling,
              //     business: post.data.businessName,
              //     isIVRverification: post.data.isIVRverification,
              //     IVRcallStatus: post.data.IVRcallStatus,
              //     verified: post.data.timeVerified,
              //   })
              // )}
              rows={[
                {
                  id: 1309483,
                  name: "React",
                  vendor: "React",
                  status: true,
                  orders: 354,
                },
                {
                  id: 2193874,
                  name: "MUI",
                  vendor: "React",
                  status: false,
                  orders: 354,
                },
              ]}
              // onRowClick={(param) => handleOnClick(param.row)}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </table>
        </div>
      </div>
    </main>
  );
};

export default CampaignDashboard;
