import React, { useState } from "react";
import CreateUser from "./CreateUser";
import UserDashScreen from "./UserDashScreen";
import UserDetails from "./UserDetails";
import USimpleScreen from "./FilteredScreens/USimpleScreen";

const UserDashboard = (props) => {
  // Navigation/ /
  function appScreenChange(newValue) {
    setTimeout(function () {
      setapplicationScreen(newValue);
    }, 100);
  }
  const [applicationScreen, setapplicationScreen] = useState("");
  const [userID, setuserID] = useState();

  function userdetailID(newValue) {
    setTimeout(function () {
      setuserID(newValue);
    }, 100);
  }

  var appPage = "";

  switch (applicationScreen) {
    case "Create-User":
      appPage = <CreateUser onChange={appScreenChange} />;
      break;
    case "Dashboard-Home":
      appPage = (
        <UserDashScreen
          userdetailID={userdetailID}
          onChange={appScreenChange}
        />
      );
      break;
    case "User-Details":
      appPage = (
        <UserDetails
          activeSelectedDB={props.activeSelectedDB}
          activeUserVendor={props.activeUserVendor}
          activeUserSupplier={props.activeUserSupplier}
          activeUser={props.activeUser}
          onChange={appScreenChange}
          userdetailID={userdetailID}
          userID={userID}
        />
      );
      break;
    case "USimple-Screen":
      appPage = <USimpleScreen onChange={appScreenChange} />;
      break;
    case "User-Details":
      appPage = (
        <UserDetails
          activeSelectedDB={props.activeSelectedDB}
          activeUserVendor={props.activeUserVendor}
          activeUserSupplier={props.activeUserSupplier}
          activeUser={props.activeUser}
          onChange={appScreenChange}
          userdetailID={userdetailID}
          userID={userID}
        />
      );
      break;
    default:
      appPage = (
        <UserDashScreen
          activeSelectedDB={props.activeSelectedDB}
          activeUserVendor={props.activeUserVendor}
          activeUserSupplier={props.activeUserSupplier}
          activeUser={props.activeUser}
          onChange={appScreenChange}
          userdetailID={userdetailID}
        />
      );
  }

  return <div class="">{appPage}</div>;
};

export default UserDashboard;
