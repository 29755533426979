import React, { useState, useEffect, useRef } from "react";
import UtilityCommodity from "./UtilityCommodity";

const CreateUtility = (props) => {
  //Get all Order Numbers//
  useEffect(() => {
    getUtilityIDs();
  }, []);
  const [SaveState, setSaveState] = useState("unsaved");
  const [allExistingUtilityIDs, setallExistingUtilityIDs] = useState("");
  const [NewUtility, setNewUtility] = useState({
    name: "",
    nickname: "",
    website: "",
    phone: "",
    accountFieldName: "",
    utilityState_1: "AL",
    utilityState_2: "",
    utilityState_3: "",
  });

  function handleChange(e) {
    setNewUtility({
      ...NewUtility,
      [e.target.name]: e.target.value,
    });
  }
  //
  //
  const [UtilityCommodityCount, setUtilityCommodityCount] = useState({
    commodity_1: "",
    commodity_2: "",
    commodity_3: "",
    commodity_4: "",
    commodity_5: "",
  });

  //
  //
  //
  function commodityChange(e) {
    setUtilityCommodityCount({
      ...UtilityCommodityCount,
      [e.target.name]: e.target.value,
    });
  }
  //
  //
  //
  //
  // Question Counter //
  const [CommodityCount, setCommodityCount] = useState(1);
  //
  // Increase
  const counterIncrease = () => {
    if (CommodityCount < 5) {
      setCommodityCount((count) => count + 1);
    }
  };
  //
  //
  // Decrease
  //
  //
  //
  const counterDecrease = (e) => {
    if (CommodityCount > 1) {
      setCommodityCount((count) => count - 1);
    }
    //
    //Clear question if deleted //
    //
    setUtilityCommodityCount({
      ...UtilityCommodityCount,
      [e.currentTarget.name]: "",
    });
  };

  //
  //
  //
  //
  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "utilities";
  //
  //
  //
  // //
  //
  // Pull All Existing Vendor Numbers //
  //
  //
  //
  const getUtilityIDs = async () => {
    {
      const existingVendorNumbers = await client.query(
        q.Paginate(q.Match(q.Index("product-numbers")), { size: 6000 })
      );

      setallExistingUtilityIDs(existingVendorNumbers.data);
    }
  };
  //
  //
  //
  // New Order Number //
  //
  //
  //
  //var vendorIdCheck = Math.floor(10000 + Math.random() * 90000);//
  var vendorIdCheck = Math.floor(1000000000 + Math.random() * 9000000000);
  var NewUtilityNumber = "";

  //
  //
  switch (allExistingUtilityIDs.includes(vendorIdCheck)) {
    case true:
      while (allExistingUtilityIDs.includes(vendorIdCheck) === true) {
        vendorIdCheck = Math.floor(1000000000 + Math.random() * 9000000000);
        var NewUtilityNumber = vendorIdCheck;
      }
      break;
    case false:
      var NewUtilityNumber = vendorIdCheck;
      break;
  }

  //
  //
  //
  var UtilityCheck = false;
  //
  //
  switch (
    NewUtility.name !== "" &&
    NewUtility.nickname !== "" &&
    NewUtility.accountFieldName !== "" &&
    UtilityCommodityCount.commodity_1 !== ""
  ) {
    case true:
      var UtilityCheck = true;
      break;
    default:
      var UtilityCheck = false;
      break;
  }

  //
  //
  //
  //

  function utilitySubmission() {
    setTimeout(() => {
      setSaveState("saving");
    }, 100);
    setTimeout(() => {
      createUtilityEntry();
    }, 3000);
    setTimeout(() => {
      setSaveState("saved");
    }, 2000);
    setTimeout(() => {
      props.onChange("Dashboard-Home");
    }, 4500);
  }

  const createUtilityEntry = async (_callback) => {
    const inputDate = new Date().toGMTString();
    await client.query(
      q.Create(q.Collection(COLLECTION_NAME), {
        data: {
          name: NewUtility.name,
          nickname: NewUtility.nickname,
          website: NewUtility.website,
          phone: NewUtility.phone,
          accountFieldName: NewUtility.accountFieldName,
          utilityState_1: NewUtility.utilityState_1,
          utilityState_2: NewUtility.utilityState_2,
          utilityState_3: NewUtility.utilityState_3,
          commodity_1: UtilityCommodityCount.commodity_1,
          commodity_2: UtilityCommodityCount.commodity_2,
          commodity_3: UtilityCommodityCount.commodity_3,
          commodity_4: UtilityCommodityCount.commodity_4,
          commodity_5: UtilityCommodityCount.commodity_5,
          openGateUtilityID: NewUtilityNumber,
        },
      })
    );
    setTimeout(() => {
      setSaveState("saved");
    }, 6000);
    props.onChange("Dashboard-Home");
  };
  //
  //
  // End Fauna DB Shenanigans //
  //

  //
  //
  //
  //

  if (Object.keys(allExistingUtilityIDs).length === 0) {
    return (
      <main class="animate-fade dashboard">
        <div className="text-center min-h-screen bg-gray-100 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <div class="animate-fade p-4 rounded p-20 rounded-3xl  ">
              <div class="flex flex-col items-center space-y-2">
                <svg
                  aria-hidden="true"
                  class="mb-10 w-20 h-20 animate-spin text-gray-100 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <h1 class="text-6xl text-tpvBlue font-bold">Loading</h1>
                <p class="text-2xl text-gray-700">
                  Please wait while we load this screen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <div class="animate-fade p-10 bg-orange-200 lg:grid lg:grid-cols-8 lg:gap-x-5">
        <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
          <div>
            <div class="heading">
              <nav class="sm:hidden" aria-label="Back">
                <a
                  href="#"
                  class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  <svg
                    class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Back
                </a>
              </nav>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <button
                        type="button"
                        onClick={() => {
                          const newPosition = "Dashboard-Home";
                          props.onChange(newPosition);
                        }}
                        class="text-sm font-medium text-blue-900 hover:text-gray-700"
                      >
                        Utilities
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="h-5 w-5 flex-shrink-0 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <p class="ml-4 text-sm font-medium text-blue-900 underline">
                        Create Utility
                      </p>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-blue-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  Create Utility
                </h2>
              </div>
            </div>
          </div>
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Utility Information
                </h3>
              </div>

              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Utility Name<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    autocomplete="given-name"
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Utility ID/Nickname
                  </label>
                  <input
                    type="text"
                    name="nickname"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Utility Website
                  </label>
                  <input
                    type="text"
                    name="website"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="phone"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Account Field Name<span class="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    name="accountFieldName"
                    onChange={handleChange}
                    class="mt-1 bg-white block w-full rounded-md border border-gray-300 py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div class="sm:col-span-3 ">
                  <div class=" bg-white sm:overflow-hidden sm:rounded-md">
                    <div class=" pr-8">
                      <div class="hidden mb-4 ">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                          States{" "}
                          <span class=" text-sm text-gray-500">
                            Select the states this utility is available in
                          </span>
                        </h3>
                      </div>

                      <div class="mb-6 questions grid grid-cols-6 gap-6 items-center">
                        <div class="col-span-6 sm:col-span-2">
                          <label class="block text-sm font-medium text-gray-700">
                            State 1
                          </label>
                          <select
                            type="text"
                            name="utilityState_1"
                            onChange={handleChange}
                            class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          >
                            <option selected="selected" value="AL">
                              Alabama
                            </option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                        </div>
                        <div class="col-span-6 sm:col-span-2">
                          <label class="block text-sm font-medium text-gray-700">
                            State 2
                          </label>
                          <select
                            type="text"
                            name="utilityState_2"
                            onChange={handleChange}
                            class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          >
                            <option value="" selected disabled hidden>
                              Select Option
                            </option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                        </div>
                        <div class="col-span-6 sm:col-span-2">
                          <label class="block text-sm font-medium text-gray-700">
                            State 3
                          </label>
                          <select
                            type="text"
                            name="utilityState_3"
                            onChange={handleChange}
                            class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          >
                            <option value="" selected disabled hidden>
                              Select Option
                            </option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-3 ">
                  <div class="p-4 bg-gray-100 sm:overflow-hidden sm:rounded-md">
                    <div class=" py-6 pr-8">
                      <div class="mb-6 ">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">
                          Commodites
                        </h3>
                        <p class=" text-sm text-gray-500">
                          Add the commodities this utility offers
                        </p>
                      </div>

                      <div class="mb-6 questions grid grid-cols-6 gap-6 items-center">
                        {CommodityCount >= 1 ? (
                          <UtilityCommodity
                            commodityName={
                              Object.keys(UtilityCommodityCount)[0]
                            }
                            commodityValue={
                              Object.values(UtilityCommodityCount)[0]
                            }
                            onChange={commodityChange}
                            counterDecrease={counterDecrease}
                          />
                        ) : (
                          <div class="hidden" />
                        )}
                        {CommodityCount >= 2 ? (
                          <UtilityCommodity
                            commodityName={
                              Object.keys(UtilityCommodityCount)[1]
                            }
                            commodityValue={
                              Object.values(UtilityCommodityCount)[1]
                            }
                            onChange={commodityChange}
                            counterDecrease={counterDecrease}
                          />
                        ) : (
                          <div class="hidden" />
                        )}
                        {CommodityCount >= 3 ? (
                          <UtilityCommodity
                            commodityName={
                              Object.keys(UtilityCommodityCount)[2]
                            }
                            commodityValue={
                              Object.values(UtilityCommodityCount)[2]
                            }
                            onChange={commodityChange}
                            counterDecrease={counterDecrease}
                          />
                        ) : (
                          <div class="hidden" />
                        )}
                        {CommodityCount >= 4 ? (
                          <UtilityCommodity
                            commodityName={
                              Object.keys(UtilityCommodityCount)[3]
                            }
                            commodityValue={
                              Object.values(UtilityCommodityCount)[3]
                            }
                            onChange={commodityChange}
                            counterDecrease={counterDecrease}
                          />
                        ) : (
                          <div class="hidden" />
                        )}
                        {CommodityCount >= 5 ? (
                          <UtilityCommodity
                            commodityName={
                              Object.keys(UtilityCommodityCount)[4]
                            }
                            commodityValue={
                              Object.values(UtilityCommodityCount)[4]
                            }
                            onChange={commodityChange}
                            counterDecrease={counterDecrease}
                          />
                        ) : (
                          <div class="hidden" />
                        )}
                      </div>
                      <div class="text-center">
                        <button
                          type="button"
                          onClick={counterIncrease}
                          class="text-center mx-auto w-full order-0 items-center rounded-md   bg-blue-900 px-8 py-3 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none  sm:order-1 "
                        >
                          Add A Commodity +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-4 sm:mt-0">
            {SaveState === "saving" ? (
              <div class="animate-fade text-center mt-4 flex sm:mt-0">
                <button
                  disabled
                  type="button"
                  class=" w-full order-0 rounded-md bg-blue-700 px-8 py-5 text-lg font-medium text-white  focus:outline-none sm:order-1"
                >
                  <svg
                    role="status"
                    class="inline mr-3 w-7 h-7 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="blue-700"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Saving...
                </button>
              </div>
            ) : (
              <></>
            )}

            {SaveState === "saved" ? (
              <div class="animate-fade text-center mt-4 flex sm:mt-0">
                <button
                  disabled
                  type="button"
                  class="w-full order-0 rounded-md bg-green-800 px-8 py-5 text-lg font-medium text-white focus:outline-none sm:order-1"
                >
                  <svg
                    role="status"
                    class="inline mr-3 w-7 h-7 text-white animate-bounce"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  Saved..Returning Home
                </button>
              </div>
            ) : (
              <></>
            )}
            {NewUtility.name === "" && NewUtility.accountFieldName === ""}

            {SaveState !== "unsaved" ? (
              <div class="hidden" />
            ) : (
              <div>
                {UtilityCheck === false ? (
                  <div class="animate-fade text-center mt-4 flex sm:mt-0">
                    <button
                      type="button"
                      disabled
                      class="opacity-70 w-full order-0 rounded-md   bg-blue-800 px-8 py-5 text-lg font-medium text-white focus:outline-none sm:order-1"
                    >
                      Fill Out Required Fields
                    </button>
                  </div>
                ) : (
                  <div class="animate-fade text-center mt-4 flex sm:mt-0">
                    <button
                      type="button"
                      onClick={utilitySubmission}
                      class=" w-full order-0 rounded-md   bg-blue-800 px-8 py-5 text-lg font-medium text-white hover:bg-blue-700 focus:outline-none sm:order-1"
                    >
                      Submit New Utility
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
};

export default CreateUtility;
