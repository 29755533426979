import React, { useState } from "react";

const CommercialOrderDashItem = (props) => {
  const dateStr = props.dateAdded;
  const verifiedStr = props.dateVerified;
  const date = new Date(dateStr);
  const verifiedDate = new Date(verifiedStr);
  return (
    <tbody class="border-b border-sky-200 divide-y divide-sky-100 bg-gradient-to-r from-sky-50 via-sky-50 to-white">
      <tr>
        <td class="max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
          <div class="flex items-center space-x-3 lg:pl-2">
            <button
              class="hover:opacity-70"
              type="button"
              onClick={() => {
                props.orderDetailID(props.orderNumber);
                props.positionChange("Commercial-Order-Details");
              }}
            >
              {" "}
              <span class="truncate">{props.orderNumber}</span>
            </button>
          </div>
        </td>

        <td class="hidden lg:inline-block whitespace-nowrap px-6 py-3 text-left text-sm text-gray-500">
          <span class="hidden lg:inline-flex items-center py-2 text-sm text-gray-500">
            {" "}
            {props.businessName}
          </span>
        </td>

        {props.status === "Pending" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded bg-orange-200 px-3 py-2 text-xs font-medium text-orange-900">
              Pending
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        {props.status === "Complete" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded bg-green-200 px-3 py-2 text-xs font-medium text-green-900">
              Complete
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        {props.status === "Issue" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded bg-red-200 px-3 py-2 text-xs font-medium text-red-900">
              Failed/Rejected
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        <td class="hidden lg:table-cell py-3 text-left text-sm text-gray-500 ">
          {props.status === "Complete" ? (
            <>
              <span>Created - {date.toLocaleString()}</span>
              <span class="text-green-500 font-medium block">
                Verified - {verifiedDate.toLocaleString()}
              </span>
            </>
          ) : (
            <span>Created - {date.toLocaleString()}</span>
          )}
        </td>
        <td class="hidden lg:table-cell mt-1 py-3 text-left font-medium text-sm text-sky-700">
          <span>{props.vendorName}</span>
        </td>
      </tr>
    </tbody>
  );
};
export default CommercialOrderDashItem;
