import React, { useState, useEffect } from "react";
import { DocumentArrowDownIcon } from "@heroicons/react/20/solid";
import OrderSubmittedIVR from "../OrderSubmittedIVR";
import { red } from "@mui/material/colors";

const EmailsmsDetails = (props) => {
  const [redialIVR, setredialIVR] = useState(false);
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
  };

  const adjustedDate = new Date(props.dateAdded);
  const dateNow = Date.now();

  const elapsed = dateNow - adjustedDate;

  //
  //
  useEffect(() => {
    setredialIVR(false);
  }, []);
  //
  const downloadPDF = async (e) => {
    var pdfWindow = window.open("", "_blank");
    pdfWindow.document.write("Loading PDF...");
    var base64str = props.matchedOrder.data.enrollmentPdf;

    // decode base64 string, remove space for IE compatibility
    var binary = atob(base64str.replace(/\s/g, ""));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    var blob = new Blob([view], { type: "application/pdf" });

    // opens pdf in new tab
    // var newWindow = window.open();
    var url = (window.URL || window.webkitURL).createObjectURL(blob);

    pdfWindow.location.href = url;
  };
  //
  console.log(props);
  //
  //
  //
  return (
    <div>
      {redialIVR === false ? (
        <div class="animate-fade create-order-1 p-4 lg:p-10 bg-resiBg lg:grid lg:grid-cols-8 lg:gap-x-5">
          <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
            <div>
              <div class="heading">
                <nav class="flex" aria-label="Breadcrumb">
                  <ol role="list" class="flex items-center space-x-4">
                    <li>
                      <div class="flex">
                        <button
                          type="button"
                          onClick={() => {
                            const newPosition = "Dashboard-Home";
                            props.positionChange(newPosition);
                          }}
                          class="animate-pulse text-lg font-medium text-white hover:underline hover:text-gray-300"
                        >
                          Home
                        </button>
                      </div>
                    </li>
                    <li>
                      <div class="flex items-center">
                        <svg
                          class="h-5 w-5 flex-shrink-0 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <p class="ml-4 text-sm font-medium text-white underline">
                          Order Details
                        </p>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
              <div class="mt-2 lg:flex lg:items-center lg:justify-between">
                <div class="lg:flex items-center ">
                  <h2 class="w-full py-4 text-2xl font-bold leading-7 text-white sm:text-3xl sm:tracking-tight">
                    Residential Order Details{" "}
                    {props.matchedOrder.data.orderStatus === "Pending" ? (
                      <>
                        <span class="text-orange-300">{props.orderID}</span>{" "}
                      </>
                    ) : (
                      <div class="hidden" />
                    )}
                    {props.matchedOrder.data.orderStatus === "Complete" ? (
                      <>
                        <span class="text-green-400">{props.orderID}</span>{" "}
                      </>
                    ) : (
                      <div class="hidden" />
                    )}
                    {props.matchedOrder.data.orderStatus === "Issue" ? (
                      <>
                        {" "}
                        <span class="text-red-500">{props.orderID}</span>{" "}
                      </>
                    ) : (
                      <div class="hidden" />
                    )}
                    {props.matchedOrder.data.orderStatus === "Expired" ? (
                      <>
                        {" "}
                        <span class="text-violet-300">
                          {props.orderID}
                        </span>{" "}
                      </>
                    ) : (
                      <div class="hidden" />
                    )}
                  </h2>

                  {props.matchedOrder.data.orderStatus === "Expired" ? (
                    <div class="lg:px-6 lg:py-3 text-sm font-medium text-gray-500">
                      <span class="mt-4 lg:mt-0 flex justify-center w-full text-center items-center rounded-md bg-violet-100 px-4 py-3 text-xs font-medium text-violet-900 ring-1 ring-inset ring-violet-600/50">
                        Timed Out
                      </span>
                    </div>
                  ) : (
                    <div class="hidden" />
                  )}

                  {props.matchedOrder.data.orderStatus === "Pending" ? (
                    <div class="lg:px-6 lg:py-3 text-sm font-medium text-gray-500">
                      <span class="mt-4 lg:mt-0 flex justify-center w-full text-center items-center rounded-md bg-orange-100 px-4 py-3 text-xs font-medium text-orange-900 ring-1 ring-inset ring-orange-600/50">
                        Delivered
                      </span>
                    </div>
                  ) : (
                    <div class="hidden" />
                  )}

                  {(props.matchedOrder.data.orderStatus === "Pending") ===
                  true ? (
                    <div class="w-1/5 py-3 text-sm font-medium text-gray-500">
                      <button
                        onClick={() => setredialIVR(true)}
                        class="mt-4 lg:mt-0 flex justify-center w-full text-center items-center rounded-md bg-emerald-100 px-4 py-3 text-xs font-medium text-emerald-900 ring-1 ring-inset ring-emerald-600/50 hover:opacity-80"
                      >
                        Redial IVR
                      </button>
                    </div>
                  ) : (
                    <div class="hidden" />
                  )}

                  {props.matchedOrder.data.orderStatus === "Complete" ? (
                    <>
                      <div class="lg:px-6 lg:py-3 text-sm font-medium text-gray-500">
                        <span class="mt-4 lg:mt-0 flex justify-center w-full text-center items-center rounded-md bg-green-100 px-4 py-3 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/50">
                          Complete
                        </span>
                      </div>
                      <button
                        type="button"
                        onClick={() => downloadPDF()}
                        className="mt-4 lg:mt-0 flex justify-center w-full text-center items-center gap-x-2 rounded-md bg-sky-500 px-3.5 py-2.5 text-xs font-medium text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                      >
                        <DocumentArrowDownIcon
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        View/Download PDF
                      </button>
                    </>
                  ) : (
                    <div class="hidden" />
                  )}
                  {props.matchedOrder.data.orderStatus === "Issue" ? (
                    <div class="px-6 py-3 text-sm font-medium text-gray-500">
                      <span class="mt-4 lg:mt-0 flex justify-center w-full items-center rounded-md bg-red-100 px-4 py-3 text-xs font-medium text-red-900 ring-1 ring-inset ring-red-600/50">
                        Failed/Rejected
                      </span>
                    </div>
                  ) : (
                    <div class="hidden" />
                  )}
                </div>
              </div>

              <div class="mt-4 lg:mt-0 lg:flex lg:items-center lg:justify-between">
                <div class="lg:flex items-center ">
                  <h2 class="pt-2 text-white text-xs sm:tracking-tight lg:pt-0">
                    Date Added -
                    <span class="text-sky-200">
                      {""} {props.dateCreated.toLocaleString()}
                    </span>
                  </h2>

                  <div class="lg:ml-10">
                    <h2 class="pt-2 text-white text-xs sm:tracking-tight lg:pt-0">
                      Date Verified -
                      {props.matchedOrder.data.orderStatus === "Complete" ? (
                        <span class="text-green-400">
                          {""} {props.timeVerified.toLocaleString()}
                        </span>
                      ) : (
                        <div class="hidden"></div>
                      )}
                    </h2>
                    <h2 class="pt-2 text-white text-xs sm:tracking-tight lg:pt-0">
                      GPS -
                      <span class="text-blue-300">
                        Lat {""}:{""} {props.matchedOrder.data.latitude}
                      </span>
                      <span class="ml-4 text-blue-300">
                        Long {""}:{""} {props.matchedOrder.data.longitude}
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="mb-8 overflow-hidden bg-white shadow sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Customer Information
                  </h3>
                </div>
                <div class="border-t border-gray-200">
                  <dl>
                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        First Name
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.firstName.replace(/-/g, " ")}
                      </dd>
                    </div>
                    <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Last Name
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.lastName.replace(/-/g, " ")}
                      </dd>
                    </div>
                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        {" "}
                        Account Holder First Name
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.actHolderFirst.replace(
                          /-/g,
                          " "
                        )}
                      </dd>
                    </div>
                    <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Account Holder Last Name
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.actHolderLast.replace(
                          /-/g,
                          " "
                        )}
                      </dd>
                    </div>
                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Relationship With Account Holder
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.actHolderRelation.replace(
                          /-/g,
                          " "
                        )}
                      </dd>
                    </div>
                    <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Language
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.Language}
                      </dd>
                    </div>
                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Billing Telephone
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {formatPhoneNumber(props.matchedOrder.data.billingTel)}
                      </dd>
                    </div>
                    <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Type Of Phone
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.telType}
                      </dd>
                    </div>
                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Email Address
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.email}
                      </dd>
                    </div>
                    <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Service Address
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.serviceAddress +
                          ` ` +
                          props.matchedOrder.data.serviceSuite +
                          "," +
                          ` ` +
                          props.matchedOrder.data.serviceCity +
                          ` ` +
                          props.matchedOrder.data.serviceState +
                          ` ` +
                          props.matchedOrder.data.serviceZip}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div class="mb-8 overflow-hidden bg-white shadow sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Order Information Details
                  </h3>
                </div>
                <div class="border-t border-gray-200">
                  <dl>
                    <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Date Created
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.dateCreated.toLocaleString()}
                      </dd>
                    </div>
                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Commodity
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.Product.commodity}
                      </dd>
                    </div>
                    <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">Product</dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.Product.name}
                      </dd>
                    </div>

                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">Agent</dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.agent}
                      </dd>
                    </div>
                    <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        {
                          props.matchedOrder.data.Product.utility
                            .accountFieldName
                        }
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.accountNumber_1}
                      </dd>
                    </div>

                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Verification Phone Number
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {formatPhoneNumber(props.matchedOrder.data.verifyPhone)}
                      </dd>
                    </div>
                    <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Verification Email
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.verifyEmail}
                      </dd>
                    </div>
                    <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Delivery Method
                      </dt>
                      <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                        {props.matchedOrder.data.deliveryMethod}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              {props.matchedOrder.data.orderStatus === "Complete" ? (
                <div class="overflow-hidden bg-green-200 shadow sm:rounded-lg">
                  <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Order Verification Details
                    </h3>
                  </div>
                  <div class="border-t border-gray-200">
                    <dl>
                      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Status
                        </dt>
                        <dd class="mt-1 text-sm text-green-500 sm:col-span-2 sm:mt-0">
                          Complete
                        </dd>
                      </div>
                      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Date Signed
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {props.matchedOrder.data.timeVerified}
                        </dd>
                      </div>
                      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Latitude:
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {props.matchedOrder.data.latitude}
                        </dd>
                      </div>
                      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Longitude
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          {props.matchedOrder.data.longitude}
                        </dd>
                      </div>

                      <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                          Signature
                        </dt>
                        <dd class="mt-1 text-lg lg:text-sm text-blue-700 lg:text-gray-900 lg:col-span-2 sm:mt-0">
                          <img
                            class="w-1/2"
                            src={props.matchedOrder.data.signaturePng}
                          />
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              ) : (
                <div class="hidden" />
              )}
              <div class="text-center flex mt-4 sm:mt-0 pt-8">
                <button
                  type="button"
                  onClick={() => {
                    const newPosition = "Dashboard-Home";
                    props.positionChange(newPosition);
                  }}
                  class="text-sm font-medium text-white hover:text-gray-300 w-full order-0 rounded-md  bg-blue-500 px-8 py-5 text-lg font-medium text-white hover:bg-opacity-80 focus:outline-none"
                >
                  Back to Order Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {redialIVR === true ? (
        <OrderSubmittedIVR
          orderNumber={Number(props.orderID)}
          customer={props.matchedOrder.data}
          activeUserSupplier={props.activeUserSupplier}
          activeSelectedDB={props.activeSelectedDB}
          dwelling={"resi"}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default EmailsmsDetails;
