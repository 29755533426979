import React, { useState } from "react";
import CreateUser from "./CreateVendorAdmin";
import UserDashScreen from "./UserDashScreen";
import UserDetails from "./UserDetails";
import USimpleScreen from "../FilteredScreens/USimpleScreen";

const UserDashboard = (props) => {
  // Navigation/ /
  function appScreenChange(newValue) {
    setTimeout(function () {
      setapplicationScreen(newValue);
    }, 100);
  }
  const [applicationScreen, setapplicationScreen] = useState("Dashboard-Home");
  const [userEmail, setuserEmail] = useState();

  function userDetailEmail(newValue) {
    setTimeout(function () {
      setuserEmail(newValue);
    }, 100);
  }

  var appPage = "";

  switch (applicationScreen) {
    case "Create-User":
      appPage = (
        <CreateUser
          activeSelectedDB={props.activeSelectedDB}
          activeUserSupplier={props.activeUserSupplier}
          supplierAccess={props.supplierAccess}
          activeUserVendor={props.activeUserVendor}
          activeUserRole={props.activeUserRole}
          vendorAccess={props.vendorAccess}
          activeUserEmail={props.activeUserEmail}
          onChange={appScreenChange}
        />
      );
      break;

    case "Dashboard-Home":
      appPage = (
        <UserDashScreen
          activeSelectedDB={props.activeSelectedDB}
          activeUserSupplier={props.activeUserSupplier}
          activeUserVendor={props.activeUserVendor}
          activeUserRole={props.activeUserRole}
          activeUserEmail={props.activeUserEmail}
          userDetailEmail={userDetailEmail}
          onChange={appScreenChange}
        />
      );
      break;
    case "User-Details":
      appPage = (
        <UserDetails userEmail={userEmail} onChange={appScreenChange} />
      );
      break;
    case "USimple-Screen":
      appPage = <USimpleScreen onChange={appScreenChange} />;
      break;
    case "User-Details":
      appPage = <UserDetails onChange={appScreenChange} />;
      break;
    default:
      appPage = <UserDashScreen onChange={appScreenChange} />;
  }

  return <div class="">{appPage}</div>;
};

export default UserDashboard;
