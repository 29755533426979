import React, { useState } from "react";

const UserDashItem = (props) => {
  return (
    <tbody class="border-b divide-y divide-gray-100 bg-white">
      <tr>
        <td class="max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
          <div class="flex items-center lg:pl-2">
            <button
              class="hover:opacity-70"
              type="button"
              onClick={() => {
                props.userDetailEmail(props.email);
                props.positionChange("User-Details");
              }}
            >
              <span>{props.firstName + ` ` + props.lastName}</span>
            </button>
          </div>
        </td>
        <td class="hidden whitespace-nowrap px-6 py-3 text-left text-sm text-gray-500 md:table-cell">
          <span>{props.email}</span>
        </td>

        <td class="hidden whitespace-nowrap px-6 py-3 text-left text-sm text-gray-500 md:table-cell">
          <span>{props.userID}</span>
        </td>
        <td class="px-6 py-3 text-sm font-medium text-green-700">
          <div class="flex items-center space-x-3">
            <span>{props.role}</span>
          </div>
        </td>
        <td class="hidden whitespace-nowrap px-6 py-3 text-left text-sm text-gray-500 md:table-cell">
          <span> {props.vendor}</span>
        </td>
      </tr>

      {
        //"<!-- More projects... -->"
      }
    </tbody>
  );
};
export default UserDashItem;
