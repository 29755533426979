import React, { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const USimpleScreen = (props) => {
  return (
    <main class="animate-fade dashboard flex-1">
      <div class="lg:border-b lg:border-gray-200 lg:py-4 lg:flex lg:items-center lg:justify-between lg:px-8">
        <div class="hidden lg:flex flex-1 justify-between lg:mx-auto">
          <div class="flex flex-1">
            <label for="search-field" class="sr-only">
              Search
            </label>
            <div class="relative w-full text-gray-400 focus-within:text-gray-600">
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                aria-hidden="true"
              >
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                id="search-field"
                name="search-field"
                class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                placeholder="Search Users"
                type="search"
              />
            </div>
          </div>
        </div>

        <div class="hidden mt-4 lg:flex sm:mt-0 sm:ml-4">
          <button
            type="button"
            onClick={() => {
              const newPosition = "Create-User";
              props.onChange(newPosition);
            }}
            class="flex flex-1 order-0 lg:inline-flex items-center rounded-md bg-tpvRed px-8 py-3 text-sm font-medium text-white hover:bg-red-500 focus:outline-none sm:order-1 sm:ml-3"
          >
            + Add A User
          </button>
        </div>
        <div class="w-full lg:hidden">
          <button
            type="button"
            onClick={() => {
              const newPosition = "Create-User";
              props.onChange(newPosition);
            }}
            class="w-full bg-tpvRed py-5 text-xl font-medium text-white hover:opacity-80 focus:outline-none "
          >
            + Add A User
          </button>
        </div>
      </div>

      <div class="lg:mt-8 mt-4">
        <div class="inline-block min-w-full border-b border-gray-200 align-middle">
          <h2 class="px-4 py-5 sm:px-6 lg:text-2xl text-xl font-semibold text-gray-900">
            Current Users
          </h2>

          <table class="min-w-full">
            <thead>
              <tr class="border-t border-gray-200">
                <th
                  class="w-1/2 lg:w-0 border-b border-gray-200 bg-green-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  scope="col"
                >
                  <span class="lg:pl-2">User Name</span>
                </th>
                <th
                  class="hidden border-b border-gray-200 bg-green-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  scope="col"
                >
                  <span> User ID</span>
                </th>

                <th
                  class="border-b border-gray-200 bg-green-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  scope="col"
                >
                  <span> Role</span>
                </th>

                <th
                  class="hidden border-b border-gray-200 bg-green-50 text-center text-sm font-semibold text-gray-900 md:table-cell"
                  scope="col"
                >
                  <div class="flex">
                    <button class="pt-1">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="flex items-center hover:opacity-50">
                            <span className="sr-only">Open options</span>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-7 h-7 lg:block hidden"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
                              />
                            </svg>
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <p
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-400"
                                        : "text-gray-400",
                                      "block bg-gray-100 cursor-default font-light px-4 py-2 text-sm"
                                    )}
                                  >
                                    Simple
                                  </p>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    onClick={() => {
                                      const newPosition = "Dashboard-Home";
                                      props.onChange(newPosition);
                                    }}
                                    href="#"
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}
                                  >
                                    Default
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </button>
                    <button class="ml-3 pt-1 hover:opacity-50">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 lg:block hidden"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                        />
                      </svg>
                    </button>
                  </div>
                </th>
                <th
                  class="border-b border-gray-200 bg-green-50 py-3 pr-6 text-left text-sm font-semibold text-gray-900"
                  scope="col"
                ></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-100 bg-white">
              <tr>
                <td class="w-1/4 max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
                  <div class="flex items-center space-x-3 lg:pl-2">
                    <a href="#" class="truncate hover:text-gray-400">
                      <span>Jason Stanard</span>
                    </a>
                  </div>
                </td>
                <td class="hidden whitespace-nowrap px-6 py-3 text-left text-sm text-gray-500 md:table-cell">
                  <span>423423423</span>
                </td>
                <td class="px-6 py-3 text-sm font-medium text-gray-900">
                  <div class="flex items-center space-x-3">
                    <a href="#" class="truncate hover:text-gray-600">
                      <span>Channel Manager</span>
                    </a>
                  </div>
                </td>
              </tr>

              {
                //"<!-- More projects... -->"
              }
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
};

export default USimpleScreen;
