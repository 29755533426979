import React, { useState, useEffect } from "react";

const ReportDownloads = (props) => {
  useEffect(() => {
    pullReportTemplates();
    pullAllOrders();
  }, []);
  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const [allCompletedResiOrders, setallCompletedResiOrders] = useState("");
  const [allCompletedCommercialOrders, setallCompletedCommercialOrders] =
    useState("");
  const [allOrders, setallOrders] = useState("");
  const [allResiNoSales, setallResiNoSales] = useState("");
  const [allCommercialNoSales, setallCommercialNoSales] = useState("");
  const [registrationDate, setregistrationDate] = useState("");
  const [allExistingReports, setallExistingReports] = useState("");
  const [resiReportTemplate, setresiReportTemplate] = useState("");
  const [commercialReportTemplate, setcommercialReportTemplate] = useState("");
  const [reportPageType, setreportPageType] = useState("Residential");

  //
  //
  //
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("_");
  }

  //
  //
  //
  //
  //
  //
  // Pull all Resi Orders
  //
  // Pull all Orders
  //
  //
  const pullAllOrders = () => {
    getAllCompletedResiOrders();
    getAllCommercialOrders();
    getAllResiNoSales();
    getAllCommercialNoSales();
    getAllOrders();
  };
  //
  //
  const getAllCompletedResiOrders = async () => {
    {
      const allOrders = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("tpv-admin-search-by-type"), [
              "Complete",
              "Residential",
            ])
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallCompletedResiOrders(allOrders.data);
    }
  };

  //
  //
  //
  //
  //
  const getAllResiNoSales = async () => {
    {
      const noSales = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("tpv-admin-search-by-type"), [
              "Pending",
              "Residential",
            ])
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallResiNoSales(noSales.data);
    }
  };

  //
  //
  //
  //
  //
  //
  //
  const getAllOrders = async () => {
    {
      const noSales = await client.query(
        q.Map(
          q.Paginate(q.Match(q.Index("completed-tpvs"), "Pending")),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallOrders(noSales.data);
    }
  };
  //
  //
  //
  //
  //
  //
  // Pull all Commercial Orders
  //
  //
  //
  //
  const getAllCommercialOrders = async () => {
    {
      const allOrders = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("tpv-admin-search-by-type"), [
              "Complete",
              "Commercial",
            ])
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallCompletedCommercialOrders(allOrders.data);
    }
  };

  //
  //
  //
  //
  //
  const getAllCommercialNoSales = async () => {
    {
      const noSales = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("tpv-admin-search-by-type"), [
              "Pending",
              "Commercial",
            ])
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallCommercialNoSales(noSales.data);
    }
  };

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // Pull Template Types - When onboarding ask client how many different report types they need (default is Resi + Commercial, charge for extra)
  //
  //
  //
  const pullReportTemplates = () => {
    getResiReportTemplate();
    getCommercialReportTemplate();
  };
  //
  //
  const getResiReportTemplate = async () => {
    {
      const reportTemplate = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("pull-reporting-template"), [true, "Residential"])
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setresiReportTemplate(reportTemplate.data);
    }
  };
  //
  //
  //
  const getCommercialReportTemplate = async () => {
    {
      const reportTemplate = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("pull-reporting-template"), [true, "Commercial"])
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setcommercialReportTemplate(reportTemplate.data);
    }
  };
  //
  //
  const getExistingReports = async () => {
    {
      const allReports = await client.query(
        q.Map(
          q.Paginate(q.Match(q.Index("find-reporting-template"), false)),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallExistingReports(allReports.data);
    }
  };
  //
  //
  //
  //
  //
  // CSV Stuff
  //
  //CSV DATA - SHOULD COME FROM ORDER ARRAY
  //
  // Lets use this switch to hold the report formats - depending on the fauna db select above this switch will give us different report templates//
  //
  //
  // Date options
  //
  //
  var newDateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  //
  //
  // Deal End Date //
  //
  //
  //
  function addYears(date, years) {
    date.setFullYear(date.getFullYear() + years);
    return date;
  }
  //
  //
  //

  switch (
    typeof allCompletedCommercialOrders === "object" &&
    typeof allCompletedResiOrders === "object" &&
    typeof allResiNoSales === "object" &&
    typeof allCommercialNoSales === "object" &&
    typeof resiReportTemplate === "object" &&
    typeof commercialReportTemplate === "object"
  ) {
    case true:
      switch (props.activeSelectedDB) {
        case "Greenwave-Energy":
          //
          var resiCsvFileName = resiReportTemplate[0].data.nameTemplate.replace(
            /[\[\]]date[\[\]]/g,
            formatDate(new Date())
          );
          var commercialCsvFileName =
            commercialReportTemplate[0].data.nameTemplate.replace(
              /[\[\]]date[\[\]]/g,
              formatDate(new Date())
            );
          //
          var resiCsvFileRowData = allCompletedResiOrders.map((post) => {
            return [
              "NEW",
              "",
              post.data.accountNumber_1,
              "",
              "",
              "",
              post.data.firstName + ` ` + post.data.lastName,
              post.data.serviceAddress,
              "",
              post.data.serviceCity,
              post.data.serviceState,
              post.data.serviceZip,
              post.data.Product.dwelling,
              post.data.firstName + ` ` + post.data.lastName,
              "",
              post.data.billingTel,
              "",
              "",
              new Date(post.ts / 1000).toLocaleString("en-US", newDateOptions),
              new Date(post.ts / 1000).toLocaleString("en-US", newDateOptions),
              addYears(new Date(post.ts / 1000), 1).toLocaleString(
                "en-US",
                newDateOptions
              ),
              post.data.agent,
              "Deal Group Code Here",
              // post.data.Product.reportingID,
              post.data.Product.utility.nickname,
              "Sales Group Code Here",
              // post.data.Product.vendor.salesGroupCode,
              post.data.Language.charAt(0),
            ];
          });
          var commercialCsvFileRowData = allCompletedCommercialOrders.map(
            (post) => {
              return [
                "NEW",
                "",
                post.data.accountNumber_1,
                "",
                "",
                "",
                post.data.firstName + ` ` + post.data.lastName,
                post.data.serviceAddress,
                "",
                post.data.serviceCity,
                post.data.serviceState,
                post.data.serviceZip,
                post.data.Product.dwelling,
                post.data.firstName + ` ` + post.data.lastName,
                "",
                post.data.billingTel,
                "",
                "",
                new Date(post.ts / 1000).toLocaleString(
                  "en-US",
                  newDateOptions
                ),
                new Date(post.ts / 1000).toLocaleString(
                  "en-US",
                  newDateOptions
                ),
                addYears(new Date(post.ts / 1000), 1).toLocaleString(
                  "en-US",
                  newDateOptions
                ),
                post.data.agent,
                post.data.Product.reportingID,
                post.data.Product.utility.nickname,
                post.data.Product.vendor.salesGroupCode,
                post.data.Language.charAt(0),
              ];
            }
          );

          break;
        case "Callective-Energy":
          //
          var resiCsvFileName = resiReportTemplate[0].data.nameTemplate.replace(
            /[\[\]]date[\[\]]/g,
            formatDate(new Date())
          );
          var commercialCsvFileName =
            commercialReportTemplate[0].data.nameTemplate.replace(
              /[\[\]]date[\[\]]/g,
              formatDate(new Date())
            );
          //
          var resiCsvFileRowData = allCompletedResiOrders.map((post) => {
            return [
              "NEW",
              "",
              post.data.accountNumber_1,
              "",
              "",
              "",
              post.data.firstName + ` ` + post.data.lastName,
              post.data.serviceAddress,
              "",
              post.data.serviceCity,
              post.data.serviceState,
              post.data.serviceZip,
              post.data.Product.dwelling,
              post.data.firstName + ` ` + post.data.lastName,
              "",
              post.data.billingTel,
              "",
              "",
              new Date(post.ts / 1000).toLocaleString("en-US", newDateOptions),
              new Date(post.ts / 1000).toLocaleString("en-US", newDateOptions),
              addYears(new Date(post.ts / 1000), 1).toLocaleString(
                "en-US",
                newDateOptions
              ),
              post.data.agent,
              post.data.Product.reportingID,
              post.data.Product.utility.nickname,
              post.data.Product.vendor.salesGroupCode,
              post.data.Language,
            ];
          });
          var commercialCsvFileRowData = allCompletedCommercialOrders.map(
            (post) => {
              return [
                "NEW",
                "",

                "",
                "",
                post.data.businessName,
                post.data.businessName,
                post.data.businessAddress,
                "",
                post.data.businessCity,
                post.data.businessState,
                post.data.businessZip,
                post.data.Product.dwelling,
                post.data.primaryFirstName + ` ` + post.data.primaryLastName,
                post.data.Product.dmPosition,
                post.data.billingTel,
                "",
                post.data.Product.businessEmail,

                new Date(post.ts / 1000).toLocaleString(
                  "en-US",
                  newDateOptions
                ),
                new Date(post.ts / 1000).toLocaleString(
                  "en-US",
                  newDateOptions
                ),
                addYears(new Date(post.ts / 1000), 1).toLocaleString(
                  "en-US",
                  newDateOptions
                ),
                post.data.agent,

                post.data.Product.utility.nickname,
                post.data.Product.vendor.salesGroupCode,
                post.data.accountNumber_1,
                post.data.accountNumber_2,
                post.data.accountNumber_3,
                post.data.accountNumber_4,
                post.data.accountNumber_5,
                post.data.accountNumber_6,
                post.data.accountNumber_7,
                post.data.accountNumber_8,
                post.data.accountNumber_9,
                post.data.accountNumber_10,
                post.data.Language.charAt(0),
              ];
            }
          );

          break;

        default:
          var resiCsvFileRowData =
            "There are no completed TPVs for this supplier";
          var commercialCsvFileRowData =
            "There are no completed Commercial TPVs for this supplier";

          break;
      }
      break;
    default:
      var resiCsvFileRowData = "Existing Orders Are Empty";
      var commercialCsvFileRowData = "Existing Commercial Orders Are Empty";
      break;
  }

  /*  [
      "NEW",
      "",
      "355576830",
      "",
      "",
      "",
      "Darnell Davis",
      "2712 mortin ct.",
      "",
      "Bakersfield",
      "CA",
      "93304",
      "Residential",
      "Darnell Davis",
      "",
      "6614489859",
      "",
      "",
      "7/31/2022",
      "7/31/2022",
      "7/31/2023",
      "Winston Robertson",
      "113GWEPGE_VAR_WACOG_REC_SM",
      "PG&E",
      "NAVC_GREENWAVEPGE",
      "E",
    ],

*/
  //
  //
  // CSV Functions - Resi + Commercial, charge for additional report types
  //
  //
  //
  //create a user-defined function to download CSV file
  const resi_csv_download = () => {
    //CSV HEADER ROWS - SHOULD COME FROM SUPPLIER REPORT COLLECTION AS THEIR REPORT TEMPLATE
    var csv = resiReportTemplate[0].data.templateHeaders;

    //merge the data with CSV
    resiCsvFileRowData.forEach(function (row) {
      csv += row.join(",");
      csv += "\n";
    });

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = resiCsvFileName;
    hiddenElement.click();
  };
  //
  //
  //
  const commercial_csv_download = () => {
    //CSV HEADER ROWS - SHOULD COME FROM SUPPLIER REPORT COLLECTION AS THEIR REPORT TEMPLATE
    var csv = commercialReportTemplate[0].data.templateHeaders;

    //merge the data with CSV
    commercialCsvFileRowData.forEach(function (row) {
      csv += row.join(",");
      csv += "\n";
    });

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = commercialCsvFileName;
    hiddenElement.click();
  };
  //
  //
  //

  if (Object.keys(allOrders).length === 0) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                aria-hidden="true"
                class="mb-10 w-20 h-20 animate-spin text-white fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <h1 class="text-6xl text-tpvBlue font-bold">Loading Orders</h1>
              <p class="text-2xl text-gray-700">
                If orders do not load please contact system admin.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <div class="animate-fade min-h-full">
        <div class="lg:border-b lg:border-gray-200 lg:py-4 lg:flex lg:items-center lg:justify-between lg:px-8">
          <div class="hidden lg:flex flex-1 justify-between lg:mx-auto">
            <div class="flex flex-1">
              <label for="search-field" class="sr-only">
                Search
              </label>
              <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                  aria-hidden="true"
                >
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search-field"
                  name="search-field"
                  class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                  placeholder="Search Report # Or Name"
                  type="search"
                />
              </div>
            </div>
            <div class="mt-5 flex xl:mt-0 xl:ml-4">
              <span class="ml-3 hidden sm:block">
                <button
                  type="button"
                  onClick={() => {
                    const newPosition = "View-Resi-TPVs";
                    props.onChange(newPosition);
                  }}
                  class="inline-flex items-center rounded-md bg-gradient-to-r from-orange-500 to-tpvRed px-12 py-3 text-sm font-medium text-white hover:opacity-70 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="mr-2 w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                    />
                  </svg>
                  View TPVs
                </button>
              </span>

              <div class="relative ml-3 sm:hidden">
                <button
                  type="button"
                  class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                  id="mobile-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  More
                  <svg
                    class="-mr-1 ml-2 h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>

                <div
                  class="absolute right-0 z-10 mt-2 -mr-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="mobile-menu-button"
                  tabindex="-1"
                >
                  <a
                    href="#"
                    class="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="mobile-menu-item-0"
                  >
                    New Template Request
                  </a>
                  <a
                    href="#"
                    class="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="mobile-menu-item-1"
                  >
                    View
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <header class="bg-gray-50 py-8">
          <div class=" px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
            <div class="min-w-0 flex-1">
              <h1 class="mt-2 text-2xl font-bold leading-7 text-blue-900 sm:truncate sm:text-4xl sm:tracking-tight">
                {props.activeSelectedDB.toUpperCase()}
              </h1>
            </div>
            <div class="mt-3 flex sm:mt-0 sm:ml-4">
              {reportPageType === "Commercial" ? (
                <button
                  type="button"
                  class="ml-3 inline-flex items-center rounded-md border-2 border-blue-400 bg-blue-400 px-6 py-3 text-sm font-medium text-white shadow-sm focus:outline-none"
                  disabled
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 mr-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                    />
                  </svg>
                  Commercial TPVs
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setreportPageType("Commercial")}
                  class="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-6 py-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-sky-100 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 mr-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    />
                  </svg>
                  Commercial TPVs
                </button>
              )}

              {reportPageType === "Residential" ? (
                <button
                  type="button"
                  class="ml-3 inline-flex items-center rounded-md border-2 border-blue-400 bg-blue-400 px-6 py-3 text-sm font-medium text-white shadow-sm focus:outline-none"
                  disabled
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 mr-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    />
                  </svg>
                  Residential TPVs
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setreportPageType("Residential")}
                  class="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-6 py-3 text-sm font-medium text-gray-700 shadow-sm hover:bg-sky-100 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 mr-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    />
                  </svg>
                  Residential TPVs
                </button>
              )}
            </div>
          </div>
        </header>

        {reportPageType === "Residential" ? (
          <main class="residential animate-fade pt-8 pb-16">
            <div class="sm:px-6 lg:px-8">
              <div class="px-4 sm:px-0">
                <h2 class="text-lg font-medium text-gray-900">
                  Residential TPV Report Downloads
                </h2>
                <div class="hidden sm:block">
                  <div class="border-b border-gray-200">
                    <nav class="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                      <a
                        href="#"
                        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      >
                        Timed out
                        <span class="bg-gray-100 text-gray-900 hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                          {allResiNoSales.length}
                        </span>
                      </a>

                      <a
                        href="#"
                        class="border-sky-500 text-sky-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      >
                        Completed
                        <span class="bg-sky-100 text-sky-600 hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                          {allCompletedResiOrders.length}
                        </span>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>

              <ul
                role="list"
                class="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
              >
                <li>
                  <button
                    onClick={() => {
                      resi_csv_download();
                    }}
                    class="w-full group block hover:opacity-80"
                  >
                    <div class="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                      <div class="flex min-w-0 flex-1 items-center">
                        <div class="flex-shrink-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            class="w-10 h-10 text-purple-800 group-hover:opacity-70"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm5.845 17.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V12a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z"
                              clip-rule="evenodd"
                            />
                            <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                          </svg>
                        </div>
                        <div class="w-full flex-1 px-4">
                          <div>
                            <p class="text-left text-lg font-medium text-sky-600">
                              {resiCsvFileName}
                            </p>
                            <p class="mt-4 flex items-center text-sm text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>

                              <span class="truncate">
                                Generated {new Date().toGMTString()}
                              </span>
                            </p>
                            <div class="mt-4 hidden md:block">
                              <div>
                                <p class="flex items-start text-sm text-gray-500">
                                  <svg
                                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  {allCompletedResiOrders.length} Completed TPVs
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center group-hover:opacity-70">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="mr-2 w-6 h-6 text-purple-400"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
                          />
                        </svg>
                        <p class="text-sm"> Download Report</p>
                      </div>
                    </div>
                  </button>
                </li>
              </ul>
              <div class="border-t border-gray-200 " />
              <nav
                class="hidden flex items-center justify-between border-t border-gray-200 px-4 sm:px-0"
                aria-label="Pagination"
              >
                <div class="-mt-px flex w-0 flex-1">
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    <svg
                      class="mr-3 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Previous
                  </a>
                </div>
                <div class="hidden md:-mt-px md:flex">
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    1
                  </a>
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-sky-500 px-4 pt-4 text-sm font-medium text-sky-600"
                    aria-current="page"
                  >
                    2
                  </a>
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    3
                  </a>
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    4
                  </a>
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    5
                  </a>
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    6
                  </a>
                </div>
                <div class="-mt-px flex w-0 flex-1 justify-end">
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    Next
                    <svg
                      class="ml-3 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </nav>
            </div>
          </main>
        ) : (
          <main class="commercial animate-fade  pt-8 pb-16">
            <div class="sm:px-6 lg:px-8">
              <div class="px-4 sm:px-0">
                <h2 class="text-lg font-medium text-gray-900">
                  Commercial TPV Report Downloads
                </h2>
                <div class="hidden sm:block">
                  <div class="border-b border-gray-200">
                    <nav class="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                      <a
                        href="#"
                        class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      >
                        Timed out
                        <span class="bg-gray-100 text-gray-900 hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                          {allCommercialNoSales.length}
                        </span>
                      </a>

                      <a
                        href="#"
                        class="border-sky-500 text-sky-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      >
                        Completed
                        <span class="bg-sky-100 text-sky-600 hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                          {allCompletedCommercialOrders.length}
                        </span>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>

              <ul
                role="list"
                class="mt-5 divide-y divide-gray-200 border-t border-gray-200 sm:mt-0 sm:border-t-0"
              >
                <li>
                  <button
                    onClick={() => {
                      commercial_csv_download();
                    }}
                    class="w-full group block hover:opacity-80"
                  >
                    <div class="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                      <div class="flex min-w-0 flex-1 items-center">
                        <div class="flex-shrink-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            class="w-10 h-10 text-orange-500 group-hover:opacity-70"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm5.845 17.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V12a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z"
                              clip-rule="evenodd"
                            />
                            <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                          </svg>
                        </div>
                        <div class="w-full flex-1 px-4">
                          <div>
                            <p class="text-left text-lg font-medium text-sky-600">
                              {commercialCsvFileName}
                            </p>
                            <p class="mt-4 flex items-center text-sm text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>

                              <span class="truncate">
                                Generated {new Date().toGMTString()}
                              </span>
                            </p>
                            <div class="mt-4 hidden md:block">
                              <div>
                                <p class="flex items-start text-sm text-gray-500">
                                  <svg
                                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  {allCompletedCommercialOrders.length}{" "}
                                  Completed TPVs
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center group-hover:opacity-70">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="mr-2 w-6 h-6 text-purple-400"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
                          />
                        </svg>
                        <p class="text-sm"> Download Report</p>
                      </div>
                    </div>
                  </button>
                </li>
              </ul>
              <div class="border-t border-gray-200 " />
              <nav
                class="hidden flex items-center justify-between border-t border-gray-200 px-4 sm:px-0"
                aria-label="Pagination"
              >
                <div class="-mt-px flex w-0 flex-1">
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    <svg
                      class="mr-3 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Previous
                  </a>
                </div>
                <div class="hidden md:-mt-px md:flex">
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    1
                  </a>
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-sky-500 px-4 pt-4 text-sm font-medium text-sky-600"
                    aria-current="page"
                  >
                    2
                  </a>
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    3
                  </a>
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    4
                  </a>
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    5
                  </a>
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    6
                  </a>
                </div>
                <div class="-mt-px flex w-0 flex-1 justify-end">
                  <a
                    href="#"
                    class="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                  >
                    Next
                    <svg
                      class="ml-3 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </nav>
            </div>
          </main>
        )}
      </div>
    );
};

export default ReportDownloads;
