import React, { useState, useEffect } from "react";
import { PaperClipIcon } from "@heroicons/react/20/solid";

export default function Example(props) {
  const activeUserData = JSON.parse(window.localStorage.getItem("ACTIVE_USER"));

  return (
    <>
      <div className="overflow-hidden bg-gray-100">
        <div className="mt-20 mb-20 max-w-5xl px-10 py-10 mx-auto bg-white overflow-hidden shadow sm:rounded-lg">
          <div className="flex items-center ">
            <div className="flex-shrink-0">
              {activeUserData[0].data.userImage === "" ? (
                <svg
                  class="h-20 w-20 flex-shrink-0 rounded-full bg-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : (
                <img
                  class="h-20 w-20 flex-shrink-0 rounded-full bg-gray-300"
                  src={activeUserData[0].data.userImage}
                  alt=""
                />
              )}
            </div>
            <div className="ml-6 px-4 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                {activeUserData[0].data.role} Details
              </h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                User details.
              </p>
            </div>
          </div>

          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Full name
                </dt>
                <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">
                    {activeUserData[0].data.firstName +
                      " " +
                      activeUserData[0].data.lastName}
                  </span>
                  <span className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Update
                    </button>
                  </span>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Vendor
                </dt>
                <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">
                    {activeUserData[0].data.vendor.name}
                  </span>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  User ID
                </dt>
                <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">
                    {activeUserData[0].ref["@ref"].id}
                  </span>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Supplier access
                </dt>
                <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div class="">
                    {activeUserData[0].data.suppliers.map((post) => {
                      return (
                        <>
                          <div class="relative flex inline-flex items-start">
                            <div class="min-w-0 flex-1 text-sm">
                              <label
                                for="person-1"
                                class="select-none font-base text-gray-900 mr-2"
                              >
                                {post.replace(/_/g, " ") + " " + "•"}
                              </label>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Email address
                </dt>
                <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">
                    {" "}
                    {activeUserData[0].data.email}
                  </span>
                  <span className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Update
                    </button>
                  </span>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Password
                </dt>
                <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">************</span>
                  <span className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Update
                    </button>
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
