import React, { useState } from "react";

const VendorDashItem = (props) => {
  return (
    <tbody class="border-b border-gray-200 divide-y divide-gray-100 bg-white">
      <tr>
        <td class="w-1/4 max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-500">
          <div class="flex items-center space-x-3 lg:pl-2">
            <button
              class="hover:opacity-70"
              type="button"
              onClick={() => {
                props.companyDetailID(props.vendorID);
                props.positionChange("Company-Details");
              }}
            >
              {" "}
              <span>{props.vendorID}</span>
            </button>
          </div>
        </td>

        <td class="lg:whitespace-nowrap px-6 py-3 text-left text-sm text-gray-900 md:table-cell">
          <span> {props.name}</span>
        </td>

        {props.status === "Pending" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded bg-orange-200 px-3 py-2 text-xs font-medium text-orange-900">
              Pending
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        {props.status === "Complete" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded bg-green-200 px-3 py-2 text-xs font-medium text-green-900">
              Complete
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        {props.status === "Issue" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded bg-red-200 px-3 py-2 text-xs font-medium text-red-900">
              Failed/Rejected
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        <td class="hidden lg:whitespace-nowrap px-6 py-3 text-left text-gray-500 md:table-cell">
          <span> {props.email}</span>
        </td>
        <td class="whitespace-nowrap px-6 py-3 text-left text-gray-500 md:table-cell">
          <span> {props.state}</span>
        </td>
      </tr>
    </tbody>
  );
};
export default VendorDashItem;
