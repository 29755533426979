import React, { useState } from "react";
import CreateScript from "./CreateScript";
import CreateScriptIVR from "./CreateIvrScript";
import EditScript from "./EditScript";
import ScriptDetails from "./ScriptDetails";
import ScriptDashScreen from "./ScriptDashScreen";
import SSimpleScreen from "./FilteredScreens/SSimpleDashScreen";
import SDetailsScreen from "./FilteredScreens/SDetailsDashScreen";
import CreateIvrScript from "./CreateIvrScript";

const ScriptDashboard = (props) => {
  // Navigation/ /
  function appScreenChange(newValue) {
    setTimeout(function () {
      setapplicationScreen(newValue);
    }, 100);
  }
  const [applicationScreen, setapplicationScreen] = useState("");
  const [scriptID, setscriptID] = useState();

  function scriptDetailID(newValue) {
    setTimeout(function () {
      setscriptID(newValue);
    }, 100);
  }

  var appPage = "";

  switch (applicationScreen) {
    case "Create-Script":
      appPage = (
        <CreateScript
          activeUserSupplier={props.activeUserSupplier}
          activeUserVendor={props.activeUserVendor}
          onChange={appScreenChange}
        />
      );
      break;
    case "Create-Script-IVR":
      appPage = (
        <CreateScriptIVR
          activeUserSupplier={props.activeUserSupplier}
          activeUserVendor={props.activeUserVendor}
          onChange={appScreenChange}
        />
      );
      break;
    case "Script-Details":
      appPage = (
        <ScriptDetails
          activeUserSupplier={props.activeUserSupplier}
          scriptID={scriptID}
          onChange={appScreenChange}
        />
      );
      break;
    case "Edit-Script":
      appPage = <EditScript scriptID={scriptID} onChange={appScreenChange} />;
      break;
    case "Dashboard-Home":
      appPage = (
        <ScriptDashScreen
          activeSelectedDB={props.activeSelectedDB}
          activeUserSupplier={props.activeUserSupplier}
          activeUserVendor={props.activeUserVendor}
          scriptDetailID={scriptDetailID}
          scriptID={scriptID}
          onChange={appScreenChange}
          activeUser={props.activeUser}
        />
      );
      break;
    case "SSimple-Screen":
      appPage = (
        <SSimpleScreen
          scriptDetailID={scriptDetailID}
          scriptID={scriptID}
          onChange={appScreenChange}
        />
      );
      break;
    case "SDetails-Screen":
      appPage = (
        <SDetailsScreen
          scriptDetailID={scriptDetailID}
          scriptID={scriptID}
          onChange={appScreenChange}
        />
      );
      break;
    default:
      appPage = (
        <ScriptDashScreen
          activeSelectedDB={props.activeSelectedDB}
          activeUserSupplier={props.activeUserSupplier}
          activeUserVendor={props.activeUserVendor}
          scriptDetailID={scriptDetailID}
          scriptID={scriptID}
          onChange={appScreenChange}
          activeUser={props.activeUser}
        />
      );
  }

  return <div class="">{appPage}</div>;
};

export default ScriptDashboard;
