import React, { useState } from "react";

const OrderDashItem = (props) => {
  const dateStr = props.dateAdded;
  const verifiedStr = props.dateVerified;
  const date = new Date(dateStr);
  const verifiedDate = new Date(verifiedStr);
  return (
    <tbody class="border-b border-gray-200 divide-y divide-orange-100 bg-white">
      <tr>
        <td class="max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
          <div class="flex items-center space-x-3 lg:pl-2">
            <button
              class="hover:opacity-70"
              type="button"
              onClick={() => {
                props.orderDetailID(props.orderNumber);
                props.positionChange("Order-Details");
              }}
            >
              {" "}
              <span class="truncate">{props.orderNumber}</span>
            </button>
          </div>
        </td>

        <td class="hidden lg:inline-block mt-1 px-6 py-3 text-left text-sm text-gray-500">
          <span> {props.firstName + ` ` + props.lastName}</span>
        </td>

        {props.status === "Pending" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded-md bg-orange-100 px-4 py-3 text-xs font-medium text-orange-900 ring-1 ring-inset ring-orange-600/50">
              Delivered
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        {props.status === "Complete" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded-md bg-green-100 px-4 py-3 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/50">
              Completed
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        {props.status === "Issue" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded bg-red-200 px-3 py-2 text-xs font-medium text-red-900">
              Failed/Rejected
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        {props.status === "Expired" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded-md bg-violet-100 px-4 py-3 text-xs font-medium text-violet-900 ring-1 ring-inset ring-violet-600/50">
              Timed Out
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        <td class="hidden lg:inline-block py-3 text-left text-sm text-gray-500 ">
          {props.status === "Complete" ? (
            <>
              <span>Created - {date.toLocaleString()}</span>
              <span class="text-green-500 font-medium block">
                Verified - {verifiedDate.toLocaleString()}
              </span>
            </>
          ) : (
            <span>Created - {date.toLocaleString()}</span>
          )}
        </td>
      </tr>
    </tbody>
  );
};
export default OrderDashItem;
