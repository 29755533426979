import React, { useState } from "react";

const ReportDashItem = (props) => {
  var myDate = new Date(props.order.ts / 1000);
  var newDateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  // Deal End Date //
  //
  //
  //
  function addYears(date, years) {
    date.setFullYear(date.getFullYear() + years);
    return date;
  }

  // May 15, 2022
  const datePlusYear = new Date(props.order.ts / 1000);

  const dealEndDate = addYears(datePlusYear, 1);
  //
  //
  //
  // Deal Group Code/Product Conversion //
  //
  var dealGroupCode = "";
  //
  //
  switch (props.order.data.Product.name) {
    case "Wholesale Variable":
      var dealGroupCode = "113GWEPGE_VAR_WACOG";
      break;
    case "Wholesale Variable + REC":
      var dealGroupCode = "113GWEPGE_VAR_WACOG_REC_SM";
      break;
  }
  //
  //
  //
  //
  //
  // Vendor Conversion //
  //
  var salesGroupCode = "";
  //
  //
  switch (props.order.data.Product.vendor.name) {
    case "All In Distribution":
      var salesGroupCode = "ALL-IN-DIST_GREENWAVEPGE";
      break;
    case "North American Venture Capital, LLC":
      var salesGroupCode = "NAVC_GREENWAVEPGE";
      break;
  }
  //
  //
  //
  //
  return (
    <tbody class="border-b border-gray-200 divide-y divide-gray-100 bg-white">
      <tr>
        <td class="pl-6 py-3 font-medium text-xs text-gray-900 md:table-cell">
          <span>{props.order.data.accountNumber_1}</span>
        </td>
        <td class=" px-6 py-3 text-xs font-medium text-gray-900 md:table-cell">
          <div class="flex items-center space-x-3">
            <span class=" font-normal text-gray-500">
              {props.order.data.actHolderFirst +
                ` ` +
                props.order.data.actHolderLast}
            </span>
          </div>
        </td>

        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {props.order.data.serviceAddress}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {props.order.data.serviceCity}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {props.order.data.serviceState}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {props.order.data.serviceZip}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {props.order.data.Product.dwelling}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span>
            {" "}
            {props.order.data.firstName + ` ` + props.order.data.lastName}
          </span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {props.order.data.billingTel}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {myDate.toLocaleString("en-US", newDateOptions)}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {myDate.toLocaleString("en-US", newDateOptions)}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {dealEndDate.toLocaleString("en-US", newDateOptions)}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {props.order.data.agent}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {dealGroupCode}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {props.order.data.Product.utility.nickname}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {salesGroupCode}</span>
        </td>
        <td class="px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
          <span> {props.order.data.Language.charAt(0)}</span>
        </td>
      </tr>
    </tbody>
  );
};
export default ReportDashItem;
