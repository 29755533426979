import React, { useState } from "react";

const IvrStatus = (props) => {
  return (
    <>
      {props.status.startsWith("Customer answering question") === true ? (
        <td class="">
          <span class="inline-flex items-center rounded-md bg-orange-100 px-4 py-3 text-xs font-medium text-orange-900 ring-1 ring-inset ring-orange-600/50">
            Question {""} {props.status.match(/\d+/)}
          </span>
        </td>
      ) : (
        <div class="hidden" />
      )}

      {props.status.startsWith("Call attempted") === true ? (
        <td class="">
          <span class="inline-flex items-center rounded-md bg-orange-100 px-4 py-3 text-xs font-medium text-orange-900 ring-1 ring-inset ring-orange-600/50">
            Call Attempted
          </span>
        </td>
      ) : (
        <div class="hidden" />
      )}

      {props.status === "Verification successful" ? (
        <td class="">
          <span class="inline-flex items-center rounded-md bg-green-100 px-4 py-3 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/50">
            Completed
          </span>
        </td>
      ) : (
        <div class="hidden" />
      )}

      {props.status.startsWith("Customer answered no") === true ? (
        <td class="">
          <span class="inline-flex items-center rounded-md bg-red-100 px-4 py-3 text-xs font-medium text-red-900 ring-1 ring-inset ring-red-600/50">
            Failed/Rejected
          </span>
        </td>
      ) : (
        <div class="hidden" />
      )}
    </>
  );
};
export default IvrStatus;
