import React, { useState, useEffect, Fragment } from "react";
import SimpleDashItem from "./SimpleDashItem";
import { Menu, Transition } from "@headlessui/react";
import ReactPaginate from "react-paginate";
import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// SIMPLE FILTER

const SimpleScreen = (props) => {
  // Start FaunaDB
  // Imports
  const faunadb = require("faunadb");
  const q = faunadb.query;
  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const [allExistingOrders, setallExistingOrders] = useState("");

  const [orders, setAllOrders] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(1);
  const [ordersPerPage, setordersPerPage] = useState(10);
  const [slice, setSlice] = useState([]);

  const COLLECTION_NAME = "orders";

  const getAllOrders = async () => {
    {
      const allOrders = await client.query(
        q.Map(
          q.Paginate(q.Documents(q.Collection(COLLECTION_NAME)), {
            size: 100000,
          }),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallExistingOrders(allOrders.data);

      const data = allOrders.data;

      const slice = data.slice(offset - 1, offset - 1 + ordersPerPage);

      // For displaying Data
      const ordersData = setSlice(slice);

      // Using Hooks to set value
      setAllOrders(ordersData);
      setPageCount(Math.ceil(data.length / ordersPerPage));
    }
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(1 + selectedPage * 10);
  };
  useEffect(() => {
    getAllOrders();
  }, [offset]);

  if (Object.keys(allExistingOrders).length === 0) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                aria-hidden="true"
                class="mb-10 lg:w-20 lg:h-20 w-14 h-14 animate-spin text-white fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <h1 class="lg:text-6xl text-4xl text-tpvBlue font-bold">
                Loading Orders
              </h1>
              <p class="lg:text-2xl text-xl text-gray-700">
                If orders do not load please contact system admin.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <main class="animate-fade dashboard flex-1">
        <div class="lg:border-b lg:border-gray-200 lg:py-4 lg:flex lg:items-center lg:justify-between lg:px-8">
          <div class="hidden lg:flex flex-1 justify-between lg:mx-auto">
            <div class="flex flex-1">
              <label for="search-field" class="sr-only">
                Search
              </label>
              <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                  aria-hidden="true"
                >
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search-field"
                  name="search-field"
                  class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                  placeholder="Search Orders"
                  type="search"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="lg:mt-8 mt-4">
          <div class="inline-block min-w-full align-middle">
            <h2 class="px-4 py-5 sm:px-6 lg:text-2xl text-xl font-semibold text-gray-900">
              Recent Orders{" "}
              <span class="text-gray-400 italic">{props.activeSelectedDB}</span>
            </h2>
            <table class="min-w-full table-auto">
              <thead>
                <tr class="border-t border-gray-200">
                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  >
                    <span class="lg:pl-2">Order No.</span>
                  </th>

                  <th
                    class="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  >
                    <span> Status</span>
                  </th>

                  <th
                    class="hidden border-b border-gray-200 bg-gray-50 text-center text-sm font-semibold text-gray-900 md:table-cell"
                    scope="col"
                  >
                    <div class="flex">
                      <button class="pt-1">
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="flex items-center hover:opacity-50">
                              <span className="sr-only">Open options</span>

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-7 h-7 lg:block hidden"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
                                />
                              </svg>
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <p
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-400"
                                          : "text-gray-400",
                                        "block bg-gray-100 cursor-default font-light px-4 py-2 text-sm"
                                      )}
                                    >
                                      Simple
                                    </p>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      onClick={() => {
                                        const newPosition = "Dashboard-Home";
                                        props.onChange(newPosition);
                                      }}
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      Default
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      onClick={() => {
                                        const newPosition = "Product-Screen";
                                        props.onChange(newPosition);
                                      }}
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      Product
                                    </a>
                                  )}
                                </Menu.Item>
                                <form method="POST" action="#">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        onClick={() => {
                                          const newPosition = "Script-Screen";
                                          props.onChange(newPosition);
                                        }}
                                        href="#"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        Script
                                      </a>
                                    )}
                                  </Menu.Item>
                                </form>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </button>
                      <button
                        onClick={() => {
                          const newPosition = "Dashboard-Home";
                          props.onChange(newPosition);
                        }}
                        class="ml-3 pt-1 hover:opacity-50"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 lg:block hidden"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                          />
                        </svg>
                      </button>
                    </div>
                  </th>
                  <th
                    class="bg-gray-50 border-b py-3 pr-6 text-left text-sm font-semibold text-gray-900"
                    scope="col"
                  ></th>
                </tr>
              </thead>

              {slice.map((post, props) => {
                return (
                  <SimpleDashItem
                    key={post.ref.value.id}
                    orderNumber={post.data.orderNumber}
                    status={post.data.orderStatus}
                    positionChange={props.onChange}
                    orderDetailID={props.orderDetailID}
                  />
                );
              })}

              <tfoot>
                <tr>
                  <td
                    class="bg-gray-50 py-3 pr-14 text-right text-sm font-semibold text-gray-900"
                    colspan="6"
                  >
                    <div class="inline-flex items-center rounded-md px-5 py-1 text-sm font-medium text-gray-500">
                      <Tab.Group>
                        <p>
                          <Tab.List>
                            {" "}
                            Results per page:{" "}
                            <Tab as={Fragment}>
                              {({ selected }) => (
                                <button
                                  type="button"
                                  class="underline font-semibold"
                                >
                                  10
                                </button>
                              )}
                            </Tab>{" "}
                            |{" "}
                            <Tab as={Fragment}>
                              {({ selected }) => (
                                <button
                                  type="button"
                                  class="hover:underline"
                                  onClick={() => {
                                    const newPosition = "Simple-50";
                                    props.onChange(newPosition);
                                  }}
                                >
                                  50
                                </button>
                              )}
                            </Tab>{" "}
                            |{" "}
                            <Tab as={Fragment}>
                              {({ selected }) => (
                                <button
                                  type="button"
                                  class="hover:underline"
                                  onClick={() => {
                                    const newPosition = "Simple-100";
                                    props.onChange(newPosition);
                                  }}
                                >
                                  100
                                </button>
                              )}
                            </Tab>
                          </Tab.List>
                        </p>
                      </Tab.Group>
                    </div>

                    <span class="inline-flex items-center rounded-md bg-purple-100 px-5 py-1 text-sm font-medium text-purple-800">
                      Orders: {allExistingOrders.length}
                    </span>
                  </td>
                </tr>
              </tfoot>
            </table>
            <div class="flex justify-center pt-8">
              <div>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
};

export default SimpleScreen;
