import React, { useState } from "react";

const UtilityDashItem = (props) => {
  return (
    <tbody class="border-b border-gray-200 divide-y divide-gray-100 bg-white">
      <tr>
        <td class="w-1/4 max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-500">
          <div class="flex items-center space-x-3 lg:pl-2">
            <button
              class=""
              type="button"
              disabled
              onClick={() => {
                props.utilityDetailID(props.utilityID);
                props.positionChange("Utility-Details");
              }}
            >
              {" "}
              <span>{props.utilityID}</span>
            </button>
          </div>
        </td>

        <td class="lg:whitespace-nowrap px-6 py-3 text-left text-sm text-gray-900 md:table-cell">
          <span> {props.name}</span>
        </td>

        {props.status === "Pending" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded bg-orange-200 px-3 py-2 text-xs font-medium text-orange-900">
              Pending
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        {props.status === "Complete" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded bg-green-200 px-3 py-2 text-xs font-medium text-green-900">
              Complete
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        {props.status === "Issue" ? (
          <td class="px-6 py-3 font-medium text-gray-500">
            <span class="inline-flex items-center rounded bg-red-200 px-3 py-2 text-xs font-medium text-red-900">
              Failed/Rejected
            </span>
          </td>
        ) : (
          <div class="hidden" />
        )}

        <td class="hidden lg:whitespace-nowrap px-6 py-3 text-left text-gray-500 md:table-cell">
          <span> {props.commodity_1}</span>
          <span class="block"> {props.commodity_2}</span>
          <span class="block"> {props.commodity_3}</span>
          <span class="block"> {props.commodity_4}</span>
          <span class="block"> {props.commodity_5}</span>
        </td>
        <td class="hidden md:whitespace-nowrap px-6 py-3 text-left text-gray-500 md:table-cell">
          <span> {props.state_1}</span>
          <span class="block"> {props.state_2}</span>
          <span class="block"> {props.state_3}</span>
        </td>
      </tr>
    </tbody>
  );
};
export default UtilityDashItem;
