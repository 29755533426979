import React, { useState, useEffect } from "react";

const OrderSubmittedAgent = (props) => {
  //
  //
  const [pullOrder, setpullOrder] = useState("");
  //
  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "orders";
  //
  //
  //
  //
  //
  const findOrder = async () => {
    {
      const foundOrder = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("find-enrollment"), Number(props.orderNumber))
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setpullOrder(foundOrder.data[0].data);
    }
  };
  //
  //
  //

  //
  //
  //

  const BacktoDash = async () => {
    await client
      .query(
        q.Update(
          q.Select(
            "ref",
            q.Get(
              q.Match(q.Index("find-enrollment"), Number(props.orderNumber))
            )
          ),
          {
            data: {
              agentCallIn: {
                Status: "",
                Questions: "",
              },
            },
          }
        )
      )
      .then(function (res) {
        console.log("Result:", res);
        window.location.href = `/app`;
      });
  };
  //
  //
  //
  //
  //
  useEffect(() => {
    setInterval(() => {
      findOrder();
    }, 5000);

    // findOrder();
  }, []);
  //
  //
  //
  console.log(pullOrder);
  //
  //
  if (Object.keys(pullOrder).length === 0) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                aria-hidden="true"
                class="mb-10 w-20 h-20 animate-spin text-white fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <h1 class="text-6xl text-tpvBlue font-bold">
                Loading Customer's Order
              </h1>
              <p class="text-2xl text-gray-700">
                If order do not load please contact system admin.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <>
        <div class="min-h-screen animate-fade bg-purple-900">
          <div class="flex justify-center pt-5">
            <div class="mt-10 flex items-center justify-center">
              <div class="ml-2 animate-fade p-8 rounded bg-white shadow-xl rounded-xl  ">
                <div class="flex flex-col items-center space-y-2 text-purple-800">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="animate-pulse mb-5 w-28 h-28"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M15 3.75a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V5.56l-4.72 4.72a.75.75 0 11-1.06-1.06l4.72-4.72h-2.69a.75.75 0 01-.75-.75z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <h1 class="text-3xl pb-4 text-tpvBlue font-medium">
                    Agent please call in now
                  </h1>
                  <h1 class="text-6xl text-tpvBlue font-bold">
                    (513) 694-0031
                  </h1>
                  <p class="text-2xl py-4 text-gray-700">
                    And enter your customer's order number:
                  </p>
                  <h1 class="text-6xl text-green-700 font-bold">
                    {props.orderNumber}
                  </h1>
                  <p className="pt-5 max-w-md text-center text-sm leading-6 text-gray-500">
                    You can return to this page and complete the verification at
                    a later time - but within 24 hours.
                  </p>
                  <div class=" ">
                    <button
                      onClick={() => BacktoDash()}
                      class="mt-4 ml-4 order-0 inline-flex items-center rounded-md bg-tpvBlue px-8 py-3 text-sm font-medium text-white hover:opacity-80 focus:outline-none sm:order-1"
                    >
                      Back To Order Dashboard
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="ml-4 mt-12 max-w-5xl bg-white overflow-hidden  shadow sm:rounded-xl">
              <div className="px-4 py-6 sm:px-6">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  Order Information
                </h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                  Customer order details
                </p>
              </div>
              <div className="border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">
                      Business Name
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {pullOrder.businessName}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">
                      Primary First Name
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {pullOrder.primaryFirstName}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">
                      Primary Last Name
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {pullOrder.primaryLastName}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">
                      Order Number
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {props.orderNumber}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">
                      Product Selected
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {pullOrder.Product.name}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">
                      Service Address
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {pullOrder.serviceAddress}, {pullOrder.serviceCity},{" "}
                      {pullOrder.serviceState} {pullOrder.serviceZip}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">
                      Billing/Mailing Telephone
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {pullOrder.billingTel}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>

          {pullOrder.agentCallIn.Status === "Ready To Review" ? (
            <div class="mx-4">
              <button
                type="button"
                onClick={() =>
                  props.orderFormScreenChange("Complete-Agent-Call")
                }
                class="mt-6 animate-pulse flex items-center justify-center w-full order-0 rounded-md bg-green-500 px-8 py-5 text-lg font-medium text-white focus:outline-none sm:order-1"
              >
                Ready to verify click here
              </button>{" "}
            </div>
          ) : (
            <div class="mx-4">
              <button
                type="button"
                disabled
                class="mt-6 flex items-center justify-center w-full order-0 rounded-md bg-tpvRed px-8 py-5 text-lg font-medium text-white focus:outline-none sm:order-1"
              >
                <svg
                  aria-hidden="true"
                  class="w-7 h-7 mr-4 animate-spin text-tpvRed fill-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                Waiting for agent call verification
              </button>{" "}
            </div>
          )}
        </div>
      </>
    );
};
export default OrderSubmittedAgent;
